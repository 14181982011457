import { AccAddress } from '@terra-money/terra.js';
import { SupportedAsset } from '../store/strategies';

export const supportedNetworks = ['mainnet', 'testnet', 'localterra'] as const;
export type SupportedNetwork = typeof supportedNetworks[number];

export function isSupportedNetwork(
  network: string
): network is SupportedNetwork {
  return supportedNetworks.includes(network as SupportedNetwork);
}

export type ExtNetworkConfig = {
  name: SupportedNetwork;
  chainID: string;
  lcd: string;
};

export type LocalNetworkConfig = {
  /** Contract Addresses */
  contracts: {
    mirror_oracle: string;
    factory: string;
    strategies: string[];
    lp_tokens: { [k in SupportedAsset]: AccAddress };
    pairs: { [k in SupportedAsset]: AccAddress };
    tokens: { [k in SupportedAsset]: AccAddress };
    cfe_vesting: string;
    ps_vesting: string;
    airdrop: string;
  };
  /** Fixed fee */
  fee: { gasPrice: number; amount: number };
  stats: string;
  apollo_fcd: string;
};

export const networks: { [k in SupportedNetwork]: LocalNetworkConfig } = {
  mainnet: {
    contracts: {
      mirror_oracle: 'terra1t6xe0txzywdg85n6k8c960cuwgh6l8esw6lau9',
      factory: 'terra1leadedadm2fguezmd4e445h6fe337yzq8n2dxf',
      //TODO: add vesting and airdrop contracts Addr
      cfe_vesting: 'terra1878h54yx347vxnlx8e0la9ngdnqu4uw9u2ppma',
      ps_vesting: 'terra1s9r689h3795v6wzjwul35q9zcfkf8m6k0a6ule',
      airdrop: 'terra1sn807fkaddr6y6asl855rlrywygywlhs88r8ps',
      strategies: [],
      lp_tokens: {
        MIR: 'terra17gjf2zehfvnyjtdgua9p9ygquk6gukxe7ucgwh',
        mAAPL: 'terra122asauhmv083p02rhgyp7jn7kmjjm4ksexjnks',
        mABNB: 'terra1jmauv302lfvpdfau5nhzy06q0j2f9te4hy2d07',
        mAMD: 'terra1m8mr9u3su46ezxwf7z7xnvm0jsapl2jd8vgefh',
        mAMZN: 'terra1q7m2qsj3nzlz5ng25z5q5w5qcqldclfe3ljup9',
        mARKK: 'terra1veqh8yc55mhw0ttjr5h6g9a6r9nylmrc0nzhr7',
        mBABA: 'terra1stfeev27wdf7er2uja34gsmrv58yv397dlxmyn',
        mBTC: 'terra1d34edutzwcz6jgecgk26mpyynqh74j3emdsnq5',
        mCOIN: 'terra1ktckr8v7judrr6wkwv476pwsv8mht0zqzw2t0h',
        mDOT: 'terra1p60datmmf25wgssguv65ltds3z6ea3me74nm2e',
        mETH: 'terra16auz7uhnuxrj2dzrynz2elthx5zpps5gs6tyln',
        mFB: 'terra1jh2dh4g65hptsrwjv53nhsnkwlw8jdrxaxrca0',
        mGLXY: 'terra1pjgzke6h5v4nz978z3a92gqajwhn8yyh5kv4zv',
        mGME: 'terra1azk43zydh3sdxelg3h4csv4a4uef7fmjy0hu20',
        mGOOGL: 'terra1falkl6jy4087h4z567y2l59defm9acmwcs70ts',
        mGS: 'terra17smg3rl9vdpawwpe7ex4ea4xm6q038gp2chge5',
        mHOOD: 'terra1s0dgcsdy9kgunnf3gnwl40uwy9rxtmc39mhy2m',
        mIAU: 'terra1ndlx5ndkknvmgj6s5ggmdlhjjsz0w6wrnwn5cf',
        mMSFT: 'terra14uaqudeylx6tegamqmygh85lfq8qg2jmg7uucc',
        mNFLX: 'terra1mwu3cqzvhygqg7vrsa6kfstgg9d6yzkgs6yy3t',
        mQQQ: 'terra16j09nh806vaql0wujw8ktmvdj7ph8h09ltjs2r',
        mSLV: 'terra178cf7xf4r9d3z03tj3pftewmhx0x2p77s0k6yh',
        mSPY: 'terra1jqqegd35rg2gjde54adpj3t6ecu0khfeaarzy9',
        mSQ: 'terra1mv3pgkzs4krcennqj442jscg6jv84cejrs50n2',
        mTSLA: 'terra1ygazp9w7tx64rkx5wmevszu38y5cpg6h3fk86e',
        mTWTR: 'terra1fc5a5gsxatjey9syq93c2n3xq90n06t60nkj6l',
        mUSO: 'terra1utf3tm35qk6fkft7ltcnscwml737vfz7xghwn5',
        mVIXY: 'terra1ekd58y58vq4gmxlzpc27dwuhw7wmms928ftuep',
        MINE: 'terra1rqkyau9hanxtn63mjrdfhpnkpddztv3qav0tq2',
        ANC: 'terra1gecs98vcuktyfkrve9czrpgtg0m3aq586x6gzm',
        APOLLO: ''
      },
      pairs: {
        MIR: 'terra1amv303y8kzxuegvurh0gug2xe9wkgj65enq2ux',
        mAAPL: 'terra1774f8rwx76k7ruy0gqnzq25wh7lmd72eg6eqp5',
        mABNB: 'terra1gq7lq389w4dxqtkxj03wp0fvz0cemj0ek5wwmm',
        mAMD: 'terra18cxcwv0theanknfztzww8ft9pzfgkmf2xrqy23',
        mAMZN: 'terra1vkvmvnmex90wanque26mjvay2mdtf0rz57fm6d',
        mARKK: 'terra1a5cc08jt5knh0yx64pg6dtym4c4l8t63rhlag3',
        mBABA: 'terra1afdz4l9vsqddwmjqxmel99atu4rwscpfjm4yfp',
        mBTC: 'terra1prfcyujt9nsn5kfj5n925sfd737r2n8tk5lmpv',
        mCOIN: 'terra1h7t2yq00rxs8a78nyrnhlvp0ewu8vnfnx5efsl',
        mDOT: 'terra17rvtq0mjagh37kcmm4lmpz95ukxwhcrrltgnvc',
        mETH: 'terra14fyt2g3umeatsr4j4g2rs8ca0jceu3k0mcs7ry',
        mFB: 'terra1yl2atgxw422qxahm02p364wtgu7gmeya237pcs',
        mGLXY: 'terra1ze5f2lm5clq2cdd9y2ve3lglfrq6ap8cqncld8',
        mGME: 'terra17eakdtane6d2y7y6v0s79drq7gnhzqan48kxw7',
        mGOOGL: 'terra1u56eamzkwzpm696hae4kl92jm6xxztar9uhkea',
        mGS: 'terra108ukjf6ekezuc52t9keernlqxtmzpj4wf7rx0h',
        mHOOD: 'terra1lr6rglgd50xxzqe6l5axaqp9d5ae3xf69z3qna',
        mIAU: 'terra15kkctr4eug9txq7v6ks6026yd4zjkrm3mc0nkp',
        mMSFT: 'terra10ypv4vq67ns54t5ur3krkx37th7j58paev0qhd',
        mNFLX: 'terra1yppvuda72pvmxd727knemvzsuergtslj486rdq',
        mQQQ: 'terra1dkc8075nv34k2fu6xn6wcgrqlewup2qtkr4ymu',
        mSLV: 'terra1f6d9mhrsl5t6yxqnr4rgfusjlt3gfwxdveeyuy',
        mSPY: 'terra14hklnm2ssaexjwkcfhyyyzvpmhpwx6x6lpy39s',
        mSQ: 'terra1u3pknaazmmudfwxsclcfg3zy74s3zd3anc5m52',
        mTSLA: 'terra1pdxyk2gkykaraynmrgjfq2uu7r9pf5v8x7k4xk',
        mTWTR: 'terra1ea9js3y4l7vy0h46k4e5r5ykkk08zc3fx7v4t8',
        mUSO: 'terra1zey9knmvs2frfrjnf4cfv4prc4ts3mrsefstrj',
        mVIXY: 'terra1krny2jc0tpkzeqfmswm7ss8smtddxqm3mxxsjm',
        MINE: 'terra178jydtjvj4gw8earkgnqc80c3hrmqj4kw2welz',
        ANC: 'terra1gm5p3ner9x9xpwugn9sp6gvhd0lwrtkyrecdn3',
        APOLLO: ''
      },
      tokens: {
        MIR: 'terra15gwkyepfc6xgca5t5zefzwy42uts8l2m4g40k6',
        mAAPL: 'terra1vxtwu4ehgzz77mnfwrntyrmgl64qjs75mpwqaz',
        mABNB: 'terra1g4x2pzmkc9z3mseewxf758rllg08z3797xly0n',
        mAMD: 'terra18ej5nsuu867fkx4tuy2aglpvqjrkcrjjslap3z',
        mAMZN: 'terra165nd2qmrtszehcfrntlplzern7zl4ahtlhd5t2',
        mARKK: 'terra1qqfx5jph0rsmkur2zgzyqnfucra45rtjae5vh6',
        mBABA: 'terra1w7zgkcyt7y4zpct9dw8mw362ywvdlydnum2awa',
        mBTC: 'terra1rhhvx8nzfrx5fufkuft06q5marfkucdqwq5sjw',
        mCOIN: 'terra18wayjpyq28gd970qzgjfmsjj7dmgdk039duhph',
        mDOT: 'terra19ya4jpvjvvtggepvmmj6ftmwly3p7way0tt08r',
        mETH: 'terra1dk3g53js3034x4v5c3vavhj2738une880yu6kx',
        mFB: 'terra1mqsjugsugfprn3cvgxsrr8akkvdxv2pzc74us7',
        mGLXY: 'terra1l5lrxtwd98ylfy09fn866au6dp76gu8ywnudls',
        mGME: 'terra1m6j6j9gw728n82k78s0j9kq8l5p6ne0xcc820p',
        mGOOGL: 'terra1h8arz2k547uvmpxctuwush3jzc8fun4s96qgwt',
        mGS: 'terra137drsu8gce5thf6jr5mxlfghw36rpljt3zj73v',
        mHOOD: 'terra18yqdfzfhnguerz9du5mnvxsh5kxlknqhcxzjfr',
        mIAU: 'terra10h7ry7apm55h4ez502dqdv9gr53juu85nkd4aq',
        mMSFT: 'terra1227ppwxxj3jxz8cfgq00jgnxqcny7ryenvkwj6',
        mNFLX: 'terra1jsxngqasf2zynj5kyh0tgq9mj3zksa5gk35j4k',
        mQQQ: 'terra1csk6tc7pdmpr782w527hwhez6gfv632tyf72cp',
        mSLV: 'terra1kscs6uhrqwy6rx5kuw5lwpuqvm3t6j2d6uf2lp',
        mSPY: 'terra1aa00lpfexyycedfg5k2p60l9djcmw0ue5l8fhc',
        mSQ: 'terra1u43zu5amjlsgty5j64445fr9yglhm53m576ugh',
        mTSLA: 'terra14y5affaarufk3uscy2vr6pe6w6zqf2wpjzn5sh',
        mTWTR: 'terra1cc3enj9qgchlrj34cnzhwuclc4vl2z3jl7tkqg',
        mUSO: 'terra1lvmx8fsagy70tv0fhmfzdw9h6s3sy4prz38ugf',
        mVIXY: 'terra19cmt6vzvhnnnfsmccaaxzy2uaj06zjktu6yzjx',
        MINE: 'terra1kcthelkax4j9x8d3ny6sdag0qmxxynl3qtcrpy',
        ANC: 'terra14z56l0fp2lsf86zy3hty2z47ezkhnthtr9yq76',
        APOLLO: ''
      }
    },
    fee: { gasPrice: 0.15, amount: 100000 }, // 0.1 UST
    stats: 'https://graph.mirror.finance/graphql',
    apollo_fcd: 'https://price-api-mainnet.apollo.farm/v1'
  },
  testnet: {
    contracts: {
      mirror_oracle: 'terra1uvxhec74deupp47enh7z5pk55f3cvcz8nj4ww9',
      factory: 'terra1kr60fwhneuzz6h28uuvexap7llarhkhvp24fx2',
      cfe_vesting: 'terra1ppaw09u02xq922gd6eudef4l4y9ev740unctmt',
      ps_vesting: '',
      airdrop: 'terra10jhelle6gfx7nk98wjt6ep5d84uk6ahmpnrp5l',
      strategies: [],

      lp_tokens: {
        mARKK: 'terra1plwkt2fj8n9xkfdp9z6f5sp6r63vx2s22mznls',
        MIR: 'terra1zrryfhlrpg49quz37u90ck6f396l4xdjs5s08j',
        mAAPL: 'terra1vth958fsn8zawllaqcdzswksjkv3dz2sqqmcu4',
        mABNB: 'terra1d7xlu53sx9anwdqvxeya0tmhr7xzepd9agyvft',
        mAMD: 'terra1lu2eg3hghmkd0s5jdsnr24mg5cremf0e8dpz4l',
        mAMZN: 'terra1kgvcrtupc8y4dgc9n08ud99ckdxp08j59zgccf',
        mBABA: 'terra1px2ya3e07aprfgc76e57r3nuvy3czssrvcxg9t',
        mBTC: 'terra186m890y8hzhyhp8emgsy2a8jqvsgluznc688xf',
        mCOIN: 'terra1gdglgpm84pdzuedc924wurhsqd0vs4nv4tpmh8',
        mDOT: 'terra1xrjygqshp9shcgfm4vj89mezxev36fk4na3ft2',
        mETH: 'terra1lgxdj5wag4ug7le6arda5pd7z9xlpstgc2ue8t',
        mFB: 'terra1l8c87av89cczj20mguxy0hmp6k6hesr0yjthkm',
        mGLXY: '',
        mGME: 'terra1kkpe6has6g55nhyqp6vkw0wk8z3u3nq6fz6znc',
        mGOOGL: 'terra1qxurxcgl30eu4ar34ltr5e9tqc2gjl4atspvy3',
        mGS: 'terra1xwk2x2pyrhlshjgnlv3ql5q8r8r8vyrdf6u2mj',
        mHOOD: '',
        mIAU: 'terra1wj8cm3h7fe369nl8j4ddukesfuy9l3hr4hjw22',
        mMSFT: 'terra1f7azmktepw5rq35e2m6r6smtwl8wdrxp0dsvar',
        mNFLX: 'terra1e0njrqcsehxpt9due62x9zsxl7h9htl0xqdujv',
        mQQQ: 'terra1h52zc9qmndczgru9vp2cvuwfclyykl5yt3qjk8',
        mSLV: 'terra16cn5cgwaktrzczda0c6ux0e2quudh4vn3t8jjm',
        mSPY: 'terra1pef9fz8lkqajzuwp6ff6t33s86ndpkm3728cgc',
        mSQ: 'terra1d6l7vk5ktugx9qm6nmtd2hv7kleg9xqce0ncps',
        mTSLA: 'terra1utf7qw0uce42vqsh255hxgd3pvuzfvp6jcayk5',
        mTWTR: 'terra10cugucjwn4hdtvavl0n2sh2ke64nx93luhj49k',
        mUSO: 'terra1rag9w5ch0jrdxjffr6napqz0zsrpm6uz2zezmj',
        mVIXY: 'terra1h9zrywpukj2wvu5dfsm7tcxk8mwzzyrxenshsn',
        MINE: 'terra1st9me79vkk4erw3apydt5z48n6ahgj4qdclp4u',
        ANC: 'terra1vg0qyq92ky9z9dp0j9fv5rmr2s80sg605dah6f',

        APOLLO: ''
      },
      pairs: {
        mARKK: 'terra1t3xljlc82tmc0xjpfcrnu44phg2s4mt5rt3llm',
        MIR: 'terra1cz6qp8lfwht83fh9xm9n94kj04qc35ulga5dl0',
        mAAPL: 'terra1yj892rl8edvk0y2ayf3h36t6uf89lzxg8jea4a',
        mABNB: 'terra13009wwv5flguch7kmwcgq8zz4r7asam3vmxkn6',
        mAMD: 'terra12q2ehzn8jah7mfez9d0f7ztsu3vdf0g3mxzztq',
        mAMZN: 'terra1xs3vy9zs8agmnzyn7z9s7kqk392uu2h3x3l6er',
        mBABA: 'terra15qq59h2canrr2pf8ny7rw57nx3mcvw97tp3xj4',
        mBTC: 'terra134jl4dt20mqfryhnmhauryr754vuw7990jdell',
        mCOIN: 'terra13qzses7zpsyrufx38h0kc3p87523cjgwmenj44',
        mDOT: 'terra1722gzus7s6prj24wnzsqfkn0t8k7hc4p847tlw',
        mETH: 'terra1j4urgdl38ezrrs59fn403cxgr34u3yd0tv4ypn',
        mFB: 'terra19jp7wqla5qrdusrcqest6rrc33wyvs0fumwd7v',
        mGLXY: '',
        mGME: 'terra1q9qtdudqasdcgqmklw2hsnju09zcjhrhr8ntsa',
        mGOOGL: 'terra1z2734asgwhma8ma2fq4yu7ce2l3mrvj4qnz6ws',
        mGS: 'terra1h73gdv74gw03gc5rfyc0y6s8advl9y2453zgcw',
        mHOOD: '',
        mIAU: 'terra1rtz5mn38pnj924nr97yzax4g849qj4d4m8r7wk',
        mMSFT: 'terra1dt7ne6gwv23wg6chl89q95yj6999alagc6rqd9',
        mNFLX: 'terra18yl0z6wntjkustt9cckc9ptp7l5qh7kr0xrmav',
        mQQQ: 'terra1epxv8z6tzxezjfgw7tveytw5n3fuf6wvg6w8f5',
        mSLV: 'terra1tyzsl0dw4pltlqey5v6g646hm22pql8vy3yh2g',
        mSPY: 'terra10q4qsd2sfl4wc6nv0d77zud4ur40ygf5602pzd',
        mSQ: 'terra1znqeqnumtmpwgdltfw72v2298m3lrg7m447f2q',
        mTSLA: 'terra1tsln42kfeq8edwscmw8njgter5dp8evn40znn9',
        mTWTR: 'terra1jv937296dy5c5dxglrzf05h0jlaaxp55tqlyh6',
        mUSO: 'terra1llk7ycwwlj2zs2l2dvnvmsxrsrnucqwaltstcf',
        mVIXY: 'terra1tfkksph7p9qkean66kc87u2kalu0pw9um9ep2y',
        MINE: 'terra1n2xmlwqpp942nfqq2muxn0u0mqk3sylekdpqfv',
        ANC: 'terra1wfvczps2865j0awnurk9m04u7wdmd6qv3fdnvz',
        APOLLO: ''
      },
      tokens: {
        mARKK: 'terra1qk0cd8576fqf33paf40xy3rt82p7yluwtxz7qx',
        MIR: 'terra10llyp6v3j3her8u3ce66ragytu45kcmd9asj3u',
        mAAPL: 'terra16vfxm98rxlc8erj4g0sj5932dvylgmdufnugk0',
        mABNB: 'terra1avryzxnsn2denq7p2d7ukm6nkck9s0rz2llgnc',
        mAMD: 'terra1ftefjmtpnk2fctsa8xkv8naven65z5qtgq83nu',
        mAMZN: 'terra12saaecsqwxj04fn0jsv4jmdyp6gylptf5tksge',
        mBABA: 'terra15dr4ah3kha68kam7a907pje9w6z2lpjpnrkd06',
        mBTC: 'terra1csr22xvxs6r3gkjsl7pmjkmpt39mwjsrm0e2r8',
        mCOIN: 'terra1qre9crlfnulcg0m68qqywqqstplgvrzywsg3am',
        mDOT: 'terra1fs6c6y65c65kkjanjwvmnrfvnm2s58ph88t9ky',
        mETH: 'terra1ys4dwwzaenjg2gy02mslmc96f267xvpsjat7gx',
        mFB: 'terra14gq9wj0tt6vu0m4ec2tkkv4ln3qrtl58lgdl2c',
        mGLXY: '', //not in tequila whitelist...?
        mGME: 'terra104tgj4gc3pp5s240a0mzqkhd3jzkn8v0u07hlf',
        mGOOGL: 'terra1qg9ugndl25567u03jrr79xur2yk9d632fke3h2',
        mGS: 'terra13myzfjdmvqkama2tt3v5f7quh75rv78w8kq6u6',
        mHOOD: '',
        mIAU: 'terra1p50j2k5vyw3q2tgywqvxyz59z8csh9p7x8dk5m',
        mMSFT: 'terra12s2h8vlztjwu440khpc0063p34vm7nhu25w4p9',
        mNFLX: 'terra1djnlav60utj06kk9dl7defsv8xql5qpryzvm3h',
        mQQQ: 'terra18yx7ff8knc98p07pdkhm3u36wufaeacv47fuha',
        mSLV: 'terra1fdkfhgk433tar72t4edh6p6y9rmjulzc83ljuw',
        mSPY: 'terra15t9afkpj0wnh8m74n8n2f8tspkn7r65vnru45s',
        mSQ: 'terra18qs6704f4ujnwus9x9vxcxrrm0du0f232kpnd6',
        mTSLA: 'terra1nslem9lgwx53rvgqwd8hgq7pepsry6yr3wsen4',
        mTWTR: 'terra1ax7mhqahj6vcqnnl675nqq2g9wghzuecy923vy',
        mUSO: 'terra1fucmfp8x4mpzsydjaxyv26hrkdg4vpdzdvf647',
        mVIXY: 'terra18gphn8r437p2xmjpw7a79hgsglf5y4t0x7s5ee',
        MINE: 'terra1lqm5tutr5xcw9d5vc4457exa3ghd4sr9mzwdex',
        ANC: 'terra1747mad58h0w4y589y3sk84r5efqdev9q4r02pc',
        APOLLO: ''
      }
    },
    //stats: 'https://bombay-mirror-graph.terra.dev/graphql',
    stats: 'https://bombay-graph.mirror.finance/graphql',
    fee: { gasPrice: 0.15, amount: 150000 }, // 0.15 UST
    apollo_fcd: 'https://price-api-bombay.apollo.farm/v1'
  },
  localterra: {
    contracts: {
      mirror_oracle: '',
      factory: '',
      cfe_vesting: '',
      ps_vesting: '',
      airdrop: '',
      strategies: [],
      lp_tokens: {
        mARKK: '',
        MIR: '',
        mAAPL: 'terra1c60d4e8ctqxz43kpwdg7ysq6xvl5y2jtt2srsq',
        mABNB: '',
        mAMD: '',
        mAMZN: '',
        mBABA: '',
        mBTC: '',
        mCOIN: '',
        mDOT: '',
        mETH: '',
        mFB: '',
        mGME: '',
        mGOOGL: '',
        mGS: '',
        mHOOD: '',
        mIAU: '',
        mMSFT: '',
        mNFLX: '',
        mQQQ: '',
        mSLV: '',
        mSPY: '',
        mTSLA: 'terra1smsu42g9c0q9v3fa59fjurnwc885zpn03xlzwg',
        mTWTR: '',
        mUSO: '',
        mVIXY: 'terra17tkxjk3xg5yt36nfm9u09u28j25phm8huh36pf',
        mGLXY: '',
        mSQ: '',
        MINE: '',
        ANC: '',
        APOLLO: ''
      },
      pairs: {
        MIR: '',
        mAAPL: '',
        mABNB: '',
        mAMD: '',
        mAMZN: '',
        mBABA: '',
        mBTC: '',
        mCOIN: '',
        mETH: '',
        mFB: '',
        mGME: '',
        mGOOGL: '',
        mGS: '',
        mHOOD: '',
        mIAU: '',
        mMSFT: '',
        mNFLX: '',
        mQQQ: '',
        mSLV: '',
        mSPY: '',
        mTSLA: '',
        mTWTR: '',
        mUSO: '',
        mVIXY: '',
        mARKK: '',
        mGLXY: '',
        mSQ: '',
        mDOT: '',
        MINE: '',
        ANC: '',
        APOLLO: ''
      },
      tokens: {
        MIR: '',
        mAAPL: '',
        mABNB: '',
        mAMD: '',
        mAMZN: '',
        mBABA: '',
        mBTC: '',
        mCOIN: '',
        mETH: '',
        mFB: '',
        mGME: '',
        mGOOGL: '',
        mGS: '',
        mHOOD: '',
        mIAU: '',
        mMSFT: '',
        mNFLX: '',
        mQQQ: '',
        mSLV: '',
        mSPY: '',
        mTSLA: '',
        mTWTR: '',
        mUSO: '',
        mVIXY: '',
        mARKK: '',
        mGLXY: '',
        mSQ: '',
        mDOT: '',
        MINE: '',
        ANC: '',
        APOLLO: ''
      }
    },
    stats: '',
    fee: { gasPrice: 0.15, amount: 150000 }, // 0.15 UST
    apollo_fcd: 'http://localhost:3060/v1'
  }
};

export default networks;
