export const TransactionFailed = ({ ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="120"
      height="120"
      viewBox="0 0 120 120"
      {...rest}>
      <g id="Group_1525" data-name="Group 1525" transform="translate(15 15)">
        <path
          id="Subtraction_6"
          data-name="Subtraction 6"
          d="M45,90A45.012,45.012,0,0,1,27.484,3.536,45.012,45.012,0,0,1,62.516,86.464,44.718,44.718,0,0,1,45,90ZM45,5A40.01,40.01,0,0,0,29.43,81.856,40.01,40.01,0,0,0,60.57,8.144,39.75,39.75,0,0,0,45,5Z"
          fill="#f26469"
        />
        <g
          id="Group_1527"
          data-name="Group 1527"
          transform="translate(3862 -2060)">
          <path
            id="Subtraction_6-2"
            data-name="Subtraction 6"
            d="M45,90A45.012,45.012,0,0,1,27.484,3.536,45.012,45.012,0,0,1,62.516,86.464,44.718,44.718,0,0,1,45,90ZM45,5A40.01,40.01,0,0,0,29.43,81.856,40.01,40.01,0,0,0,60.57,8.144,39.75,39.75,0,0,0,45,5Z"
            transform="translate(-3862 2060)"
            fill="#f26469"
          />
          <path
            id="Subtraction_7"
            data-name="Subtraction 7"
            d="M45,90A45.012,45.012,0,0,1,27.484,3.536,45.012,45.012,0,0,1,62.516,86.464,44.718,44.718,0,0,1,45,90ZM45,5A40.01,40.01,0,0,0,29.43,81.856,40.01,40.01,0,0,0,60.57,8.144,39.75,39.75,0,0,0,45,5Z"
            transform="translate(-3862 2060)"
            fill="#f26469"
          />
        </g>
        <path
          id="Union_12"
          data-name="Union 12"
          d="M37.4,30.051,26.788,19.442,16.178,30.051a2.5,2.5,0,0,1-3.533-3.533L23.254,15.908,12.646,5.3a2.5,2.5,0,0,1,3.532-3.533L26.787,12.375,37.4,1.767A2.5,2.5,0,1,1,40.929,5.3L30.321,15.909,40.929,26.518A2.5,2.5,0,1,1,37.4,30.051Z"
          transform="translate(18.087 28.965)"
          fill="#f26469"
        />
        <path
          id="Union_13"
          data-name="Union 13"
          d="M37.4,30.051,26.788,19.442,16.178,30.051a2.5,2.5,0,0,1-3.533-3.533L23.254,15.908,12.646,5.3a2.5,2.5,0,0,1,3.532-3.533L26.787,12.375,37.4,1.767A2.5,2.5,0,1,1,40.929,5.3L30.321,15.909,40.929,26.518A2.5,2.5,0,1,1,37.4,30.051Z"
          transform="translate(18.087 28.965)"
          fill="#f26469"
        />
      </g>
    </svg>
  );
};
