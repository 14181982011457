import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import Bow from '../Bow';
import { fontWeights } from '../../../styles/themes/redesign';
import Connect from '../../../layouts/Connect';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: `0 8px`
  },
  connectButton: {
    width: 140,
    height: 30,
    marginRight: 10,
    background: `linear-gradient(to left, #fed200, #fd806d)`,
    transition: 'all .2s ease-in-out',
    '&:hover': {
      transform: 'scale(1.04)'
    }
  },
  connectButtonText: {
    fontWeight: fontWeights.semiBold
  }
}));

const Mobile = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      justify={'space-between'}
      alignItems={'center'}
      className={classes.wrapper}>
      <Grid item>
        <Bow />
      </Grid>
      <Grid item>
        <Grid container>
          <Grid item>
            <Connect />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Mobile;
