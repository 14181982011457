import { atom, selector } from 'recoil';
import { div, gt } from '../../libs/math';
import { PriceKey, BalanceKey } from './contractKeys';
import { useStore, useStoreLoadable } from '../utils/loadable';
import { exchangeRatesQuery } from '../native/exchange';
import { bankBalanceQuery } from '../native/balance';
import { useProtocol } from './protocol';
import { pairPoolQuery, oraclePriceQuery } from './contract';
import { tokenBalanceQuery, lpTokenBalanceQuery } from './contract';
import { mintAssetConfigQuery } from './contract';

/* price */
export const nativePricesQuery = selector({
  key: 'nativePrices',
  get: ({ get }) =>
    reduceNativePrice(get(exchangeRatesQuery).OracleDenomsExchangeRates.Result)
});

const nativePricesState = atom<Dictionary>({
  key: 'nativePricesState',
  default: {}
});

export const pairPricesQuery = selector({
  key: 'pairPrices',
  get: ({ get }) => dict(get(pairPoolQuery), calcPairPrice)
});

const pairPricesState = atom<Dictionary>({
  key: 'pairPricesState',
  default: {}
});

export const oraclePricesQuery = selector({
  key: 'oraclePrices',
  get: ({ get }) => dict(get(oraclePriceQuery), ({ rate }) => rate)
});

const oraclePricesState = atom<Dictionary>({
  key: 'oraclePricesState',
  default: {}
});

export const prePricesQuery = selector({
  key: 'prePrices',
  get: ({ get }) =>
    dict(
      get(mintAssetConfigQuery),
      ({ ipo_params }) => ipo_params?.pre_ipo_price ?? '0'
    )
});

const prePricesState = atom<Dictionary>({
  key: 'prePricesState',
  default: {}
});

export const endPricesQuery = selector({
  key: 'endPrices',
  get: ({ get }) =>
    dict(get(mintAssetConfigQuery), ({ end_price }) => end_price)
});

const endPricesState = atom<Dictionary>({
  key: 'endPricesState',
  default: {}
});

/* balance */
export const nativeBalancesQuery = selector({
  key: 'nativeBalances',
  get: ({ get }) =>
    reduceByDenom(get(bankBalanceQuery)?.BankBalancesAddress?.Result ?? [])
});

const nativeBalancesState = atom<Dictionary>({
  key: 'nativeBalancesState',
  default: {}
});

export const tokenBalancesQuery = selector({
  key: 'tokenBalances',
  get: ({ get }) => {
    const result = get(tokenBalanceQuery);
    return result ? dict(result, ({ balance }) => balance) : {};
  }
});

const tokenBalancesState = atom<Dictionary>({
  key: 'tokenBalancesState',
  default: {}
});

export const lpStakableBalancesQuery = selector({
  key: 'lpStakableBalances',
  get: ({ get }) => {
    const result = get(lpTokenBalanceQuery);
    return result ? dict(result, ({ balance }) => balance) : {};
  }
});

const lpStakableBalancesState = atom<Dictionary>({
  key: 'lpStakableBalancesState',
  default: {}
});

/* store: price */
export const usePairPrices = () => {
  return useStoreLoadable(pairPricesQuery, pairPricesState);
};

export const useOraclePrices = () => {
  return useStoreLoadable(oraclePricesQuery, oraclePricesState);
};

export const useNativePrices = () => {
  return useStoreLoadable(nativePricesQuery, nativePricesState);
};

export const usePrePrices = () => {
  return useStoreLoadable(prePricesQuery, prePricesState);
};

export const useEndPrices = () => {
  return useStoreLoadable(endPricesQuery, endPricesState);
};

/* store: balance */
export const useNativeBalances = () => {
  return useStore(nativeBalancesQuery, nativeBalancesState);
};

export const useTokenBalances = () => {
  return useStore(tokenBalancesQuery, tokenBalancesState);
};

/* store: staking balance */
export const useLpStakableBalances = () => {
  return useStore(lpStakableBalancesQuery, lpStakableBalancesState);
};

/* hooks:find */
export const useFindPrice = () => {
  const { getPriceKey } = useProtocol();

  const pairPrices = usePairPrices();
  const oraclePrices = useOraclePrices();
  const nativePrices = useNativePrices();
  const prePrices = usePrePrices();
  const endPrices = useEndPrices();

  const dictionary = {
    [PriceKey.PAIR]: pairPrices,
    [PriceKey.ORACLE]: oraclePrices,
    [PriceKey.NATIVE]: nativePrices,
    [PriceKey.PRE]: prePrices,
    [PriceKey.END]: endPrices
  };

  return (key: PriceKey, token: string) =>
    dictionary[getPriceKey(key, token)][token];
};

export const useFindBalance = () => {
  const { getBalanceKey } = useProtocol();

  const nativeBalances = useNativeBalances();
  const tokenBalances = useTokenBalances();

  const dictionary = {
    [BalanceKey.NATIVE]: nativeBalances.contents,
    [BalanceKey.TOKEN]: tokenBalances.contents
  };

  return {
    contents: (token: string) => dictionary[getBalanceKey(token)][token],
    isLoading: [nativeBalances, tokenBalances].some(
      ({ isLoading }) => isLoading
    )
  };
};

/* utils */
export const dict = <Data, Item = string>(
  dictionary: Dictionary<Data> = {},
  selector: (data: Data, token?: string) => Item
) =>
  Object.entries(dictionary).reduce<Dictionary<Item>>(
    (acc, [token, data]) =>
      selector(data, token) ? { ...acc, [token]: selector(data, token) } : acc,
    {}
  );

/* helpers */
export const parsePairPool = ({ assets, total_share }: PairPool) => ({
  uusd: assets.find(({ info }) => 'native_token' in info)?.amount ?? '0',
  asset: assets.find(({ info }) => 'token' in info)?.amount ?? '0',
  total: total_share ?? '0'
});

export const calcPairPrice = (param: PairPool) => {
  const { uusd, asset } = parsePairPool(param);
  return [uusd, asset].every((v) => v && gt(v, 0)) ? div(uusd, asset) : '0';
};

const reduceNativePrice = (coins: MantleCoin[]): Dictionary => ({
  uusd: '1',
  uluna: coins.find(({ Denom }) => Denom === 'uusd')?.Amount ?? '0'
});

const reduceByDenom = (coins: MantleCoin[]) =>
  coins.reduce<Dictionary>(
    (acc, { Amount, Denom }) => ({ ...acc, [Denom]: Amount }),
    {}
  );
