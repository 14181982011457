export enum PriceKey {
  NATIVE = 'price',
  PAIR = 'pair',
  ORACLE = 'oracle',
  PRE = 'pre',
  END = 'end'
}

export enum BalanceKey {
  NATIVE = 'balance',
  TOKEN = 'token'
}
