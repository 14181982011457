import { useRecoilValueLoadable } from 'recoil';
import { getLoadableContents } from '../data/utils/loadable';
import { networkNameState } from '../data/network';

export const useFinderLink = (mode: 'blocks' | 'hash', value: any) => {
  const networkLoadable = useRecoilValueLoadable(networkNameState);
  const network = getLoadableContents(networkLoadable);

  switch (mode) {
    case 'blocks':
      return `https://finder.extraterrestrial.money/${network}/blocks/${value}`;
    case 'hash':
      return `https://finder.extraterrestrial.money/${network}/tx/${value}`;
    default:
      return '';
  }
};
