import React, { StrictMode } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';

import WalletConnectProvider from '../layouts/WalletConnectProvider';
//import { LanguageProvider } from '../providers/LanguageProvider';

import Loading from '../components/core/Loading';
import theme from '../styles/themes/redesign';
import { RecoilRoot } from 'recoil';
import Boundary from '../components/mirror/Boundary';
import { useInitAddress, useInitNetwork } from '../layouts/init';
import { Layout } from '../components/common/Layout';
import { QueryClient, QueryClientProvider } from 'react-query';

const CommunityFarming = React.lazy(() => import('../pages/CommunityFarming'));

const App = () => {
  useInitAddress();
  useInitNetwork();

  return (
    <Layout>
      <Routes />
    </Layout>
  );
};

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/" component={CommunityFarming} />
    </Switch>
  );
};

const Root = () => {
  const queryClient = new QueryClient();
  return (
    <StrictMode>
      <RecoilRoot>
        <Boundary fallback={<Loading />}>
          <Router>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <QueryClientProvider client={queryClient}>
                <WalletConnectProvider>
                  <App />
                </WalletConnectProvider>
              </QueryClientProvider>
            </ThemeProvider>
          </Router>
        </Boundary>
      </RecoilRoot>
    </StrictMode>
  );
};

export default Root;
