import { useContext, useEffect, useState } from 'react';
import { Grid, Typography, makeStyles, Modal } from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import ReportOutlinedIcon from '@material-ui/icons/ReportOutlined';
import { palette, fontWeights } from '../../styles/themes/redesign';
import { AngelAvatarSvg } from '../../content/images';
import { hexToRgbA } from '../../libs/util';
import VaultIcon from '../core/VaultIcon';
import AssetIcon from '../common/AssetIcon';
import { VaultInfoDialog } from './VaultInfoDialog';
import {
  Pending,
  VaultTransactionStatusDialog
} from './VaultTransactionStatusDialog';
import { CloseButton } from './CloseButton';
import { VaultDetailsButton } from './VaultDetailsButton';
import { NewModalContext } from '../../providers/NewModalProvider';
import ProtocolChip from '../common/ProtocolChip';
import Button from '../common/Button';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import { StrategyInfo, SupportedAsset } from '../../store/strategies';
import { getStrategyQuery } from '../../data/apollo/factory';
import { useRecoilValue } from 'recoil';
import { formatRate, MICRO } from '@anchor-protocol/notation';
import {
  CreateTxFailed,
  TxFailed,
  TxResult,
  TxUnspecifiedError,
  UserDenied
} from '@terra-money/wallet-provider';
import { gt, safeParseFloat, safeParseInt } from '../../libs/math';
import { useNativeBalances } from '../../data/mirror/normalize';
import { useApolloFactory, useGasAndTax } from '../../hooks/useApolloFactory';
import { AssetSelectorDialog } from './AssetSelectorDialog';
import useTax from '../../hooks/useTax';
import {
  microNumberToDollarValue,
  formatMicroNumber,
  formatInput,
  parseInput,
  dollarInputToLP,
  inputToDollarValue,
  microNumberToInput
} from '../../libs/formatting';

export type PostError =
  | UserDenied
  | CreateTxFailed
  | TxFailed
  | TxUnspecifiedError;

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'absolute',
    width: '100%',
    maxWidth: 421,
    minHeight: 433,
    top: '50% ',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 36,
    backgroundColor: palette.modalBlue,
    border: `1px solid ${hexToRgbA('#FFFFFF', 0.2)}`,
    boxShadow: `0px 3px 10px rgba(0, 0, 0, 0.3)`
  },
  modalInnerContainer: {
    margin: 10,
    position: 'relative'
  },
  modalRow: {},
  modalSection: {},
  modalVaultInfo: {
    padding: 10
  },
  modalDepositWithdraw: {
    minHeight: 225,
    backgroundColor: hexToRgbA(palette.darkBlueGrey, 0.8),
    border: `1px solid ${hexToRgbA('#FFFFFF', 0.2)}`,
    padding: 10,
    borderRadius: 25
  },
  vaultLabel: {
    marginTop: 12,
    fontSize: theme.typography.pxToRem(20),
    color: palette.mirrorBlue,
    fontWeight: fontWeights.semiBold,
    lineHeight: 1,
    marginBottom: 12
  },
  vaultLabelAnchor: {
    color: palette.anchorGreen
  },
  protocolChipContainer: {
    width: 80,
    height: 24
  },
  vaultAPYText: {
    fontSize: theme.typography.pxToRem(22),
    fontWeight: fontWeights.semiBold,
    margin: 0,
    lineHeight: 1
  },
  vaultAPYLabelText: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: fontWeights.medium,
    opacity: 0.6,
    margin: 0,
    lineHeight: 1
  },
  vaultTokenPriceText: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: fontWeights.semiBold,
    marginTop: 16,
    marginBottom: 5,
    lineHeight: 1
  },
  vaultTokenPriceLabelText: {
    fontSize: theme.typography.pxToRem(13),
    fontWeight: fontWeights.medium,
    opacity: 0.6,
    margin: 0,
    lineHeight: 1
  },
  vaultDPRText: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: fontWeights.semiBold,
    margin: 0,
    marginBottom: 2,
    lineHeight: 1
  },
  vaultDPRLabelText: {
    fontSize: theme.typography.pxToRem(13),
    fontWeight: fontWeights.medium,
    opacity: 0.6,
    margin: 0,
    lineHeight: 1
  },
  depositInfoContainer: {
    borderTop: '1px solid rgba(255,255,255,.2)',
    padding: 12
  },
  depositInfoText: {
    background: palette.darkBlueGrey,
    padding: '6px 13px',
    marginBottom: 7,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: fontWeights.medium,
    width: 90,
    borderRadius: 10
  },
  depositInfoLPText: {
    fontSize: theme.typography.pxToRem(15),
    color: 'rgba(255,255,255,1)',
    fontWeight: fontWeights.medium
  },
  depositInfoLPValueText: {
    fontSize: theme.typography.pxToRem(12),
    color: 'rgba(255,255,255,.5)',
    fontWeight: fontWeights.medium
  },
  depositValueText: {
    fontSize: theme.typography.pxToRem(22),
    color: 'rgba(255,255,255,1)',
    fontWeight: fontWeights.semiBold,
    textAlign: 'right'
  },
  depositValueProfitLossText: {
    fontSize: theme.typography.pxToRem(15),
    color: palette.systemGreen,
    fontWeight: fontWeights.medium,
    textAlign: 'right'
  },
  depositValueLossText: {
    color: palette.plRed
  },
  plIcon: {
    fontSize: 14,
    marginRight: 2
  },
  modalInputForm: {
    backgroundColor: palette.nearBlack,
    borderRadius: 20,
    minHeight: 80,
    padding: 10,
    margin: '12px 0'
  },
  txWarningText: {
    fontSize: theme.typography.pxToRem(12),
    display: 'flex',
    alignItems: 'center',
    marginBottom: 12
  },
  txWarningIcon: {
    fontSize: 12,
    marginRight: 4
  },
  txErrorText: {
    fontSize: theme.typography.pxToRem(12),
    display: 'flex',
    alignItems: 'center',
    marginBottom: 12,
    color: palette.plRed
  },
  txErrorIcon: {
    fontSize: 12,
    marginRight: 4
  },
  vaultTransactionFeeLabelText: {
    fontSize: theme.typography.pxToRem(13),
    color: 'rgba(255,255,255,.5)',
    fontWeight: fontWeights.medium
  },
  vaultEstimatedLPLabelText: {
    fontSize: theme.typography.pxToRem(13),
    color: 'rgba(255,255,255,.5)',
    fontWeight: fontWeights.medium
  },
  vaultTransactionFeeText: {
    fontSize: theme.typography.pxToRem(13),
    fontWeight: fontWeights.medium,
    textAlign: 'right'
  },
  vaultEstimatedLPText: {
    fontSize: theme.typography.pxToRem(13),
    fontWeight: fontWeights.medium,
    textAlign: 'right'
  },
  vaultEstimatedLPSplitText: {
    fontSize: theme.typography.pxToRem(10),
    color: 'rgba(255,255,255,.5)',
    fontWeight: fontWeights.medium,
    textAlign: 'right',
    marginBottom: 12
  },
  button: {
    backgroundColor: '#253651'
  },
  buttonText: {
    fontWeight: fontWeights.semiBold
  },
  buttonActive: {
    backgroundColor: palette.mirrorBlue,
    '&:hover': {
      backgroundColor: palette.mirrorBlueHover
    }
  },
  assetSelector: {
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 13,
    backgroundColor: palette.modalBlue,
    boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.3)',
    padding: 6,
    height: 33,
    '&:hover': {
      color: 'rgba(255,255,255,.5)'
    }
  },
  assetSelectorAssetIcon: {
    width: 21,
    height: 21,
    marginRight: 8
  },
  assetSelectorText: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: fontWeights.medium,
    margin: '0 2px'
  },
  assetSelectorExpandIcon: {
    fontSize: 20
  },
  formAssetBalance: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: fontWeights.medium,
    color: 'rgba(255,255,255,.5)',
    marginTop: 2,
    marginLeft: 2
  },
  formAssetBalanceLink: {
    color: palette.mirrorBlue,
    cursor: 'pointer'
  },
  formTextInput: {
    backgroundColor: palette.nearBlack,
    border: 0,
    fontSize: theme.typography.pxToRem(24),
    fontWeight: fontWeights.medium,
    width: '100%',
    textAlign: 'right',
    color: '#FFFFFF'
  },
  formInputEstimatedValue: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: fontWeights.medium,
    color: 'rgba(255,255,255,.5)',
    marginTop: 2,
    marginLeft: 2
  },
  confirmButton: {},
  confirmButtonDisabled: {
    cursor: 'not-allowed'
  },
  confirmButtonTextDisabled: {
    color: 'rgba(255,255,255,.5)'
  },
  txPendingContainer: {
    position: 'relative',
    maxWidth: 410,
    top: '49%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: hexToRgbA('#12161F', 0.9),
    border: `1px solid ${hexToRgbA('#FFFFFF', 0.2)}`,
    padding: 10,
    borderRadius: 25
  },
  withdrawAssetContainer: {
    flexGrow: 2,
    marginBottom: 10
  },
  withdrawAssetButtonContainer: {
    border: '1px solid rgba(255,255,255, .2)',
    padding: 10,
    marginTop: 21,
    marginBottom: 10,
    borderRadius: 20
  },
  withdrawAssetTitleText: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: fontWeights.medium
  },
  withdrawAssetButton: {
    border: '2px solid rgba(255,255,255,.5)',
    color: 'rgba(255,255,255,.6)',
    '&:hover': {
      color: 'rgba(255,255,255,.6)'
    }
  },
  withdrawAssetButtonActive: {
    borderColor: palette.mirrorBlue
  },
  withdrawAssetButtonText: {},
  withdrawAssetButtonTextActive: {
    color: palette.mirrorBlue
  },
  assetSelectorWithdraw: {
    cursor: 'initial',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 13,
    backgroundColor: palette.modalBlue,
    boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.3)',
    padding: 6,
    height: 33,
    '&:hover': {
      color: 'rgba(255,255,255,1)'
    }
  },
  modalInputFormWithdraw: {
    backgroundColor: palette.nearBlack,
    borderRadius: 20,
    minHeight: 80,
    padding: 10,
    margin: 0
  },
  txInformationContainer: {
    marginBottom: 10
  }
}));

const VaultInfo = ({
  vault,
  handleVaultInfoDialog
}: {
  vault: StrategyInfo;
  handleVaultInfoDialog: () => void;
}) => {
  const { handleClose } = useContext(NewModalContext);
  const classes = useStyles();

  return (
    <Grid
      className={`${classes.modalSection} ${classes.modalVaultInfo}`}
      container
      direction={'column'}
      justify={'space-between'}>
      <Grid item container className={classes.modalRow}>
        <Grid item xs={6}>
          <VaultIcon
            className=""
            vaultKey={vault.key}
            icon1Type={'bordered'}
            icon2Type="bordered"
            variant={'side'}
          />
        </Grid>
        <Grid item xs={6} container alignItems={'center'} justify={'flex-end'}>
          <VaultDetailsButton
            style={{ marginRight: 5 }}
            onClick={() => handleVaultInfoDialog()}
          />
          <CloseButton onClick={handleClose} />
        </Grid>
      </Grid>
      <Grid item container className={classes.modalRow}>
        <Typography
          className={`${classes.vaultLabel} ${
            vault.protocolKey === 'anc' && classes.vaultLabelAnchor
          }`}>
          {vault.name}
        </Typography>
      </Grid>
      <Grid item container className={classes.modalRow}>
        <Grid item xs={6}>
          <Grid item className={classes.protocolChipContainer}>
            <ProtocolChip protocolNameShort={vault.protocolNameShort} />
          </Grid>
        </Grid>
        <Grid
          item
          xs={6}
          container
          alignItems={'flex-end'}
          justify={'flex-end'}
          direction={'column'}>
          {vault.id === 31 ? (
            <Typography>----</Typography>
          ) : (
            <>
              <img
                style={{ width: 26, height: 26 }}
                src={AngelAvatarSvg}
                alt={'Angel'}
              />
            </>
          )}
        </Grid>
      </Grid>
      <Grid item container className={classes.modalRow}>
        <Grid
          item
          xs={6}
          container
          alignItems={'flex-start'}
          justify={'flex-end'}
          direction={'column'}>
          <Typography className={classes.vaultTokenPriceText} align={'right'}>
            {microNumberToDollarValue(vault.tokenPrice)}
          </Typography>
          <Typography
            className={classes.vaultTokenPriceLabelText}
            align={'right'}>
            Price
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          container
          alignItems={'flex-end'}
          justify={'flex-end'}
          direction={'column'}>
          {vault.id === 31 ? (
            <Typography>----</Typography>
          ) : (
            <>
              <Typography className={classes.vaultDPRText} align={'right'}>
                {formatRate(vault.apolloApr)}%
              </Typography>
              <Typography className={classes.vaultDPRLabelText} align={'right'}>
                HALO APR
              </Typography>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

const DepositWithdraw = ({
  toggleAssetDialog,
  handleFormMode,
  handleInputChange,
  handleMaxClick,
  handleSubmit,
  handleWithdrawAssetSelection,
  confirmButtonText,
  txFee,
  tax,
  usingZapper,
  withdrawAsset,
  confirmButtonState,
  inputError,
  inputErrorText,
  balance,
  uusdBalance,
  formMode,
  inputValue,
  inputDollarValue,
  assetName,
  vault
}: {
  handleFormMode: (formMode: 'deposit' | 'withdraw') => void;
  handleWithdrawAssetSelection: (assetSelection: string) => void;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  toggleAssetDialog: () => void;
  handleMaxClick: () => void;
  handleSubmit: () => void;
  confirmButtonText: string;
  confirmButtonState: string;
  inputError: boolean;
  inputErrorText: string;
  balance: number;
  uusdBalance: number;
  formMode: string;
  inputValue: string;
  txFee: string;
  tax: string;
  usingZapper: boolean;
  withdrawAsset: string;
  inputDollarValue: string;
  assetName: string;
  vault: StrategyInfo;
}) => {
  const classes = useStyles();
  return (
    <Grid
      className={`${classes.modalSection} ${classes.modalDepositWithdraw}`}
      container
      justify={'space-between'}
      direction={'column'}
      alignItems={'center'}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Button
            onClick={() => handleFormMode('deposit')}
            textClassName={classes.buttonText}
            className={`${classes.button} ${
              formMode === 'deposit' && classes.buttonActive
            }`}
            filled>
            Deposit
          </Button>
        </Grid>
        <Grid item xs={6} container alignItems={'center'} justify={'flex-end'}>
          <Button
            onClick={() => handleFormMode('withdraw')}
            textClassName={classes.buttonText}
            className={`${classes.button} ${
              formMode === 'withdraw' && classes.buttonActive
            }`}
            filled>
            Withdraw
          </Button>
        </Grid>
      </Grid>
      {/* Start Deposit Input */}
      {formMode === 'deposit' ? (
        <Grid container alignItems={'flex-start'}>
          <Grid
            item
            container
            xs={12}
            className={classes.modalInputForm}
            alignItems={'flex-start'}>
            <Grid xs={7} item>
              <div
                className={classes.assetSelector}
                onClick={() => toggleAssetDialog()}>
                {usingZapper ? (
                  <AssetIcon className={classes.assetSelectorAssetIcon} />
                ) : (
                  <VaultIcon
                    className={''}
                    vaultKey={vault.key}
                    icon1Type={'bordered'}
                    icon2Type="bordered"
                    variant={'side'}
                    scale={'mini'}
                  />
                )}
                <Typography className={classes.assetSelectorText}>
                  {usingZapper ? 'UST' : assetName}
                </Typography>
                <ExpandMoreOutlinedIcon
                  className={classes.assetSelectorExpandIcon}
                />
              </div>
              <Typography className={classes.formAssetBalance}>
                Balance:
                {` ${formatMicroNumber(balance)} ${
                  usingZapper ? 'UST' : 'LP'
                } `}
                <span
                  className={classes.formAssetBalanceLink}
                  onClick={handleMaxClick}>
                  (MAX)
                </span>
              </Typography>
            </Grid>
            <Grid
              item
              xs={5}
              container
              direction={'column'}
              alignItems={'flex-end'}
              justify={'flex-start'}>
              <input
                className={classes.formTextInput}
                type="number"
                min="0"
                step="0.000001"
                lang="en"
                value={inputValue}
                onChange={handleInputChange}
              />
              <Typography className={classes.formInputEstimatedValue}>
                {usingZapper
                  ? `~$${formatInput(inputValue, 2)}`
                  : `~${inputDollarValue}`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          className={classes.withdrawAssetContainer}
          alignItems={'flex-start'}>
          <Grid
            container
            spacing={1}
            className={classes.withdrawAssetButtonContainer}>
            <Grid item xs={12}>
              <Typography className={classes.withdrawAssetTitleText}>
                Receive withdrawal as:
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Button
                className={`${classes.withdrawAssetButton} ${
                  withdrawAsset === 'UST' && classes.withdrawAssetButtonActive
                }`}
                textClassName={`${classes.withdrawAssetButtonText} ${
                  withdrawAsset === 'UST' &&
                  classes.withdrawAssetButtonTextActive
                }`}
                onClick={() => handleWithdrawAssetSelection('UST')}>
                UST
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                className={`${classes.withdrawAssetButton} ${
                  withdrawAsset === assetName &&
                  classes.withdrawAssetButtonActive
                }`}
                textClassName={`${classes.withdrawAssetButtonText} ${
                  withdrawAsset === assetName &&
                  classes.withdrawAssetButtonTextActive
                }`}
                onClick={() => handleWithdrawAssetSelection(assetName)}>
                {assetName}
              </Button>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            className={classes.modalInputFormWithdraw}
            alignItems={'flex-start'}>
            <Grid xs={12} item>
              <Typography
                className={classes.withdrawAssetTitleText}
                style={{ marginBottom: 8 }}>
                Withdraw:
              </Typography>
            </Grid>
            <Grid xs={7} item>
              <div className={classes.assetSelectorWithdraw}>
                <VaultIcon
                  className={''}
                  vaultKey={vault.key}
                  icon1Type={'bordered'}
                  icon2Type="bordered"
                  variant={'side'}
                  scale={'mini'}
                />
                <Typography className={classes.assetSelectorText}>
                  {assetName}
                </Typography>
              </div>
              <Typography className={classes.formAssetBalance}>
                {`Balance: ${formatMicroNumber(balance)} LP `}
                <span
                  className={classes.formAssetBalanceLink}
                  onClick={handleMaxClick}>
                  (MAX)
                </span>
              </Typography>
            </Grid>
            <Grid
              item
              xs={5}
              container
              direction={'column'}
              alignItems={'flex-end'}
              justify={'flex-start'}>
              <input
                className={classes.formTextInput}
                type="number"
                min="0"
                step="0.000001"
                lang="en"
                value={inputValue}
                onChange={handleInputChange}
              />
              <Typography className={classes.formInputEstimatedValue}>
                {formMode === 'deposit' && usingZapper
                  ? `$${formatInput(inputValue, 2)}`
                  : `~${inputDollarValue}`}
              </Typography>
            </Grid>
          </Grid>
          {withdrawAsset === 'UST' && (
            <>
              <Grid container justify={'center'}>
                <Grid item>
                  <ExpandMoreOutlinedIcon />
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={12}
                className={classes.modalInputFormWithdraw}
                alignItems={'flex-start'}>
                <Grid xs={12} item>
                  <Typography
                    className={classes.withdrawAssetTitleText}
                    style={{ marginBottom: 8 }}>
                    Receive (estimated):
                  </Typography>
                </Grid>
                <Grid xs={7} item>
                  <div className={classes.assetSelectorWithdraw}>
                    <AssetIcon className={classes.assetSelectorAssetIcon} />
                    <Typography className={classes.assetSelectorText}>
                      UST
                    </Typography>
                  </div>
                  <Typography className={classes.formAssetBalance}>
                    {`Balance: ${formatMicroNumber(uusdBalance)} UST`}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={5}
                  container
                  direction={'column'}
                  alignItems={'flex-end'}
                  justify={'flex-start'}>
                  <Typography className={classes.formTextInput}>
                    {inputDollarValue}
                  </Typography>
                  <Typography className={classes.formInputEstimatedValue}>
                    {formMode === 'deposit' && usingZapper
                      ? `$${formatInput(inputValue, 2)}`
                      : `~${inputDollarValue}`}
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      )}
      {parseInput(inputValue) > 0 && (
        <Grid item container className={classes.txInformationContainer}>
          {inputError && (
            <Grid item xs={12}>
              <Typography className={classes.txErrorText}>
                <ReportOutlinedIcon className={classes.txErrorIcon} />
                {inputErrorText}
              </Typography>
            </Grid>
          )}
          {usingZapper && (
            <Grid item xs={12}>
              <Typography className={classes.txWarningText}>
                <InfoIcon className={classes.txWarningIcon} />
                {formMode === 'deposit'
                  ? `UST Deposit will be swapped into ${assetName} Tokens`
                  : `${inputValue} ${assetName} tokens will be swapped into UST`}
              </Typography>
            </Grid>
          )}
          <Grid
            item
            xs={6}
            container
            alignItems={'flex-start'}
            justify={'flex-start'}
            direction={'column'}>
            <Typography
              className={classes.vaultTransactionFeeLabelText}
              align={'right'}>
              Transaction Fee
            </Typography>
            {usingZapper && (
              <Typography
                className={classes.vaultEstimatedLPLabelText}
                align={'left'}>
                {formMode === 'deposit'
                  ? `Estimated LP Deposited`
                  : `Estimated UST Withdrawn`}
              </Typography>
            )}
          </Grid>
          <Grid
            item
            xs={6}
            container
            alignItems={'flex-end'}
            justify={'flex-start'}
            direction={'column'}>
            <Typography
              className={classes.vaultTransactionFeeText}
              align={'right'}>
              {txFee} UST
              {usingZapper && formMode === 'deposit' && ` gas + ${tax} UST tax`}
            </Typography>
            {usingZapper && (
              <>
                <Typography
                  className={classes.vaultEstimatedLPText}
                  align={'right'}>
                  {formMode === 'deposit'
                    ? `${dollarInputToLP(
                        inputValue,
                        vault.baseTokenPrice
                      )} ${assetName}`
                    : inputDollarValue}
                </Typography>
                {/*
                TODO: Get underlying assets. Would need to know if a vault's baseToken is an LP token...
                <Typography
                  className={classes.vaultEstimatedLPSplitText}
                  align={'right'}>
                  0.00 MIR + 0.00 UST LP
                </Typography> */}
              </>
            )}
          </Grid>
        </Grid>
      )}
      <Grid container>
        <Grid item xs={12}>
          <Button
            textClassName={`${classes.buttonText} ${
              confirmButtonState === 'disabled' &&
              classes.confirmButtonTextDisabled
            }`}
            className={`${classes.button} ${classes.confirmButton} ${
              confirmButtonState === 'disabled'
                ? classes.confirmButtonDisabled
                : classes.buttonActive
            }`}
            onClick={handleSubmit}
            filled>
            {confirmButtonText}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

const DepositInfo = ({
  lp = 0,
  lpAsset1Value = 0,
  lpAsset2Value = 0,
  depositValue = 0,
  depositProfit = 1
}) => {
  const classes = useStyles();
  return (
    <Grid
      className={classes.depositInfoContainer}
      container
      justify={'space-between'}>
      <Grid item xs={6}>
        <Typography className={classes.depositInfoText}>Deposited</Typography>
        <Typography className={classes.depositInfoLPText}>
          {lp} mAsset-UST LP
        </Typography>
        <Typography className={classes.depositInfoLPValueText}>
          {lpAsset1Value} mAsset + {lpAsset2Value} UST
        </Typography>
      </Grid>
      <Grid item xs={6} container direction={'column'} justify={'flex-end'}>
        <Typography className={classes.depositValueText}>
          ${depositValue}
        </Typography>
        <Typography
          className={`${classes.depositValueProfitLossText} ${
            depositProfit < 0 && classes.depositValueLossText
          }`}>
          {depositProfit > 0 && <ArrowUpwardIcon className={classes.plIcon} />}
          {depositProfit < 0 && (
            <ArrowDownwardIcon className={classes.plIcon} />
          )}
          ${depositProfit} Today
        </Typography>
      </Grid>
    </Grid>
  );
};

const VaultModal = ({ vaultKey, ...rest }: { vaultKey: SupportedAsset }) => {
  const [usingZapper, setUsingZapper] = useState(true);
  const [withdrawAsset, setWithdrawAsset] = useState('UST'); //TODO: remove in favor of "usingZapper"
  const [showAssetDialog, setShowAssetDialog] = useState(false);
  const [inputValue, setInputValue] = useState('0');
  const [inputDollarValue, setInputDollarValue] = useState('$0');
  // eslint-disable-next-line
  const [deposited, setDeposited] = useState(false);
  const [inputError, setInputError] = useState(true);
  const [inputErrorText, setInputErrorText] = useState('');
  const [confirmButtonText, setConfirmButtonText] = useState('Enter an amount');
  const [confirmButtonState, setConfirmButtonState] = useState('disabled');
  // const [assetInUST, setAssetInUST] = useState(0);
  // const [balance, setBalance] = useState(10);
  // const [transactionFee, setTransactionFee] = useState(0);
  // const [estimatedLP, setEstimatedLP] = useState(0);
  const [formMode, setFormMode] = useState<'deposit' | 'withdraw'>('deposit');
  const [showVaultInfoDialog, setShowVaultInfoDialog] = useState(false);
  const { calcTax } = useTax();
  const [tax, setTax] = useState('0');

  const { handleClose } = useContext(NewModalContext);

  const { contents: nativeBalances } = useNativeBalances();
  const uusdBalance = nativeBalances['uusd'];

  const vault = useRecoilValue(getStrategyQuery(vaultKey));
  const {
    depositToStrategy,
    withdrawFromStrategy,
    zapIntoStrategy,
    zapOutOfStrategy
  } = useApolloFactory();

  const handleInputAsset = (usingZapper: boolean) => {
    setUsingZapper(usingZapper);
    setShowAssetDialog(false);
    resetInputField();
  };

  const handleWithdrawAssetSelection = (selectedAsset: string) => {
    setWithdrawAsset(selectedAsset);
    if (selectedAsset === 'UST') setUsingZapper(true);
    else setUsingZapper(false);
  };

  const toggleAssetDialog = () => {
    setShowAssetDialog(!showAssetDialog);
  };

  const handleVaultInfoDialog = () => {
    setShowVaultInfoDialog(!showVaultInfoDialog);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const parsedInput = parseInput(e.target.value);

    if (parsedInput <= balance && parsedInput >= 0) {
      if (usingZapper && formMode === 'deposit')
        setInputDollarValue(`$${formatInput(e.target.value, 2)}`);
      else
        setInputDollarValue(
          inputToDollarValue(e.target.value, vault?.baseTokenPrice || 0)
        );
      setInputError(false);
      setInputErrorText('');
      setConfirmButtonText('Confirm');
      setConfirmButtonState('enabled');
    }

    if (parsedInput > balance || parsedInput <= 0) {
      setInputError(true);
      setInputErrorText('Insufficient Balance');
      setConfirmButtonState('disabled');
    }

    //Only allow 6 decimals
    let micro = safeParseFloat(e.target.value) * MICRO;
    if (micro - safeParseInt(micro.toString()) > 0) {
      setInputError(true);
      setInputErrorText('No more than 6 decimals are allowed');
      setConfirmButtonText('Too Many Decimals');
      setConfirmButtonState('disabled');
    }

    if (usingZapper && formMode === 'deposit') {
      const taxInput = parsedInput.toString();
      const tax = formatMicroNumber(calcTax(taxInput), 2);
      setTax(tax);
    }

    setInputValue(e.target.value);
  };

  /* Gas & tax calculation */
  const pretax = ''; //TODO: Set when zapping in UST
  const includeTax = false; //TODO: Set to true when zapping in UST
  const gasAndTax = useGasAndTax(pretax, includeTax);
  useEffect(() => {
    if (gt(gasAndTax, uusdBalance)) {
      setInputError(true);
      setInputErrorText('Transaction fee exceeds UST balance');
      setConfirmButtonState('disabled');
    }
  }, [gasAndTax, uusdBalance]);

  /* Submit */
  const [submitted, setSubmitted] = useState(false);
  const [txResult, setTxResult] = useState<TxResult>();
  const [txError, setTxError] = useState<PostError>();

  const handleSubmit = async () => {
    if (inputError === false && confirmButtonState === 'enabled' && vault) {
      setSubmitted(true);
      try {
        let response;
        if (formMode === 'deposit') {
          if (usingZapper)
            response = await zapIntoStrategy(
              vault.id,
              parseInput(inputValue).toString()
            );
          else
            response = await depositToStrategy(
              vault.id,
              vault.baseToken,
              parseInput(inputValue)
            );
        } else if (usingZapper)
          response = await zapOutOfStrategy(
            vault.id,
            parseInput(inputValue).toString()
          );
        else
          response = await withdrawFromStrategy(
            vault.id,
            parseInput(inputValue)
          );
        setTxResult(response);
      } catch (error: any) {
        setTxError(error);
      }
    }
  };

  const handleMaxClick = () => {
    if (balance > 0) {
      if (usingZapper && formMode === 'deposit') {
        setInputValue(`${formatInput(microNumberToInput(balance - 3000000))}`);
        setInputDollarValue(
          `$${formatInput(microNumberToInput(balance - 3000000), 2)}`
        );
        const taxInput = (balance - 3000000).toString();
        const tax = formatMicroNumber(calcTax(taxInput), 2);
        setTax(tax);
      } else {
        setInputValue(`${formatInput(microNumberToInput(balance))}`);
        setInputDollarValue(
          microNumberToDollarValue(balance, vault?.baseTokenPrice || 0)
        );
      }
      setInputError(false);
      setInputErrorText('');
      setConfirmButtonText('Confirm');
      setConfirmButtonState('enabled');
    }
  };

  const handleFormMode = (formMode: 'deposit' | 'withdraw') => {
    setFormMode(formMode);
    resetInputField();

    if (formMode === 'withdraw' && vault) {
      setUsingZapper(false);
      handleWithdrawAssetSelection(vault.baseTokenName);
      setConfirmButtonText('Select an asset');
    } else {
      setConfirmButtonText('Enter an amount');
    }
  };

  const resetInputField = () => {
    setInputValue('0');
    setInputDollarValue('$0');
    setInputError(false);
    setInputErrorText('');
    setConfirmButtonState('disabled');
    setConfirmButtonText('Enter an amount');
  };

  const classes = useStyles();

  //TODO: Handle better
  if (!vault) return <div>Vault not found</div>;

  let balance: number;
  if (usingZapper) {
    balance =
      formMode === 'deposit'
        ? safeParseInt(uusdBalance)
        : vault.stakedTokenBalance;
  } else {
    balance =
      formMode === 'deposit'
        ? vault.stakableTokenBalance
        : vault.stakedTokenBalance;
  }
  return (
    <Grid className={classes.container} container {...rest}>
      <Grid
        container
        className={classes.modalInnerContainer}
        justify={'space-between'}
        direction={'column'}>
        <VaultInfo
          vault={vault}
          handleVaultInfoDialog={handleVaultInfoDialog}
        />
        {deposited && <DepositInfo />}
        <DepositWithdraw
          confirmButtonText={confirmButtonText}
          confirmButtonState={confirmButtonState}
          vault={vault}
          formMode={formMode}
          inputValue={inputValue}
          balance={balance}
          uusdBalance={safeParseInt(uusdBalance)}
          inputErrorText={inputErrorText}
          inputError={inputError}
          toggleAssetDialog={toggleAssetDialog}
          handleSubmit={handleSubmit}
          handleMaxClick={handleMaxClick}
          handleFormMode={handleFormMode}
          handleInputChange={handleInputChange}
          handleWithdrawAssetSelection={handleWithdrawAssetSelection}
          txFee={formatMicroNumber(gasAndTax, 4)}
          tax={tax}
          withdrawAsset={withdrawAsset}
          usingZapper={usingZapper}
          inputDollarValue={inputDollarValue}
          assetName={vault.baseTokenName}
        />
      </Grid>
      <VaultInfoDialog
        open={showVaultInfoDialog}
        handleClose={handleVaultInfoDialog}
        vault={vault}
      />
      <AssetSelectorDialog
        asset={vault.key}
        open={showAssetDialog}
        handleInputAsset={handleInputAsset}
        formMode={formMode}
        stakedTokenBalance={formatMicroNumber(vault.stakedTokenBalance)}
        stakedTokenValue={formatMicroNumber(vault.stakedTokenValue)}
        stakableTokenBalance={formatMicroNumber(vault.stakableTokenBalance)}
        ustBalance={formatMicroNumber(uusdBalance)}
      />
      <Modal open={submitted && !txError && !txResult} onClose={() => {}}>
        <Grid className={`${classes.txPendingContainer}`} container>
          <Pending
            amount={formatInput(inputValue, 6)}
            formMode={formMode}
            assetName={vault.baseTokenName}
            vaultName={vault.name}
            usingZapper={usingZapper}
            amountValue={inputDollarValue}
          />
        </Grid>
      </Modal>
      {submitted && (txError || txResult) && (
        <VaultTransactionStatusDialog
          vault={vault}
          txnMode={formMode}
          open={submitted}
          handleClose={handleClose}
          txResult={txResult}
          txError={txError}
          amount={formatInput(inputValue, 6)}
          formMode={formMode}
          amountValue={inputDollarValue}
          txFee={formatMicroNumber(gasAndTax, 4)}
          usingZapper={usingZapper}
        />
      )}
    </Grid>
  );
};

export default VaultModal;
