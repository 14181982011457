import { selector, useRecoilValue } from 'recoil';
import axios from 'axios';
import { ICON_URL } from '../../constants';
import { getIsTokenNative, lookupSymbol } from '../../libs/parse';
import { BalanceKey, PriceKey } from './contractKeys';
import { networkQuery } from '../network';

const protocolAddressQuery = selector({
  key: 'protocolAddress',
  get: async ({ get }) => {
    const { contract: url } = get(networkQuery);

    try {
      const { data } = await axios.get<ProtocolJSON>(url);
      return data;
    } catch {
      throw new Error(`Failed to load contract: ${url}`);
    }
  }
});

const protocolHelpersQuery = selector({
  key: 'protocolHelpers',
  get: ({ get }) => {
    const { whitelist } = get(protocolAddressQuery);

    const listedAll = Object.values(whitelist);
    const listed = listedAll.filter(({ status }) => status !== 'DELISTED');

    const getToken = (symbol?: string) =>
      !symbol
        ? ''
        : getIsTokenNative(symbol)
        ? symbol
        : listed.find((item) => item.symbol === symbol)?.['token'] ?? '';

    const getSymbol = (token?: string) =>
      !token
        ? ''
        : getIsTokenNative(token)
        ? token
        : whitelist[token]?.symbol ?? '';

    const getIcon = (token: string) => {
      const symbol = getSymbol(token);
      const ticker = getIsTokenNative(symbol)
        ? lookupSymbol(symbol)
        : symbol.startsWith('m')
        ? symbol.slice(1)
        : symbol;

      const icon = ticker && `${ICON_URL}/${ticker}.png`;
      return icon;
    };

    const getPriceKey = (key: PriceKey, token: string) =>
      getIsTokenNative(token)
        ? PriceKey.NATIVE
        : getIsDelisted(token)
        ? PriceKey.END
        : key === PriceKey.ORACLE
        ? getIsPreIPO(key)
          ? PriceKey.PRE
          : key
        : key;

    const getBalanceKey = (token: string) =>
      getIsTokenNative(token) ? BalanceKey.NATIVE : BalanceKey.TOKEN;

    const getIsDelisted = (token: string) =>
      whitelist[token]?.status === 'DELISTED';

    const getIsPreIPO = (token: string) =>
      whitelist[token]?.status === 'PRE_IPO';

    const toAssetInfo = (token: string) =>
      getIsTokenNative(token)
        ? { native_token: { denom: token } }
        : { token: { contract_addr: token } };

    const toToken = ({ amount, token }: Asset) => ({
      amount,
      info: toAssetInfo(token)
    });

    const parseAssetInfo = (info: AssetInfo | NativeInfo) => {
      const token =
        'native_token' in info
          ? info.native_token.denom
          : info.token.contract_addr;

      return { token, symbol: getSymbol(token) };
    };

    const parseToken = ({ amount, info }: AssetToken | NativeToken) => ({
      amount,
      ...parseAssetInfo(info)
    });

    return {
      listed,
      listedAll,

      getToken,
      getSymbol,
      getIcon,

      getPriceKey,
      getBalanceKey,

      getIsDelisted,
      getIsPreIPO,

      toAssetInfo,
      parseAssetInfo,

      toToken,
      parseToken
    };
  }
});

export const protocolQuery = selector({
  key: 'protocol',
  get: ({ get }) => ({
    ...get(protocolAddressQuery),
    ...get(protocolHelpersQuery)
  })
});

export const useProtocol = () => {
  return useRecoilValue(protocolQuery);
};
