import { makeStyles } from '@material-ui/core/styles';
import { ASSET_PATH } from '../../store/constants';

export const iconImages = {
  mAAPL: {
    default: `${ASSET_PATH}/icons/AAPL.png`
  },
  mARKK: {
    default: `${ASSET_PATH}/icons/ARKK.png`
  },
  mABNB: {
    default: `${ASSET_PATH}/icons/ABNB.png`
  },
  mAMC: {
    default: `${ASSET_PATH}/icons/AMC.png`
  },
  ANC: {
    default: `${ASSET_PATH}/icons/ANC.gif`
  },
  mAMZN: {
    default: `${ASSET_PATH}/icons/AMZN.png`
  },
  mBABA: {
    default: `${ASSET_PATH}/icons/BABA.png`
  },
  mBTC: {
    default: `${ASSET_PATH}/icons/BTC.png`
  },
  mCOIN: {
    default: `${ASSET_PATH}/icons/COIN.png`
  },
  mETH: {
    default: `${ASSET_PATH}/icons/ETH.png`
  },
  mFB: {
    default: `${ASSET_PATH}/icons/FB.png`
  },
  mGLXY: {
    default: `${ASSET_PATH}/icons/GLXY.png`
  },
  mGME: {
    default: `${ASSET_PATH}/icons/GME.png`
  },
  mGOOGL: {
    default: `${ASSET_PATH}/icons/GOOGL.png`
  },
  mGS: {
    default: `${ASSET_PATH}/icons/GS.png`
  },
  mIAU: {
    default: `${ASSET_PATH}/icons/IAU.png`
  },
  MIR: {
    default: `${ASSET_PATH}/icons/MIR.png`
  },
  mMSFT: {
    default: `${ASSET_PATH}/icons/MSFT.png`
  },
  mNFLX: {
    default: `${ASSET_PATH}/icons/NFLX.png`
  },
  mQQQ: {
    default: `${ASSET_PATH}/icons/QQQ.png`
  },
  mSLV: {
    default: `${ASSET_PATH}/icons/SLV.png`
  },
  mSPY: {
    default: `${ASSET_PATH}/icons/SPY.png`
  },
  mTSLA: {
    default: `${ASSET_PATH}/icons/TSLA.png`
  },
  mTWTR: {
    default: `${ASSET_PATH}/icons/TWTR.png`
  },
  mUSO: {
    default: `${ASSET_PATH}/icons/USO.png`
  },
  UST: {
    default: `${ASSET_PATH}/icons/UST.png`
  },
  mVIXY: {
    default: `${ASSET_PATH}/icons/VIXY.png`
  },
  UNKNOWN: {
    default: `${ASSET_PATH}/icons/unknown.png`
  }
};

const useStyles = makeStyles(() => ({
  iconImage: {
    width: '100%',
    height: 'auto'
  },
  iconWrapper: {
    display: 'inline-flex'
  }
}));

export const AssetIcon = ({ className, asset = 'UST', ...rest }) => {
  const classes = useStyles();
  return (
    <span className={`${classes.iconWrapper}`} {...rest}>
      <img
        alt={''}
        className={`${classes.iconImage} ${className}`}
        src={iconImages[asset].default}
      />
    </span>
  );
};

export default AssetIcon;
