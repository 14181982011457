import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  vaultDetails: {
    opacity: 0.501,
    '&:hover': {
      opacity: 1,
      cursor: 'pointer'
    }
  }
}));

export const VaultDetailsButton = ({ ...rest }) => {
  const classes = useStyles();

  return (
    <svg
      id="Vault_Details"
      data-name="Vault Details"
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 35 35"
      className={classes.vaultDetails}
      {...rest}>
      <rect
        id="Rectangle_308"
        data-name="Rectangle 308"
        width="35"
        height="35"
        rx="12"
        fill="#1c293e"
      />
      <g
        id="Group_1257"
        data-name="Group 1257"
        transform="translate(-1742 -84)">
        <path
          id="Path_113"
          data-name="Path 113"
          d="M17.064,15.328a1.75,1.75,0,1,0,1.75,1.75,1.749,1.749,0,0,0-1.75-1.75Z"
          transform="translate(1742.696 84.672)"
          fill="#fff"
        />
        <path
          id="Path_114"
          data-name="Path 114"
          d="M6.25,15.328A1.75,1.75,0,1,0,8,17.078a1.749,1.749,0,0,0-1.75-1.75Z"
          transform="translate(1746.5 84.672)"
          fill="#fff"
        />
        <path
          id="Path_115"
          data-name="Path 115"
          d="M27.906,15.328a1.75,1.75,0,1,0,1.75,1.75,1.749,1.749,0,0,0-1.75-1.75Z"
          transform="translate(1738.885 84.672)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};
