import { ReactNode } from 'react';
import { ConnectType, useWallet } from '@terra-money/wallet-provider';
import { Grid, Typography, makeStyles, Modal } from '@material-ui/core';
import { ReactComponent as Terra } from '../../styles/mirror/images/Terra.svg';
import WalletConnect from '../../styles/mirror/images/WalletConnect.png';
import { fontWeights } from '../../styles/themes/redesign';
import { hexToRgbA } from '../../libs/util';

const size = { width: 24, height: 24 };

const useStyles = makeStyles((theme) => ({
  assetSelectorContainer: {
    position: 'relative',
    maxWidth: 410,
    top: '49%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: hexToRgbA('#12161F', 0.9),
    border: `1px solid ${hexToRgbA('#FFFFFF', 0.2)}`,
    padding: `20px 20px 10px 20px`,
    borderRadius: 25
  },
  assetSelectorTitleText: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: fontWeights.semiBold,
    marginBottom: 10
  },
  assetSelectorRow: {
    padding: 12,
    borderRadius: 15,
    marginBottom: 5,
    backgroundColor: `${hexToRgbA('#1F293C', 0.6)}`,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: `${hexToRgbA('#1F293C', 1)}`
    }
  },
  assetIcon: {
    width: 21,
    height: 21,
    marginRight: 18
  },
  assetText: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: fontWeights.medium
  },
  assetTextAmount: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: fontWeights.medium
  },
  assetTextMuted: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: fontWeights.semiBold
  }
}));

export const WalletSelectDialog = ({
  open,
  handleClose
}: {
  open: boolean;
  handleClose: () => void;
}) => {
  const classes = useStyles();

  const {
    availableConnectTypes,
    availableInstallTypes,
    connect,
    install
  } = useWallet();

  type Button = { label: string; image: ReactNode; onClick: () => void };
  const buttons = ([] as Button[])
    .concat(
      availableInstallTypes.includes(ConnectType.CHROME_EXTENSION)
        ? {
            label: 'Terra Station Extension',
            image: <Terra {...size} />,
            onClick: () => install(ConnectType.CHROME_EXTENSION)
          }
        : []
    )
    .concat(
      availableConnectTypes.includes(ConnectType.WEBEXTENSION)
        ? {
            label: 'Terra Station Extension',
            image: <Terra {...size} />,
            onClick: () => connect(ConnectType.WEBEXTENSION)
          }
        : availableConnectTypes.includes(ConnectType.CHROME_EXTENSION)
        ? {
            label: 'Terra Station Extension',
            image: <Terra {...size} />,
            onClick: () => connect(ConnectType.CHROME_EXTENSION)
          }
        : []
    )
    .concat(
      availableConnectTypes.includes(ConnectType.WALLETCONNECT)
        ? {
            label: 'Terra Station Mobile',
            image: <img src={WalletConnect} {...size} alt="WalletConnect" />,
            onClick: () => connect(ConnectType.WALLETCONNECT)
          }
        : []
    );

  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose();
      }}>
      <Grid
        className={`${classes.assetSelectorContainer}`}
        container
        direction={'column'}>
        <Grid item xs={12}>
          <Typography className={classes.assetSelectorTitleText}>
            Connect To A Wallet
          </Typography>
        </Grid>
        {Object.entries(buttons).map(([key, { label, image, onClick }]) => (
          <Grid
            key={key}
            onClick={onClick}
            container
            item
            xs={12}
            alignItems={'center'}
            className={classes.assetSelectorRow}>
            <Grid item container xs={6}>
              <Typography className={classes.assetText}>{label}</Typography>
            </Grid>
            <Grid item container xs={6} justify={'flex-end'}>
              {image}
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Modal>
  );
};
