import { makeStyles } from '@material-ui/core/styles';
import { ASSET_PATH } from '../../store/constants';

export const iconImages = {
  mARKK: {
    default: `${ASSET_PATH}/icons/ARKK.png`
  },
  mAAPL: {
    default: `${ASSET_PATH}/icons/AAPL.png`
  },
  mABNB: {
    default: `${ASSET_PATH}/icons/ABNB.png`
  },
  mAMC: {
    default: `${ASSET_PATH}/icons/AMC.png`
  },
  ANC: {
    default: `${ASSET_PATH}/icons/ANC.gif`
  },
  mAMZN: {
    default: `${ASSET_PATH}/icons/AMZN.png`
  },
  mBABA: {
    default: `${ASSET_PATH}/icons/BABA.png`
  },
  mBTC: {
    default: `${ASSET_PATH}/icons/BTC.png`
  },
  mCOIN: {
    default: `${ASSET_PATH}/icons/COIN.png`
  },
  mETH: {
    default: `${ASSET_PATH}/icons/ETH.png`
  },
  mFB: {
    default: `${ASSET_PATH}/icons/FB.png`
  },
  mGLXY: {
    default: `${ASSET_PATH}/icons/GLXY.png`
  },
  mGME: {
    default: `${ASSET_PATH}/icons/GME.png`
  },
  mGOOGL: {
    default: `${ASSET_PATH}/icons/GOOGL.png`
  },
  mGS: {
    default: `${ASSET_PATH}/icons/GS.png`
  },
  mIAU: {
    default: `${ASSET_PATH}/icons/IAU.png`
  },
  MIR: {
    default: `${ASSET_PATH}/icons/MIR.png`
  },
  mMSFT: {
    default: `${ASSET_PATH}/icons/MSFT.png`
  },
  mNFLX: {
    default: `${ASSET_PATH}/icons/NFLX.png`
  },
  mQQQ: {
    default: `${ASSET_PATH}/icons/QQQ.png`
  },
  mSLV: {
    default: `${ASSET_PATH}/icons/SLV.png`
  },
  mSPY: {
    default: `${ASSET_PATH}/icons/SPY.png`
  },
  mTSLA: {
    default: `${ASSET_PATH}/icons/TSLA.png`
  },
  mTWTR: {
    default: `${ASSET_PATH}/icons/TWTR.png`
  },
  mUSO: {
    default: `${ASSET_PATH}/icons/USO.png`
  },
  UST: {
    default: `${ASSET_PATH}/icons/UST.png`
  },
  mVIXY: {
    default: `${ASSET_PATH}/icons/VIXY.png`
  },
  mDOT: {
    default: `${ASSET_PATH}/icons/DOT.png`
  },
  mAMD: {
    default: `${ASSET_PATH}/icons/AMD.png`
  },
  mSQ: {
    default: `${ASSET_PATH}/icons/SQ.png`
  },
  MINE: {
    default: `${ASSET_PATH}/icons/MINE.png`
  },
  STT: {
    default: `${ASSET_PATH}/icons/STT.png`
  },
  mHOOD: {
    default: `${ASSET_PATH}/icons/HOOD.png`
  },
  UNKNOWN: {
    default: `${ASSET_PATH}/icons/unknown.png`
  }
};

const useStyles = makeStyles((theme) => ({
  iconsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  iconWrapper: {
    width: 70,
    height: 70,
    filter: 'drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.35))'
  },
  bordered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    backgroundColor: '#172340'
  },
  iconWrapper1: {
    zIndex: 1
  },
  iconImage: {
    width: 63,
    height: 63,
    marginLeft: 1
  },
  borderedImage: {
    width: 43,
    height: 43
  },
  iconWrapper2: {
    zIndex: 2,
    position: 'absolute'
  },
  iconImage2: {
    width: 30,
    height: 30,
    position: 'absolute',
    bottom: -5,
    right: -5
  },
  scaleMini: {
    transform: 'scale(.5)',
    maxHeight: 40,
    transformOrigin: 'left center',
    marginRight: -36
  },
  scaleMiniSide: {
    transform: 'scale(.4)',
    maxHeight: 21,
    transformOrigin: 'left center',
    marginRight: -48
  },
  iconsWrapperSide: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  iconWrapperSide: {
    width: 50,
    height: 50
  },
  iconImageSide: {
    width: 50,
    height: 50
  },
  borderedImageSide: {
    width: 36,
    height: 36
  },
  iconWrapper2Side: {
    zIndex: 0,
    marginLeft: '-8px'
  },
  iconImage2Side: {
    width: 50,
    height: 50
  }
}));

export const VaultIcon = ({
  className,
  vaultKey = '',
  icon1Type = 'bordered',
  icon2Type = 'full',
  scale = 'full',
  variant = 'primary',
  ...rest
}) => {
  // TODO: Don't depend being able to know the base denom (UI should be
  // strategy-agnostic). Lets hardcode the icon in the StaticStrategyInfo
  let icon1 = vaultKey;
  let icon2 = 'UST';
  const classes = useStyles();
  return variant === 'side' ? (
    <div
      className={`${classes.iconsWrapperSide} ${className} ${
        scale === 'mini' && classes.scaleMiniSide
      }`}
      {...rest}>
      <div
        className={`${classes.iconWrapperSide}  ${classes.iconWrapper1} ${
          icon1Type === 'bordered' && classes.bordered
        }`}>
        <img
          alt={''}
          className={`${classes.iconImageSide} ${classes.iconImage1} ${
            icon1Type === 'bordered' && classes.borderedImageSide
          }`}
          src={(iconImages[icon1] || iconImages['UNKNOWN']).default}
        />
      </div>
      <div
        className={`${classes.iconsWrapperSide}  ${classes.iconWrapper2Side}`}>
        <img
          alt={''}
          className={`${classes.iconImageSide} ${classes.iconImage2Side} ${
            icon2Type === 'bordered' && classes.borderedImageSide
          }`}
          src={iconImages[icon2].default}
        />
      </div>
    </div>
  ) : (
    <div
      className={`${classes.iconsWrapper} ${className} ${
        scale === 'mini' && classes.scaleMini
      }`}
      {...rest}>
      <div
        className={`${classes.iconWrapper}  ${classes.iconWrapper1} ${
          icon1Type === 'bordered' && classes.bordered
        }`}>
        <img
          alt={''}
          className={`${classes.iconImage} ${classes.iconImage1} ${
            icon1Type === 'bordered' && classes.borderedImage
          }`}
          src={(iconImages[icon1] || iconImages['UNKNOWN']).default}
        />
      </div>
      <div className={`${classes.iconWrapper}  ${classes.iconWrapper2}`}>
        <img
          alt={''}
          className={`${classes.iconImage} ${classes.iconImage2} ${
            icon2Type === 'bordered' && classes.borderedImage
          }`}
          src={iconImages[icon2]?.default || iconImages.UNKNOWN.default}
        />
      </div>
    </div>
  );
};

export default VaultIcon;
