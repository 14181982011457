import { safeParseFloat, safeParseInt } from './math';

export const MICRO = 1000000;

/**
 * Parses a user input string into a micronumber.
 * @param {string} input - The input string to parse.
 */
export function parseInput(input: string) {
  return safeParseInt((safeParseFloat(input) * MICRO).toString());
}

/**
 * Formats a user input string into a number string with a fixed number of decimals.
 * @param {string} input - The input string to format.
 * @param {number} decimals - The number of decimals to use in the output string.
 */
export function formatInput(input: string, decimals: number = 6) {
  return safeParseFloat(input).toFixed(decimals);
}

/**
 * Converts a micronumber to a string with decimals
 * @param {number} x - The micronumber to convert.
 */
export function microNumberToInput(x: number) {
  return (x / MICRO).toString();
}

/**
 * Converts a user input string into a dollar formatted string ($X.yy)
 * @param {string} input - The input string to format.
 * @param {number} price - The price to use when converting to a dollar value.
 */
export function inputToDollarValue(input: string, price: number) {
  return microNumberToDollarValue(parseInput(input), price);
}

/**
 * Converts a micronumber into a dollar formatted string ($X.yy)
 * @param {number | string} x - The input micronumber to format.
 * @param {number} price - The price to use when converting to a dollar value.
 */
export function microNumberToDollarValue(
  x: number | string,
  price: number = 1
) {
  if (typeof x === 'string') x = safeParseInt(x);

  return ((x * price) / MICRO).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD'
  });
}

/**
 * Formats a micronumber as a string with decimals
 * @param {number | string} x - The input micronumber to format.
 * @param {number} decimals - The number of decimals to use in the output string.
 */
export function formatMicroNumber(x: number | string, decimals: number = 6) {
  if (typeof x === 'string') x = safeParseInt(x);
  return formatInput(microNumberToInput(x), decimals);
}

/**
 * Converts a user input dollar value into a string number of LP tokens with decimals
 * @param {string} input - The input string to format.
 * @param {number} price - The price to use when converting from a dollar value.
 */
export function dollarInputToLP(input: string, price: number) {
  return formatMicroNumber(parseInput(input) / price);
}
