export const transparentGold = 'rgba(254,210,0,0.9)';

export const apolloGold = 'rgba(254, 210, 0, 1)';

export const apolloGoldTransparent = 'rgba(254, 210, 0, 0.2)';

export const coral = 'rgba(253, 128, 109, 1)';

export const almostBlack = 'rgba(5,8,13,1)';

export const darkBlue = 'rgba(28,41,62,1)';

export const darkerBlue = 'rgba(25,32,45,1)';

export const lightBlue = 'rgba(97,169,237,1)';

export const transparentGrayBlue = 'rgba(31,41,60,0.35)';

export const indigo = 'rgba(41,143,196,1)';

export const transparentIndigo = 'rgba(34, 42, 57, 0.6)';

export const white = 'rgba(255,255,255,1)';

export const transparentWhite = 'rgba(255,255,255,0.8)';

export const veryTransparentWhite = 'rgba(255,255,255,0.2)';

export const gray = 'rgba(112,112,112,1)';

export const darkGray = 'rgba(36,42,56,1)';

export const almostWhite = 'rgba(255,255,255,0.6)';

export const popOverBlue = 'rgba(7,10,15,1)';

export const borderWhite = 'rgba(255,255,255,0.2)';
