import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { palette } from '../../../styles/themes/redesign';
import { ASSET_PATH } from '../../../store/constants';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: palette.darkBlueGrey,
    height: 40,
    padding: `0 1px 0 6px`,
    borderRadius: 15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  },
  walletInfo: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 15,
    border: `3px solid ${palette.darkBlueGrey}`,
    padding: '5px',
    background: palette.reallyDarkBlueGrey,
    '&:hover': {
      borderColor: palette.apolloGold
    }
  },
  ustTotal: {
    padding: `0 12px 0 6px`,
    fontWeight: 600
  },
  terraGlobe: {
    padding: `0 8px 0 20px`
  },
  walletID: {
    padding: `0 20px 0 0`,
    fontWeight: 600
  }
}));

const TerraExtensionConnect = ({ className, address, ustTotal, ...rest }) => {
  const classes = useStyles();
  const shortAddress = `${address.substr(0, 6)}...${address.substr(38, 6)}`;

  return (
    <div className={`${classes.container} ${className}`} {...rest}>
      <Typography className={classes.ustTotal}>{ustTotal}</Typography>
      <div className={classes.walletInfo}>
        <img
          className={classes.terraGlobe}
          src={`${ASSET_PATH}/img/terraGlobeIconColor.png`}
          alt={'terra globe'}
        />
        <Typography className={classes.walletID}>{shortAddress}</Typography>
      </div>
    </div>
  );
};

export default TerraExtensionConnect;
