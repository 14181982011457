//import MESSAGE from '../lang/MESSAGE.json';

import { useTranslation } from 'react-i18next';

const Reconnect = ({ name }: { name: string }) => {
  const { t } = useTranslation();
  return (
    <div className="empty">
      <p>{t('Network.NoContract')}</p>
      <p>Current network: {name}</p>
    </div>
  );
};

export default Reconnect;
