import { FactoryStrategyInfoResponse } from '../types/apollo-factory/get_strategies_response';
import {
  AccAddress,
  Coins,
  Int,
  MsgExecuteContract,
  Numeric,
  StdFee
} from '@terra-money/terra.js';
import networks, {
  isSupportedNetwork,
  SupportedNetwork
} from '../store/networks';
import { ConfigResponseFor_Empty as StrategyConfigResponse } from '../types/apollo-protocol/config_response_for__empty';
import { FactoryUserInfoResponse } from '../types/apollo-factory/get_user_strategies_response';
import { useRecoilValue } from 'recoil';
import { networkNameState } from '../data/network';
import { addressState } from '../data/wallet';
import { TxResult, useWallet } from '@terra-money/wallet-provider';
import useFee from './useFee';
import useTax from './useTax';
import { plus, sum } from '../libs/math';
import { HandleMsg } from '../types/apollo-factory/handle_msg';

export type Strategies = {
  config: StrategyConfigResponse;
  info: FactoryStrategyInfoResponse;
  userInfo: FactoryUserInfoResponse;
}[];

export type ApolloFactoryState = {
  depositToStrategy: (
    strategyId: number,
    token: AccAddress,
    amount: Numeric.Input
  ) => Promise<TxResult>;
  withdrawFromStrategy: (
    strategyId: number,
    amount: Numeric.Input
  ) => Promise<TxResult>;
  zapIntoStrategy: (strategyId: number, amount: string) => Promise<TxResult>;
  zapOutOfStrategy: (strategyId: number, amount: string) => Promise<TxResult>;
};

export const useApolloFactory: () => ApolloFactoryState = () => {
  let networkName = useRecoilValue(networkNameState);
  if (!networkName || !isSupportedNetwork(networkName)) networkName = 'mainnet';

  const { post } = useWallet();

  const { calcTax } = useTax();

  const fee = useFee();

  const factoryAddress =
    networks[networkName as SupportedNetwork].contracts.factory;
  const userWalletAddr = useRecoilValue(addressState);

  function depositToStrategy(
    strategyId: number,
    tokenAddress: AccAddress,
    amount: Numeric.Input
  ): Promise<TxResult> {
    if (!factoryAddress)
      return Promise.reject(`Contract address not set for apollo factory`);
    const executeMsg = sendToken(
      userWalletAddr,
      factoryAddress,
      strategyId,
      tokenAddress,
      amount.toString()
    );

    return post({
      msgs: [executeMsg],
      fee: new StdFee(fee.gas, { uusd: fee.amount })
    });
  }

  function withdrawFromStrategy(
    strategyId: number,
    amount: Numeric.Input
  ): Promise<TxResult> {
    if (!factoryAddress)
      return Promise.reject(`Contract address not set for apollo factory`);

    const executeMsg = createExecuteMsg({
      withdraw_from_strategy: {
        strategy_id: strategyId,
        amount: amount.toString()
      }
    });

    return post({
      msgs: [executeMsg],
      fee: new StdFee(fee.gas, { uusd: fee.amount })
    });
  }

  function zapIntoStrategy(
    strategyId: number,
    amount: string
  ): Promise<TxResult> {
    if (!factoryAddress)
      return Promise.reject(`Contract address not set for apollo factory`);

    const executeMsg = createExecuteMsg(
      {
        zap_into_strategy: { strategy_id: strategyId }
      },
      { uusd: amount }
    );

    const tax = calcTax(amount);

    const tx = {
      msgs: [executeMsg],
      fee: new StdFee(fee.gas, { uusd: plus(fee.amount, tax) })
    };

    return post(tx);
  }

  function zapOutOfStrategy(
    strategyId: number,
    amount: string
  ): Promise<TxResult> {
    if (!factoryAddress)
      return Promise.reject(`Contract address not set for apollo factory`);

    const executeMsg = createExecuteMsg({
      zap_out_of_strategy: { strategy_id: strategyId, amount }
    });

    return post({
      msgs: [executeMsg],
      fee: new StdFee(fee.gas, { uusd: fee.amount })
    });
  }

  function createExecuteMsg(
    executeMsg: HandleMsg,
    coins: Coins.Input = {}
  ): MsgExecuteContract {
    return new MsgExecuteContract(
      userWalletAddr,
      factoryAddress,
      executeMsg,
      coins
    );
  }

  return {
    depositToStrategy,
    withdrawFromStrategy,
    zapIntoStrategy,
    zapOutOfStrategy
  };
};

function createHookMsg(msg: { deposit: { strategy_id: number } }): string {
  return Buffer.from(JSON.stringify(msg)).toString('base64');
}

export function useGasAndTax(
  pretax: string,
  includeTax: boolean,
  length = 1,
  gasAdjust = 1
) {
  const fee = useFee(length, gasAdjust);
  const { calcTax } = useTax();
  const tax = pretax ? calcTax(pretax) : '0';
  const uusdAmount = includeTax
    ? sum([pretax ?? '0', tax, fee.amount])
    : fee.amount;
  return uusdAmount;
}

function sendToken(
  sender: AccAddress,
  factoryAddress: AccAddress,
  strategyId: number,
  lpTokenAddress: AccAddress,
  amount: Numeric.Input
): MsgExecuteContract {
  const executeMsg = {
    send: {
      contract: factoryAddress,
      amount: new Int(amount).toString(),
      msg: createHookMsg({
        deposit: {
          strategy_id: strategyId
        }
      })
    }
  };

  return new MsgExecuteContract(sender, lpTokenAddress, executeMsg);
}
