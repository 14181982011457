/* eslint-disable import/no-anonymous-default-export */
import BigNumber from 'bignumber.js';
import { gt } from './math';

export default {
  /**
   * to LP
   * @param deposits[].amount - Amount to provide
   * @param deposits[].pair - pair, {pool:{}}
   * @param totalShare - pair, {pool:{}}
   */
  toLP: (deposits: { amount: string; pair: string }[], totalShare: string) =>
    gt(totalShare, 0)
      ? BigNumber.minimum(
          ...deposits.map(({ amount, pair }) =>
            new BigNumber(amount).times(totalShare).div(pair)
          )
        ).toString()
      : new BigNumber(deposits[0].amount)
          .times(deposits[1].amount)
          .sqrt()
          .toString(),

  /**
   * from LP
   * @param lp - Amount to withdraw
   * @param shares - pair, {pool:{}}
   * @param totalShare - pair, {pool:{}}
   */
  fromLP: (
    lp: string,
    shares: { asset: Asset; uusd: Asset },
    totalShare: string
  ): { asset: Asset; uusd: Asset } =>
    Object.entries(shares).reduce(
      (acc, [key, { amount, token }]) => ({
        ...acc,
        [key]: {
          amount: new BigNumber(lp).times(amount).div(totalShare).toString(),
          token
        }
      }),
      {} as { asset: Asset; uusd: Asset }
    ),
  aprToApy: (apr: number) => (apr <= 0 ? 0 : Math.pow(1 + apr / 365, 365) - 1)
};
