import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Fab, Grid, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';

import { palette } from '../../styles/themes/redesign';
import { Mobile, Desktop } from './header';
import Footer from './Footer';
import { hexToRgbA } from '../../libs/util';
import { NewModalProvider } from '../../providers/NewModalProvider';
import { ASSET_PATH } from '../../store/constants';

const useStyles = makeStyles((theme) => ({
  '@global': {
    html: {
      [theme.breakpoints.down('md')]: {
        fontSize: 14
      }
    },
    a: {
      textDecoration: 'none'
    }
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    position: 'relative',
    background: `radial-gradient(800px, ${hexToRgbA(
      palette.darkBlueGrey,
      0.6
    )}, ${palette.nearBlack})`
  },
  milkyWay: {
    background: `url(${ASSET_PATH}/img/milkyway.png)`,
    backgroundSize: 'cover'
  },
  container: {
    maxWidth: 1010,
    minWidth: 370,
    padding: `0 16px`,
    height: 'calc(100% - 60px)',
    marginTop: 27
  },
  fab: {
    position: 'fixed',
    bottom: 32,
    right: 32
  },
  extendedIcon: {
    marginRight: 8
  },
  contentWrapper: {
    [theme.breakpoints.down('sm')]: {
      minWidth: 350
    }
  }
}));

export const FAB = () => {
  const classes = useStyles();
  return (
    <Fab
      href={
        'https://discord.com/channels/830099985969971211/880412251973115924'
      }
      target={'_blank'}
      variant="extended"
      color={'primary'}
      className={classes.fab}>
      <LiveHelpIcon className={classes.extendedIcon} />
      Submit a Bug
    </Fab>
  );
};

export const Header = () => {
  return (
    <>
      <Hidden smDown>
        <Desktop />
      </Hidden>
      <Hidden mdUp>
        <Mobile />
      </Hidden>
    </>
  );
};

export const Layout: FC = ({ children }) => {
  const classes = useStyles();
  const { pathname: location } = useLocation();

  return (
    <div
      className={`${classes.wrapper} ${
        location.indexOf('community-farming') === 1 && classes.milkyWay
      } `}>
      <Header />
      <NewModalProvider>
        <Container component="main" className={classes.container}>
          <Grid
            className={classes.contentWrapper}
            container
            justify={'center'}
            spacing={2}>
            {children}
          </Grid>
        </Container>
        <FAB />
      </NewModalProvider>
      <Footer />
    </div>
  );
};
