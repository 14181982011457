import { StaticStrategyInfo } from './strategies';

export const mainnetStrategies: Record<string, StaticStrategyInfo> = {
  '1': {
    id: 1,
    key: 'MIR',
    name: 'MIR-UST HALO Farm',
    description:
      'During the Community Farming Event, all rewards will be used to purchase Apollo tokens at a fixed price of $0.25, instead of being compounded.',
    baseToken: 'terra17gjf2zehfvnyjtdgua9p9ygquk6gukxe7ucgwh',
    baseTokenName: 'MIR-UST LP',
    icon: 'https://d14knz87alb4l4.cloudfront.net/icons/MIR.png',
    protocolNameFull: 'Mirror Protocol',
    protocolNameShort: 'Mirror',
    protocolKey: 'mir',
    manageAssetLink: 'https://app.terraswap.io/#Withdraw'
  },
  '2': {
    id: 2,
    key: 'mAAPL',
    name: 'mAAPL-UST HALO Farm',
    description:
      'During the Community Farming Event, all rewards will be used to purchase Apollo tokens at a fixed price of $0.25, instead of being compounded.',
    baseToken: 'terra122asauhmv083p02rhgyp7jn7kmjjm4ksexjnks',
    baseTokenName: 'mAAPL-UST LP',
    icon: 'https://d14knz87alb4l4.cloudfront.net/icons/AAPL.png',
    protocolNameFull: 'Mirror Protocol',
    protocolNameShort: 'Mirror',
    protocolKey: 'mir',
    manageAssetLink: 'https://app.terraswap.io/#Withdraw'
  },
  '3': {
    id: 3,
    key: 'mABNB',
    name: 'mABNB-UST HALO Farm',
    description:
      'During the Community Farming Event, all rewards will be used to purchase Apollo tokens at a fixed price of $0.25, instead of being compounded.',
    baseToken: 'terra1jmauv302lfvpdfau5nhzy06q0j2f9te4hy2d07',
    baseTokenName: 'mABNB-UST LP',
    icon: 'https://d14knz87alb4l4.cloudfront.net/icons/ABNB.png',
    protocolNameFull: 'Mirror Protocol',
    protocolNameShort: 'Mirror',
    protocolKey: 'mir',
    manageAssetLink: 'https://app.terraswap.io/#Withdraw'
  },
  '4': {
    id: 4,
    key: 'mAMD',
    name: 'mAMD-UST HALO Farm',
    description:
      'During the Community Farming Event, all rewards will be used to purchase Apollo tokens at a fixed price of $0.25, instead of being compounded.',
    baseToken: 'terra1m8mr9u3su46ezxwf7z7xnvm0jsapl2jd8vgefh',
    baseTokenName: 'mAMD-UST LP',
    icon: 'https://d14knz87alb4l4.cloudfront.net/icons/AMD.png',
    protocolNameFull: 'Mirror Protocol',
    protocolNameShort: 'Mirror',
    protocolKey: 'mir',
    manageAssetLink: 'https://app.terraswap.io/#Withdraw'
  },
  '5': {
    id: 5,
    key: 'mAMZN',
    name: 'mAMZN-UST HALO Farm',
    description:
      'During the Community Farming Event, all rewards will be used to purchase Apollo tokens at a fixed price of $0.25, instead of being compounded.',
    baseToken: 'terra1q7m2qsj3nzlz5ng25z5q5w5qcqldclfe3ljup9',
    baseTokenName: 'mAMZN-UST LP',
    icon: 'https://d14knz87alb4l4.cloudfront.net/icons/AMZN.png',
    protocolNameFull: 'Mirror Protocol',
    protocolNameShort: 'Mirror',
    protocolKey: 'mir',
    manageAssetLink: 'https://app.terraswap.io/#Withdraw'
  },
  '6': {
    id: 6,
    key: 'mARKK',
    name: 'mARKK-UST HALO Farm',
    description:
      'During the Community Farming Event, all rewards will be used to purchase Apollo tokens at a fixed price of $0.25, instead of being compounded.',
    baseToken: 'terra1veqh8yc55mhw0ttjr5h6g9a6r9nylmrc0nzhr7',
    baseTokenName: 'mARKK-UST LP',
    icon: 'https://d14knz87alb4l4.cloudfront.net/icons/ARKK.png',
    protocolNameFull: 'Mirror Protocol',
    protocolNameShort: 'Mirror',
    protocolKey: 'mir',
    manageAssetLink: 'https://app.terraswap.io/#Withdraw'
  },
  '7': {
    id: 7,
    key: 'mBABA',
    name: 'mBABA-UST HALO Farm',
    description:
      'During the Community Farming Event, all rewards will be used to purchase Apollo tokens at a fixed price of $0.25, instead of being compounded.',
    baseToken: 'terra1stfeev27wdf7er2uja34gsmrv58yv397dlxmyn',
    baseTokenName: 'mBABA-UST LP',
    icon: 'https://d14knz87alb4l4.cloudfront.net/icons/BABA.png',
    protocolNameFull: 'Mirror Protocol',
    protocolNameShort: 'Mirror',
    protocolKey: 'mir',
    manageAssetLink: 'https://app.terraswap.io/#Withdraw'
  },
  '8': {
    id: 8,
    key: 'mBTC',
    name: 'mBTC-UST HALO Farm',
    description:
      'During the Community Farming Event, all rewards will be used to purchase Apollo tokens at a fixed price of $0.25, instead of being compounded.',
    baseToken: 'terra1d34edutzwcz6jgecgk26mpyynqh74j3emdsnq5',
    baseTokenName: 'mBTC-UST LP',
    icon: 'https://d14knz87alb4l4.cloudfront.net/icons/BTC.png',
    protocolNameFull: 'Mirror Protocol',
    protocolNameShort: 'Mirror',
    protocolKey: 'mir',
    manageAssetLink: 'https://app.terraswap.io/#Withdraw'
  },
  '9': {
    id: 9,
    key: 'mCOIN',
    name: 'mCOIN-UST HALO Farm',
    description:
      'During the Community Farming Event, all rewards will be used to purchase Apollo tokens at a fixed price of $0.25, instead of being compounded.',
    baseToken: 'terra1ktckr8v7judrr6wkwv476pwsv8mht0zqzw2t0h',
    baseTokenName: 'mCOIN-UST LP',
    icon: 'https://d14knz87alb4l4.cloudfront.net/icons/COIN.png',
    protocolNameFull: 'Mirror Protocol',
    protocolNameShort: 'Mirror',
    protocolKey: 'mir',
    manageAssetLink: 'https://app.terraswap.io/#Withdraw'
  },
  '10': {
    id: 10,
    key: 'mDOT',
    name: 'mDOT-UST HALO Farm',
    description:
      'During the Community Farming Event, all rewards will be used to purchase Apollo tokens at a fixed price of $0.25, instead of being compounded.',
    baseToken: 'terra1p60datmmf25wgssguv65ltds3z6ea3me74nm2e',
    baseTokenName: 'mDOT-UST LP',
    icon: 'https://d14knz87alb4l4.cloudfront.net/icons/DOT.png',
    protocolNameFull: 'Mirror Protocol',
    protocolNameShort: 'Mirror',
    protocolKey: 'mir',
    manageAssetLink: 'https://app.terraswap.io/#Withdraw'
  },
  '11': {
    id: 11,
    key: 'mETH',
    name: 'mETH-UST HALO Farm',
    description:
      'During the Community Farming Event, all rewards will be used to purchase Apollo tokens at a fixed price of $0.25, instead of being compounded.',
    baseToken: 'terra16auz7uhnuxrj2dzrynz2elthx5zpps5gs6tyln',
    baseTokenName: 'mETH-UST LP',
    icon: 'https://d14knz87alb4l4.cloudfront.net/icons/ETH.png',
    protocolNameFull: 'Mirror Protocol',
    protocolNameShort: 'Mirror',
    protocolKey: 'mir',
    manageAssetLink: 'https://app.terraswap.io/#Withdraw'
  },
  '12': {
    id: 12,
    key: 'mFB',
    name: 'mFB-UST HALO Farm',
    description:
      'During the Community Farming Event, all rewards will be used to purchase Apollo tokens at a fixed price of $0.25, instead of being compounded.',
    baseToken: 'terra1jh2dh4g65hptsrwjv53nhsnkwlw8jdrxaxrca0',
    baseTokenName: 'mFB-UST LP',
    icon: 'https://d14knz87alb4l4.cloudfront.net/icons/FB.png',
    protocolNameFull: 'Mirror Protocol',
    protocolNameShort: 'Mirror',
    protocolKey: 'mir',
    manageAssetLink: 'https://app.terraswap.io/#Withdraw'
  },
  '13': {
    id: 13,
    key: 'mGLXY',
    name: 'mGLXY-UST HALO Farm',
    description:
      'During the Community Farming Event, all rewards will be used to purchase Apollo tokens at a fixed price of $0.25, instead of being compounded.',
    baseToken: 'terra1pjgzke6h5v4nz978z3a92gqajwhn8yyh5kv4zv',
    baseTokenName: 'mGLXY-UST LP',
    icon: 'https://d14knz87alb4l4.cloudfront.net/icons/GLXY.png',
    protocolNameFull: 'Mirror Protocol',
    protocolNameShort: 'Mirror',
    protocolKey: 'mir',
    manageAssetLink: 'https://app.terraswap.io/#Withdraw'
  },
  '14': {
    id: 14,
    key: 'mGME',
    name: 'mGME-UST HALO Farm',
    description:
      'During the Community Farming Event, all rewards will be used to purchase Apollo tokens at a fixed price of $0.25, instead of being compounded.',
    baseToken: 'terra1azk43zydh3sdxelg3h4csv4a4uef7fmjy0hu20',
    baseTokenName: 'mGME-UST LP',
    icon: 'https://d14knz87alb4l4.cloudfront.net/icons/GME.png',
    protocolNameFull: 'Mirror Protocol',
    protocolNameShort: 'Mirror',
    protocolKey: 'mir',
    manageAssetLink: 'https://app.terraswap.io/#Withdraw'
  },
  '15': {
    id: 15,
    key: 'mGOOGL',
    name: 'mGOOGL-UST HALO Farm',
    description:
      'During the Community Farming Event, all rewards will be used to purchase Apollo tokens at a fixed price of $0.25, instead of being compounded.',
    baseToken: 'terra1falkl6jy4087h4z567y2l59defm9acmwcs70ts',
    baseTokenName: 'mGOOGL-UST LP',
    icon: 'https://d14knz87alb4l4.cloudfront.net/icons/GOOGL.png',
    protocolNameFull: 'Mirror Protocol',
    protocolNameShort: 'Mirror',
    protocolKey: 'mir',
    manageAssetLink: 'https://app.terraswap.io/#Withdraw'
  },
  '16': {
    id: 16,
    key: 'mGS',
    name: 'mGS-UST HALO Farm',
    description:
      'During the Community Farming Event, all rewards will be used to purchase Apollo tokens at a fixed price of $0.25, instead of being compounded.',
    baseToken: 'terra17smg3rl9vdpawwpe7ex4ea4xm6q038gp2chge5',
    baseTokenName: 'mGS-UST LP',
    icon: 'https://d14knz87alb4l4.cloudfront.net/icons/GS.png',
    protocolNameFull: 'Mirror Protocol',
    protocolNameShort: 'Mirror',
    protocolKey: 'mir',
    manageAssetLink: 'https://app.terraswap.io/#Withdraw'
  },
  '17': {
    id: 17,
    key: 'mHOOD',
    name: 'mHOOD-UST HALO Farm',
    description:
      'During the Community Farming Event, all rewards will be used to purchase Apollo tokens at a fixed price of $0.25, instead of being compounded.',
    baseToken: 'terra1s0dgcsdy9kgunnf3gnwl40uwy9rxtmc39mhy2m',
    baseTokenName: 'mHOOD-UST LP',
    icon: 'https://d14knz87alb4l4.cloudfront.net/icons/HOOD.png',
    protocolNameFull: 'Mirror Protocol',
    protocolNameShort: 'Mirror',
    protocolKey: 'mir',
    manageAssetLink: 'https://app.terraswap.io/#Withdraw'
  },
  '18': {
    id: 18,
    key: 'mIAU',
    name: 'mIAU-UST HALO Farm',
    description:
      'During the Community Farming Event, all rewards will be used to purchase Apollo tokens at a fixed price of $0.25, instead of being compounded.',
    baseToken: 'terra1ndlx5ndkknvmgj6s5ggmdlhjjsz0w6wrnwn5cf',
    baseTokenName: 'mIAU-UST LP',
    icon: 'https://d14knz87alb4l4.cloudfront.net/icons/IAU.png',
    protocolNameFull: 'Mirror Protocol',
    protocolNameShort: 'Mirror',
    protocolKey: 'mir',
    manageAssetLink: 'https://app.terraswap.io/#Withdraw'
  },
  '19': {
    id: 19,
    key: 'mMSFT',
    name: 'mMSFT-UST HALO Farm',
    description:
      'During the Community Farming Event, all rewards will be used to purchase Apollo tokens at a fixed price of $0.25, instead of being compounded.',
    baseToken: 'terra14uaqudeylx6tegamqmygh85lfq8qg2jmg7uucc',
    baseTokenName: 'mMSFT-UST LP',
    icon: 'https://d14knz87alb4l4.cloudfront.net/icons/MSFT.png',
    protocolNameFull: 'Mirror Protocol',
    protocolNameShort: 'Mirror',
    protocolKey: 'mir',
    manageAssetLink: 'https://app.terraswap.io/#Withdraw'
  },
  '20': {
    id: 20,
    key: 'mNFLX',
    name: 'mNFLX-UST HALO Farm',
    description:
      'During the Community Farming Event, all rewards will be used to purchase Apollo tokens at a fixed price of $0.25, instead of being compounded.',
    baseToken: 'terra1mwu3cqzvhygqg7vrsa6kfstgg9d6yzkgs6yy3t',
    baseTokenName: 'mNFLX-UST LP',
    icon: 'https://d14knz87alb4l4.cloudfront.net/icons/NFLX.png',
    protocolNameFull: 'Mirror Protocol',
    protocolNameShort: 'Mirror',
    protocolKey: 'mir',
    manageAssetLink: 'https://app.terraswap.io/#Withdraw'
  },
  '21': {
    id: 21,
    key: 'mQQQ',
    name: 'mQQQ-UST HALO Farm',
    description:
      'During the Community Farming Event, all rewards will be used to purchase Apollo tokens at a fixed price of $0.25, instead of being compounded.',
    baseToken: 'terra16j09nh806vaql0wujw8ktmvdj7ph8h09ltjs2r',
    baseTokenName: 'mQQQ-UST LP',
    icon: 'https://d14knz87alb4l4.cloudfront.net/icons/QQQ.png',
    protocolNameFull: 'Mirror Protocol',
    protocolNameShort: 'Mirror',
    protocolKey: 'mir',
    manageAssetLink: 'https://app.terraswap.io/#Withdraw'
  },
  '22': {
    id: 22,
    key: 'mSLV',
    name: 'mSLV-UST HALO Farm',
    description:
      'During the Community Farming Event, all rewards will be used to purchase Apollo tokens at a fixed price of $0.25, instead of being compounded.',
    baseToken: 'terra178cf7xf4r9d3z03tj3pftewmhx0x2p77s0k6yh',
    baseTokenName: 'mSLV-UST LP',
    icon: 'https://d14knz87alb4l4.cloudfront.net/icons/SLV.png',
    protocolNameFull: 'Mirror Protocol',
    protocolNameShort: 'Mirror',
    protocolKey: 'mir',
    manageAssetLink: 'https://app.terraswap.io/#Withdraw'
  },
  '23': {
    id: 23,
    key: 'mSPY',
    name: 'mSPY-UST HALO Farm',
    description:
      'During the Community Farming Event, all rewards will be used to purchase Apollo tokens at a fixed price of $0.25, instead of being compounded.',
    baseToken: 'terra1jqqegd35rg2gjde54adpj3t6ecu0khfeaarzy9',
    baseTokenName: 'mSPY-UST LP',
    icon: 'https://d14knz87alb4l4.cloudfront.net/icons/SPY.png',
    protocolNameFull: 'Mirror Protocol',
    protocolNameShort: 'Mirror',
    protocolKey: 'mir',
    manageAssetLink: 'https://app.terraswap.io/#Withdraw'
  },
  '24': {
    id: 24,
    key: 'mSQ',
    name: 'mSQ-UST HALO Farm',
    description:
      'During the Community Farming Event, all rewards will be used to purchase Apollo tokens at a fixed price of $0.25, instead of being compounded.',
    baseToken: 'terra1mv3pgkzs4krcennqj442jscg6jv84cejrs50n2',
    baseTokenName: 'mSQ-UST LP',
    icon: 'https://d14knz87alb4l4.cloudfront.net/icons/SQ.png',
    protocolNameFull: 'Mirror Protocol',
    protocolNameShort: 'Mirror',
    protocolKey: 'mir',
    manageAssetLink: 'https://app.terraswap.io/#Withdraw'
  },
  '25': {
    id: 25,
    key: 'mTSLA',
    name: 'mTSLA-UST HALO Farm',
    description:
      'During the Community Farming Event, all rewards will be used to purchase Apollo tokens at a fixed price of $0.25, instead of being compounded.',
    baseToken: 'terra1ygazp9w7tx64rkx5wmevszu38y5cpg6h3fk86e',
    baseTokenName: 'mTSLA-UST LP',
    icon: 'https://d14knz87alb4l4.cloudfront.net/icons/TSLA.png',
    protocolNameFull: 'Mirror Protocol',
    protocolNameShort: 'Mirror',
    protocolKey: 'mir',
    manageAssetLink: 'https://app.terraswap.io/#Withdraw'
  },
  '26': {
    id: 26,
    key: 'mTWTR',
    name: 'mTWTR-UST HALO Farm',
    description:
      'During the Community Farming Event, all rewards will be used to purchase Apollo tokens at a fixed price of $0.25, instead of being compounded.',
    baseToken: 'terra1fc5a5gsxatjey9syq93c2n3xq90n06t60nkj6l',
    baseTokenName: 'mTWTR-UST LP',
    icon: 'https://d14knz87alb4l4.cloudfront.net/icons/TWTR.png',
    protocolNameFull: 'Mirror Protocol',
    protocolNameShort: 'Mirror',
    protocolKey: 'mir',
    manageAssetLink: 'https://app.terraswap.io/#Withdraw'
  },
  '27': {
    id: 27,
    key: 'mUSO',
    name: 'mUSO-UST HALO Farm',
    description:
      'During the Community Farming Event, all rewards will be used to purchase Apollo tokens at a fixed price of $0.25, instead of being compounded.',
    baseToken: 'terra1utf3tm35qk6fkft7ltcnscwml737vfz7xghwn5',
    baseTokenName: 'mUSO-UST LP',
    icon: 'https://d14knz87alb4l4.cloudfront.net/icons/USO.png',
    protocolNameFull: 'Mirror Protocol',
    protocolNameShort: 'Mirror',
    protocolKey: 'mir',
    manageAssetLink: 'https://app.terraswap.io/#Withdraw'
  },
  '28': {
    id: 28,
    key: 'mVIXY',
    name: 'mVIXY-UST HALO Farm',
    description:
      'During the Community Farming Event, all rewards will be used to purchase Apollo tokens at a fixed price of $0.25, instead of being compounded.',
    baseToken: 'terra1ekd58y58vq4gmxlzpc27dwuhw7wmms928ftuep',
    baseTokenName: 'mVIXY-UST LP',
    icon: 'https://d14knz87alb4l4.cloudfront.net/icons/VIXY.png',
    protocolNameFull: 'Mirror Protocol',
    protocolNameShort: 'Mirror',
    protocolKey: 'mir',
    manageAssetLink: 'https://app.terraswap.io/#Withdraw'
  },
  '29': {
    id: 29,
    key: 'MINE',
    name: 'MINE-UST HALO Farm',
    description:
      'During the Community Farming Event, all rewards will be used to purchase Apollo tokens at a fixed price of $0.25, instead of being compounded.',
    baseToken: 'terra1rqkyau9hanxtn63mjrdfhpnkpddztv3qav0tq2',
    baseTokenName: 'MINE-UST LP',
    icon: 'https://d14knz87alb4l4.cloudfront.net/icons/MINE.png',
    protocolNameFull: 'Pylon Protocol',
    protocolNameShort: 'Pylon',
    protocolKey: 'pylon',
    manageAssetLink: 'https://app.pylon.money/liquidity/pool/withdraw'
  },
  '30': {
    id: 30,
    key: 'ANC',
    name: 'ANC-UST HALO Farm',
    description:
      'During the Community Farming Event, all rewards will be used to purchase Apollo tokens at a fixed price of $0.25, instead of being compounded.',
    baseToken: 'terra1gecs98vcuktyfkrve9czrpgtg0m3aq586x6gzm',
    baseTokenName: 'ANC-UST LP',
    icon: 'https://d14knz87alb4l4.cloudfront.net/icons/ANC.png',
    protocolNameFull: 'Anchor Protocol',
    protocolNameShort: 'Anchor',
    protocolKey: 'anc',
    manageAssetLink: 'https://app.anchorprotocol.com/anc-ust-lp/withdraw'
  }
};

export const testnetStrategies: Record<string, StaticStrategyInfo> = {
  1: {
    key: 'MIR',
    name: 'MIR-UST HALO Farm',
    description:
      'Compounds the underlying farm at the optimal rate to accumulate more MIR-UST LP tokens.',
    baseToken: 'terra1zrryfhlrpg49quz37u90ck6f396l4xdjs5s08j',
    baseTokenName: 'MIR-UST LP',
    icon: 'https://d14knz87alb4l4.cloudfront.net/icons/MIR.png',
    protocolNameFull: 'Mirror Protocol',
    protocolNameShort: 'Mirror',
    protocolKey: 'mir',
    manageAssetLink: 'https://app.terraswap.io/#Withdraw',
    id: 1
  },
  2: {
    key: 'mAAPL',
    name: 'mAAPL-UST HALO Farm',
    description:
      'Compounds the underlying farm at the optimal rate to accumulate more mAAPL-UST LP tokens.',
    baseToken: 'terra1vth958fsn8zawllaqcdzswksjkv3dz2sqqmcu4',
    baseTokenName: 'mAAPL-UST LP',
    icon: 'https://d14knz87alb4l4.cloudfront.net/icons/AAPL.png',
    protocolNameFull: 'Mirror Protocol',
    protocolNameShort: 'Mirror',
    protocolKey: 'mir',
    manageAssetLink: 'https://app.terraswap.io/#Withdraw',
    id: 2
  },
  3: {
    key: 'mABNB',
    name: 'mABNB-UST HALO Farm',
    description:
      'Compounds the underlying farm at the optimal rate to accumulate more mABNB-UST LP tokens.',
    baseToken: 'terra1d7xlu53sx9anwdqvxeya0tmhr7xzepd9agyvft',
    baseTokenName: 'mABNB-UST LP',
    icon: 'https://d14knz87alb4l4.cloudfront.net/icons/ABNB.png',
    protocolNameFull: 'Mirror Protocol',
    protocolNameShort: 'Mirror',
    protocolKey: 'mir',
    manageAssetLink: 'https://app.terraswap.io/#Withdraw',
    id: 3
  },
  4: {
    key: 'mAMD',
    name: 'mAMD-UST HALO Farm',
    description:
      'Compounds the underlying farm at the optimal rate to accumulate more mAMD-UST LP tokens.',
    baseToken: 'terra1lu2eg3hghmkd0s5jdsnr24mg5cremf0e8dpz4l',
    baseTokenName: 'mAMD-UST LP',
    icon: 'https://d14knz87alb4l4.cloudfront.net/icons/AMD.png',
    protocolNameFull: 'Mirror Protocol',
    protocolNameShort: 'Mirror',
    protocolKey: 'mir',
    manageAssetLink: 'https://app.terraswap.io/#Withdraw',
    id: 4
  },
  5: {
    key: 'mAMZN',
    name: 'mAMZN-UST HALO Farm',
    description:
      'Compounds the underlying farm at the optimal rate to accumulate more mAMZN-UST LP tokens.',
    baseToken: 'terra1kgvcrtupc8y4dgc9n08ud99ckdxp08j59zgccf',
    baseTokenName: 'mAMZN-UST LP',
    icon: 'https://d14knz87alb4l4.cloudfront.net/icons/AMZN.png',
    protocolNameFull: 'Mirror Protocol',
    protocolNameShort: 'Mirror',
    protocolKey: 'mir',
    manageAssetLink: 'https://app.terraswap.io/#Withdraw',
    id: 5
  }
};
