import { CSSProperties, useContext } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { AnimateNumber, formatRate } from '@anchor-protocol/notation';
import { NewModalContext } from '../../providers/NewModalProvider';
import { AngelAvatarSvg } from '../../content/images';
import {
  fontWeights,
  palette,
  textPalette
} from '../../styles/themes/redesign';
import VaultIcon from '../core/VaultIcon';
import ProtocolChip from './ProtocolChip';
import { hexToRgbA } from '../../libs/util';
import { StrategyInfo } from '../../store/strategies';
import {
  microNumberToDollarValue,
  formatMicroNumber
} from '../../libs/formatting';

const useStyles = makeStyles((theme) => ({
  vaultPanelContainer: {
    flexShrink: 0,
    width: 145,
    height: 220,
    marginRight: 20,
    background: palette.Mirror,
    borderRadius: 20,
    position: 'relative',
    top: 0,
    transition: 'transform ease 0.25s',
    '&:hover': {
      [theme.breakpoints.up('md')]: {
        transform: 'scale(1.08)',
        cursor: 'pointer'
      }
    }
  },
  vaultNameText: {
    fontWeight: fontWeights.semiBold,
    fontSize: theme.typography.pxToRem(15)
  },
  vaultPriceText: {
    fontWeight: fontWeights.semiBold,
    fontSize: theme.typography.pxToRem(10)
  },
  vaultNameRow: {
    paddingTop: 12
  },
  tokenValueRow: {
    marginTop: 10
  },
  apyRow: {
    marginTop: 2
  },
  img: {
    paddingTop: 20
  },
  protocolChipGrid: {
    marginTop: 5
  },
  stakedTokenValueText: {
    fontWeight: fontWeights.semiBold,
    fontSize: theme.typography.pxToRem(17)
  },
  apyText: {
    height: 22,
    width: 62,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: fontWeights.semiBold,
    backgroundColor: hexToRgbA('#FFF', 0.9),
    borderRadius: 10,
    maxWidth: 58,
    margin: 'auto',
    fontSize: theme.typography.pxToRem(13)
  }
}));

const VaultPanel = ({
  vault,
  className,
  style,
  balanceDisplay = 'uusd',
  useClick = true
}: {
  vault: StrategyInfo;
  className?: string;
  style?: CSSProperties;
  balanceDisplay?: 'uusd' | 'lp';
  useClick?: boolean;
}) => {
  const {
    key: vaultKey,
    protocolKey,
    stakedTokenValue,
    protocolNameShort,
    stakedTokenBalance
  } = vault;
  const classes = useStyles();
  const { handleVaultModal } = useContext(NewModalContext);

  const handlePanelClick = () => {
    if (vaultKey) {
      handleVaultModal(vaultKey);
    } else {
      console.log('no valid vault key');
    }
  };

  return (
    <div
      className={`${classes.vaultPanelContainer} ${className}`}
      style={{
        background: palette[protocolKey],
        ...style
      }}
      onClick={useClick ? handlePanelClick : () => {}}>
      <Grid container justify={'center'} alignItems={'center'}>
        <div
          style={{
            position: 'absolute',
            top: 8,
            right: 8,
            opacity: 0.95
          }}>
          <img
            style={{ width: 32, height: 32 }}
            src={AngelAvatarSvg}
            alt={'Angel Avatar'}
          />
        </div>
        <Grid item xs={12}>
          <VaultIcon
            className={classes.img}
            vaultKey={vaultKey}
            icon1Type="bordered"
            icon2Type="full"
          />
        </Grid>
        <Grid item xs={12} className={classes.vaultNameRow}>
          <Typography align={'center'} className={classes.vaultNameText}>
            {vault.baseTokenName.substring(0, vault.baseTokenName.length - 2)}
          </Typography>
        </Grid>
        <Grid item xs={6} className={classes.protocolChipGrid}>
          <ProtocolChip protocolNameShort={protocolNameShort} />
        </Grid>
        <Grid item xs={12} className={classes.tokenValueRow}>
          <Typography align={'center'} className={classes.stakedTokenValueText}>
            {balanceDisplay === 'uusd' ? (
              <AnimateNumber format={microNumberToDollarValue}>
                {stakedTokenValue}
              </AnimateNumber>
            ) : (
              <>
                <AnimateNumber format={(x) => formatMicroNumber(x, 3)}>
                  {stakedTokenBalance}
                </AnimateNumber>{' '}
                LP
              </>
            )}
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.apyRow}>
          <Typography
            align={'center'}
            className={classes.apyText}
            style={{
              color: textPalette[protocolKey]
            }}>
            {vault.id === 31 ? (
              <span>Delisted</span>
            ) : (
              <>
                <AnimateNumber format={formatRate}>
                  {vault.apolloApr}
                </AnimateNumber>
                {'%'}
              </>
            )}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default VaultPanel;
