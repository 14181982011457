import { useContext } from 'react';
import { Typography, Grid } from '@material-ui/core';
import { NewModalContext } from '../../providers/NewModalProvider';
import { makeStyles } from '@material-ui/core';
import Button from '../common/Button';
import { hexToRgbA } from '../../libs/util';
import { fontWeights } from '../../styles/themes/redesign';

const useStyles = makeStyles((theme) => ({
  container: {
    border: `1px solid ${hexToRgbA('#FFFFFF', 0.2)}`,
    backgroundColor: '#070A0F',
    borderRadius: 16,
    padding: 20,
    width: 374,
    position: 'absolute',
    top: '30% ',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex'
  },
  announcementTitleText: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: fontWeights.semiBold,
    paddingBottom: 20
  },
  announcementBodyText: {
    fontSize: theme.typography.pxToRem(15)
  }
}));

const AnnouncementModal = ({ ...rest }) => {
  const { handleClose } = useContext(NewModalContext);

  const classes = useStyles();
  return (
    <Grid className={classes.container} container {...rest}>
      <Grid item>
        <Typography className={classes.announcementTitleText}>
          All you need is UST to start yield farming!
        </Typography>
      </Grid>
      <Grid item>
        <Typography className={classes.announcementBodyText}>
          Our Apollo Vaults will auto-magically split your UST deposit into
          equal amounts of the Vaults required token and UST, add the pair of
          tokens into the Liquidity Pool, and lastly deposit the LP Tokens into
          the Vault.
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ marginTop: 16 }}>
        <Button filled onClick={() => handleClose()}>
          Close
        </Button>
      </Grid>
    </Grid>
  );
};

export default AnnouncementModal;
