import { atomFamily, selector, selectorFamily } from 'recoil';
import { request } from 'graphql-request';
import { path } from 'ramda';
import { getTime, startOfMinute, subDays } from 'date-fns';
import { useStoreLoadable } from '../utils/loadable';
import { statsURLQuery } from '../network';
import { ASSETS } from './gqldocs';

export enum StatsNetwork {
  COMBINE = 'COMBINE',
  TERRA = 'Terra',
  ETH = 'ETH'
}

export const assetsByNetworkQuery = selectorFamily({
  key: 'assetsByNetwork',
  get: (network: StatsNetwork) => async ({ get }) => {
    const url = get(statsURLQuery);

    const { assets } = await request<{ assets: AssetDataItem[] }>(
      `${url}?assetsStats:${network.toUpperCase()}`,
      ASSETS.STATS,
      { network: network.toUpperCase() }
    );

    return assets.reduce<Dictionary<AssetDataItem>>(
      (acc, asset) => ({ ...acc, [asset.token]: asset }),
      {}
    );
  }
});

export const assetsByNetworkState = atomFamily<
  Dictionary<AssetDataItem>,
  StatsNetwork
>({
  key: 'assetsByNetworkState',
  default: {}
});

interface Asset {
  token: string;
  prices: Prices;
}

interface Prices {
  price: string;
  priceAt: string | null;
  oraclePrice: string;
  oraclePriceAt: string | null;
}

export const pricesQuery = selector({
  key: 'prices',
  get: async ({ get }) => {
    const url = get(statsURLQuery);
    const yesterday = getTime(subDays(startOfMinute(new Date()), 1));
    const { assets } = await request<{ assets: Asset[] }>(
      url + '?assetsPrices',
      ASSETS.CHANGE,
      { timestamp: yesterday }
    );

    return assets.reduce<Dictionary<Prices>>(
      (acc, cur) => ({ ...acc, [cur.token]: cur.prices }),
      {}
    );
  }
});

/* hooks */
export const useAssetsByNetwork = (network = StatsNetwork.TERRA) => {
  return useStoreLoadable(
    assetsByNetworkQuery(network),
    assetsByNetworkState(network)
  );
};

export const useAssetsHelpersByNetwork = (network = StatsNetwork.TERRA) => {
  const assets = useAssetsByNetwork(network);
  return getAssetsHelpers(assets);
};

export const getAssetsHelpers = (assets: Dictionary<AssetDataItem>) => {
  const getValueFromAsset = (key: string) => (token: string) =>
    path(key.split('.'), assets[token]) as string;

  return {
    description: getValueFromAsset('description'),
    liquidity: getValueFromAsset('statistic.liquidity'),
    volume: getValueFromAsset('statistic.volume'),
    marketCap: getValueFromAsset('statistic.marketCap'),
    collateralValue: getValueFromAsset('statistic.collateralValue'),
    minCollateralRatio: getValueFromAsset('statistic.minCollateralRatio'),
    longAPR: getValueFromAsset('statistic.apr.long'),
    shortAPR: getValueFromAsset('statistic.apr.short')
  };
};
