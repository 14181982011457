import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    opacity: 0.501,
    '&:hover': {
      opacity: 1,
      cursor: 'pointer'
    }
  }
}));

export const CloseButton = ({ ...rest }) => {
  const classes = useStyles();
  return (
    <svg
      id="Close_Button"
      data-name="Close Button"
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 35 35"
      className={classes.closeButton}
      {...rest}>
      <rect
        id="Rectangle_361"
        data-name="Rectangle 361"
        width="35"
        height="35"
        rx="12"
        fill="#1c293e"
      />
      <g
        id="Group_1502"
        data-name="Group 1502"
        transform="translate(-1544.5 -219.5)">
        <line
          id="Line_205"
          data-name="Line 205"
          x2="8"
          y2="8"
          transform="translate(1558.5 233.5)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeWidth="2"
        />
        <line
          id="Line_206"
          data-name="Line 206"
          x1="8"
          y2="8"
          transform="translate(1558.5 233.5)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};
