import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { ASSET_PATH } from '../../store/constants';

const protocolIcons = {
  Mirror: `${ASSET_PATH}/icons/MIR.png`,
  Anchor: `${ASSET_PATH}/icons/anchor-small.png`,
  Pylon: `${ASSET_PATH}/icons/pylon-small.png`,
  StarTerra: `${ASSET_PATH}/icons/STT.png`
};

const useStyles = makeStyles((theme) => ({
  chipContainer: {
    border: '.5px solid rgba(255,255,255,.3)',
    height: 22,
    borderRadius: 10,
    padding: '0px 6px',
    margin: '0 auto'
  },
  protocolIcon: {
    display: 'block',
    width: 12,
    height: 12,
    marginRight: 4,
    marginTop: 1
  },
  protocolText: {
    fontSize: theme.typography.pxToRem(13)
  }
}));

const ProtocolChip = ({ protocolNameShort, ...rest }) => {
  const classes = useStyles();
  return (
    <Grid
      className={classes.chipContainer}
      container
      {...rest}
      alignItems={'center'}
      justify={'center'}>
      <Grid item className={classes.chipImageGrid}>
        <img
          className={classes.protocolIcon}
          src={protocolIcons[protocolNameShort]}
          alt={'protocol'}
        />
      </Grid>
      <Grid item>
        <Typography className={classes.protocolText}>
          {protocolNameShort}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ProtocolChip;
