import { Grid, Typography, makeStyles, Modal } from '@material-ui/core';
import { palette, fontWeights } from '../../styles/themes/redesign';
import { hexToRgbA } from '../../libs/util';
import CloseIcon from '@material-ui/icons/Close';
import { StrategyInfo } from '../../store/strategies';
import { formatRate } from '@anchor-protocol/notation';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'absolute',
    width: '100%',
    maxWidth: 421,
    minHeight: 433,
    top: '50% ',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 36,
    backgroundColor: palette.modalBlue,
    border: `1px solid ${hexToRgbA('#FFFFFF', 0.2)}`,
    boxShadow: `0px 3px 10px rgba(0, 0, 0, 0.3)`
  },
  modalInnerContainer: {
    margin: 10,
    position: 'relative'
  },
  modalSection: {},
  modalVaultInfo: {
    padding: 10
  },
  modalDepositWithdraw: {
    minHeight: 225,
    backgroundColor: hexToRgbA(palette.darkBlueGrey, 0.8),
    border: `1px solid ${hexToRgbA('#FFFFFF', 0.2)}`,
    padding: 10,
    borderRadius: 25
  },
  vaultInfoContainer: {
    position: 'relative',
    maxWidth: 410,
    minHeight: 363,
    top: '49%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: hexToRgbA('#12161F', 0.9),
    border: `1px solid ${hexToRgbA('#FFFFFF', 0.2)}`,
    padding: 10,
    borderRadius: 25
  },
  vaultInfoCloseButtonContainer: {
    position: 'absolute',
    top: 10,
    right: 20,
    cursor: 'pointer'
  },
  vaultInfoCloseButton: {
    color: '#FFFFFF'
  },
  vaultInfoStatGroup: {
    paddingBottom: 10,
    fontSize: 15,
    color: '#FFF'
  },
  vaultInfoStatsTitle: {
    fontWeight: fontWeights.semiBold,
    fontSize: 15
  },
  vaultInfoStatsDetail: {},
  vaultInfoDetailLabel: {
    color: 'rgba(255,255,255, .5)'
  }
}));

export const VaultInfoDialog = ({
  open,
  handleClose,
  vault
}: {
  open: boolean;
  handleClose: () => void;
  vault: StrategyInfo;
}) => {
  let stats = [
    {
      title: 'Vault Details',
      stats: [
        {
          label: 'Asset',
          value: vault.baseTokenName
        },
        {
          label: 'Description',
          value: `During the Community Farming Event, 94.9% of the rewards will be used to purchase HALO tokens at a fixed price of $0.05.`
        },
        {
          label: 'Farm Name',
          value: vault.protocolNameFull
        }
      ]
    },
    {
      title: 'APY Calculations',
      stats: [
        {
          label: `Original Farm APR`,
          value: formatRate(vault.farmApr) + '%'
        },
        {
          label: 'HALO APR',
          value: formatRate(vault.apolloApr) + '%'
        }
      ]
    },
    {
      title: 'Fees',
      stats: [
        {
          label: 'Performance Fee',
          value: formatRate(vault.performanceFee) + '%'
        },
        {
          label: 'Platform Fee',
          value: '5%'
        },
        {
          label: 'Deposit Fee',
          value: formatRate(vault.depositFee) + '%'
        },
        {
          label: 'Withdrawal Fee',
          value: formatRate(vault.withdrawalFee) + '%'
        }
      ]
    }
  ];

  if (vault.id === 31) {
    stats[0] = {
      title: 'Vault Details',
      stats: [
        {
          label: 'Asset',
          value: vault.baseTokenName
        },
        {
          label: 'Description',
          value: `The STT-UST vault has been delisted.  If you have LP in this vault, you may withdraw your LP. Deposits are disabled.`
        },
        {
          label: 'Farm Name',
          value: vault.protocolNameFull
        }
      ]
    };

    stats[1] = {
      title: 'APY Calculations - STT',
      stats: [
        {
          label: 'STT APR',
          value: '0%'
        },
        {
          label: 'Apollo APR',
          value: '0%'
        }
      ]
    };

    stats[2] = {
      title: 'Fees',
      stats: [
        {
          label: 'Performance Fee',
          value: '---'
        },
        {
          label: 'Platform Fee',
          value: formatRate(vault.platformFee) + '%'
        },
        {
          label: 'Deposit Fee',
          value: formatRate(vault.depositFee) + '%'
        },
        {
          label: 'Withdrawal Fee',
          value: formatRate(vault.withdrawalFee) + '%'
        }
      ]
    };
  }

  const classes = useStyles();
  return (
    <Modal open={open} onClose={() => {}}>
      <Grid className={classes.vaultInfoContainer} container>
        <div
          className={classes.vaultInfoCloseButtonContainer}
          onClick={() => handleClose()}>
          <CloseIcon className={classes.vaultInfoCloseButton} />
        </div>
        <Grid item xs={12}>
          {stats.map((s, i) => (
            <div className={classes.vaultInfoStatGroup} key={`${s.title}${i}`}>
              <Typography className={classes.vaultInfoStatsTitle}>
                {s.title}
              </Typography>
              {s.stats.map((d, i) => (
                <Typography
                  key={`${d.label}${i}`}
                  className={classes.vaultInfoStatsDetail}>
                  <span className={classes.vaultInfoDetailLabel}>
                    {d.label}:
                  </span>{' '}
                  {d.value}
                </Typography>
              ))}
            </div>
          ))}
        </Grid>
      </Grid>
    </Modal>
  );
};
