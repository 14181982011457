import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { MouseEventHandler, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { palette } from '../../styles/themes/redesign';

const useStyles = makeStyles((theme) => ({
  container: {
    height: 40,
    width: '100%',
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 15,
    cursor: 'pointer',
    color: 'rgba(255,255,255, .8)',
    '&:hover': {
      color: 'rgba(255,255,255, 1)'
    }
  },
  filled: {
    backgroundColor: palette.darkBlueGrey,
    color: 'rgba(255,255,255, 1)'
  },
  disabled: {
    color: 'rgba(255,255,255, .5)',
    cursor: 'not-allowed',
    '&:hover': {
      color: 'rgba(255,255,255, .5)'
    }
  },
  buttonGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  buttonText: {
    fontWeight: 600
  }
}));

//TODO: Should we inject classes so we can generally use it?
export type ButtonInterface = {
  children?: ReactNode;
  to?: string;
  className?: string;
  textClassName?: string;
  filled?: boolean;
  disabled?: boolean;
  href?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
};

const Button = ({
  children,
  to,
  className,
  textClassName,
  filled,
  disabled,
  href,
  onClick,
  ...rest
}: ButtonInterface) => {
  const classes = useStyles();

  return to ? (
    <Link
      onClick={onClick}
      to={to}
      className={`${classes.container} ${filled && classes.filled} ${
        disabled && classes.disabled
      } ${className}`}
      {...rest}>
      <Typography className={`${classes.buttonText} ${textClassName}`}>
        {children}
      </Typography>
    </Link>
  ) : (
    <a
      onClick={onClick}
      className={`${classes.container} ${filled && classes.filled} ${
        disabled && classes.disabled
      } ${className}`}
      {...rest}
      href={href}
      rel="noreferrer">
      <Typography className={`${classes.buttonText} ${textClassName}`}>
        {children}
      </Typography>
    </a>
  );
};

export default Button;
