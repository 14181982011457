import { FC, forwardRef } from 'react';
import { makeStyles } from '@material-ui/core';
import { fontWeights } from '../../styles/themes/redesign';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: `25px 25px 0 25px`
  },
  navButton: {
    marginRight: 10
  },
  apolloLogo: {
    marginRight: 15
  },
  walletButton: {
    marginRight: 5
  },
  connectButton: {
    width: 140,
    marginRight: 10,
    background: `linear-gradient(to left, #fed200, #fd806d)`,
    transition: 'all .2s ease-in-out',
    fontWeight: fontWeights.semiBold,
    color: '#FFF',
    padding: 10,
    borderRadius: 15,
    border: 0,
    cursor: 'pointer',
    '&:hover': {
      color: 'rgba(255,255,255, 1)',
      transform: 'scale(1.04)'
    }
  }
}));
interface Props {
  connected?: boolean;
  className?: string;
  onClick?: () => void;
}

const ConnectButton: FC<Props> = forwardRef(
  ({ connected, className, onClick, children }, ref: any) => {
    const classes = useStyles();
    const attrs = {
      className: classes.connectButton,
      children,
      ref
    };

    return <button {...attrs} onClick={onClick} />;
  }
);

export default ConnectButton;
