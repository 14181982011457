import { AccAddress } from '@terra-money/terra.js';
import { atom, selector, selectorFamily } from 'recoil';
import { networkNameState } from '../network';
import {
  GetUserStrategiesResponse,
  FactoryUserInfoResponse
} from '../../types/apollo-factory/get_user_strategies_response';
import { GetTotalCollectedFeesResponse } from '../../types/apollo-factory/get_total_collected_fees_response';
import { QueryMsg } from '../../types/apollo-factory/query_msg';
import { addressState } from '../wallet';
import networks, { isSupportedNetwork } from '../../store/networks';
import {
  FactoryStrategyInfoResponse,
  GetStrategiesResponse
} from '../../types/apollo-factory/get_strategies_response';
import {
  defaultDynamicStrategyInfo,
  DynamicStrategyInfo,
  isSupportedStrategyId,
  StaticStrategyInfo,
  allStaticStrategyInfos,
  StrategyId,
  StrategyInfo,
  SupportedAsset
} from '../../store/strategies';
import {
  assetsByNetworkQuery,
  getAssetsHelpers,
  StatsNetwork
} from '../stats/assets';
import {
  div,
  plus,
  safeParseFloat,
  safeParseInt,
  times
} from '../../libs/math';
import {
  lpStakableBalancesQuery,
  nativeBalancesQuery,
  parsePairPool
} from '../mirror/normalize';
import calc from '../../libs/calc';
import { pairPoolQuery } from '../mirror/contract';
import { strategyRequestIndex } from '../app';
import { getContractQueriesQuery } from '../utils/queries';
import alias, { Query } from '../mirror/alias';
import axios from 'axios';
import { useStoreLoadable } from '../utils/loadable';
import { GetExtensionTotalCollectedFeesResponse } from '../../types/apollo-factory/get_extension_total_collected_fees_response';

export type FactoryStrategies = {
  info: FactoryStrategyInfoResponse;
  userInfo: FactoryUserInfoResponse;
}[];

export type StakableAsset = {
  key: string;
  assetName: SupportedAsset | 'UST';
  balance: number;
  value: number;
  denom: string;
  vaultApy: number;
};

const factoryAddressQuery = selector({
  key: 'FacoryAddressQuery',
  get: ({ get }) => {
    const networkName = get(networkNameState);
    if (!isSupportedNetwork(networkName))
      throw new Error('Unsupported network!');
    return networks[networkName].contracts.factory;
  }
});

const factoryQuery2 = selectorFamily({
  key: 'FactoryQuery2',
  get: <T>(queryMsg: QueryMsg) => async ({ get }) => {
    let name = Object.entries(queryMsg)[0][0];

    get(strategyRequestIndex);

    const factoryAddress = get(factoryAddressQuery);

    const getContractQueries = get(getContractQueriesQuery);

    let query: Query = {
      name,
      contract: factoryAddress,
      msg: queryMsg
    };

    const document = alias([query], name);
    let res = await getContractQueries<T>(document, name);
    if (!res) return undefined;

    let values = Object.values(res);
    return values.length > 0 ? values[0] : undefined;
  }
});

// type StakingConfigResponse = {
//   staking_token: string;
//   distribution_schedule: [number, number, string];
// };

// export const distributionSchedulesQuery = selector({
//   key: 'distributionSchedulesQuery',
//   get: async ({ get }) => {
//     get(strategyRequestIndex);

//     const networkName = get(networkNameState);

//     const getContractQueries = get(getContractQueriesQuery);

//     let contracts = {
//       anchor: '',
//       pylon: '',
//       stt: ''
//     };

//     if (networkName === 'mainnet') {
//       contracts.anchor = 'terra1897an2xux840p9lrh6py3ryankc6mspw49xse3';
//       contracts.pylon = 'terra19nek85kaqrvzlxygw20jhy08h3ryjf5kg4ep3l';
//     } else if (networkName === 'testnet') {
//       contracts.anchor = 'terra19nxz35c8f7t3ghdxrxherym20tux8eccar0c3k';
//       contracts.pylon = 'terra17av0lfhqymusm6j9jpepzerg6u54q57jp7xnrz';
//       contracts.stt = 'terra1vhcw4weg53psp3h44ss80nwmp7ey7egay02j4m';
//     }

//     let queries: Query[] = Object.entries(contracts).map((x) => ({
//       name: x[0],
//       contract: x[1],
//       msg: { config: {} }
//     }));

//     console.log(`queries:`, queries);

//     try {
//       const document = alias(queries, 'distributionSchedules');
//       let res = await getContractQueries<StakingConfigResponse>(
//         document,
//         'distributionSchedules'
//       );
//       if (!res) return undefined;

//       console.log(`res: `, res);

//       return res
//         ? Object.entries(res).flatMap(([name, x]) => [
//             name,
//             x.distribution_schedule
//           ])
//         : [];

//       const result = await request<Dictionary<ContractData | null> | null>(
//         url + '?' + name,
//         document
//       );

//       return result ? parseResults<Parsed>(result) : undefined;
//     } catch (error) {
//       const result =
//         error instanceof ClientError ? error.response.data : undefined;
//       return result ? parseResults<Parsed>(result) : undefined;
//     }
//   }
// });

export const totalFarmedPhaseOneQuery = selector({
  key: 'totalFarmedPhaseOneQuery',
  get: async ({ get }) => {
    const res = get(
      factoryQuery2<GetTotalCollectedFeesResponse>({
        get_total_collected_fees: {}
      })
    );

    const totalFees = res?.total_collected_fees || '0';

    return (safeParseInt(totalFees) / 1000000) * 20;
  }
});

export const totalFarmedPhaseTwoQuery = selector({
  key: 'totalFarmedPhaseTwoQuery',
  get: async ({ get }) => {
    const res = get(
      factoryQuery2<GetExtensionTotalCollectedFeesResponse>({
        get_extension_total_collected_fees: {}
      })
    );

    const totalFees = res?.extension_total_collected_fees || '0';

    return (safeParseInt(totalFees) / 1000000) * 20;
  }
});

const totalTvlState = atom<string>({
  key: 'totalTvlState',
  default: '$0'
});

export const getTotalTvlQuery = selector({
  key: 'GetTotalTvlQuery',
  get: async ({ get }) => {
    try {
      return (await axios.get('https://api.apollo.farm/api/halo-stats')).data;
    } catch (error) {
      return 0.000001;
    }
  }
});

export const useTotalTvl = () => {
  return useStoreLoadable(getTotalTvlQuery, totalTvlState);
};

export const getFactoryStrategiesQuery = selector({
  key: 'GetFactoryStrategiesQuery',
  get: async ({ get }) => {
    const factoryAddress = get(factoryAddressQuery);
    const getContractQueries = get(getContractQueriesQuery);

    const strategyCount = 36;
    const limit = 1; //gas limit seems to be 14, but use 12 to be safe
    const pages = Math.ceil(strategyCount / limit);

    let queries: Query[] = [];
    for (let i = 0; i < pages; i++) {
      queries.push({
        name: `page_${i}`,
        contract: factoryAddress,
        msg: { get_strategies: { limit, start_from: i * limit } }
      });
    }
    const document = alias(queries, 'factoryStrategies');
    let res = await getContractQueries<GetStrategiesResponse>(
      document,
      'factoryStrategies'
    );

    return res ? Object.values(res).flatMap((x) => x.strategies) : [];
  }
});

export const getAnchorAprQuery = selector({
  key: 'getAnchorAprQuery',
  get: async ({ get }) => {
    try {
      const url = 'https://api.anchorprotocol.com/api/v2/ust-lp-reward';

      const result = await axios.get(url);

      if (!result) return 0;

      return safeParseFloat(result?.data.apy || '0');
    } catch (error: any) {
      console.log('GetAnchorAprQuery error: ', error);
      return 0;
    }
  }
});

export const communityFarmingEndTimeQuery = selector({
  key: 'communityFarmingEndTimeQuery',
  get: async ({ get }) => {
    const totalAverageApr = get(totalAverageAprQuery);
    const totalFarmed = get(totalFarmedPhaseTwoQuery);

    const totalTvl = get(getTotalTvlQuery);

    let revenuePerSecond = ((totalAverageApr * totalTvl) / 31556952) * 0.1;

    let apolloPerSecond = revenuePerSecond / 0.05;

    const farmedTokenLimit = 60000000;

    const tokensRemaining = farmedTokenLimit - totalFarmed;

    const milliSecondsRemaining = (tokensRemaining / apolloPerSecond) * 1000;

    const currentTime = Date.now();
    const endTimestamp = new Date(currentTime + milliSecondsRemaining);
    return endTimestamp;
  }
});

export const getUserStrategiesQuery = selector({
  key: 'GetUserStrategiesQuery',
  get: async ({ get }) => {
    get(strategyRequestIndex);

    const factoryAddress = get(factoryAddressQuery);
    const userWalletAddr = get(addressState);
    if (!userWalletAddr) return [];
    const getContractQueries = get(getContractQueriesQuery);
    const strategyCount = 36;
    const limit = 12; //gas limit seems to be 14, but use 12 to be safe
    const pages = Math.ceil(strategyCount / limit);

    let queries: Query[] = [];
    for (let i = 0; i < pages; i++) {
      const msg: QueryMsg = {
        get_user_strategies: {
          user: userWalletAddr,
          limit,
          start_from: i * limit
        }
      };

      queries.push({
        name: `page_${i}`,
        contract: factoryAddress,
        msg
      });
    }

    const document = alias(queries, 'factoryUserStrategies');

    let res = await getContractQueries<GetUserStrategiesResponse>(
      document,
      'factoryUserStrategies'
    );
    let strategies = res ? Object.values(res).flatMap((x) => x.strategies) : [];

    return strategies.filter(
      (x) =>
        safeParseInt(x.base_token_balance) > 0 ||
        safeParseInt(x.pending_reward) > 0 ||
        safeParseInt(x.extension_pending_reward) > 0
    );
  }
});

type CfeRewards = {
  phase_1_rewards: number;
  phase_2_rewards: number;
};

export const getCfeRewardsQuery = selector({
  key: 'GetCfeRewardsQuery',
  get: async ({ get }) => {
    const userStrategies = get(getUserStrategiesQuery);

    let phase_1_rewards = 0;
    let phase_2_rewards = 0;
    userStrategies.forEach((strategy) => {
      phase_1_rewards += safeParseInt(strategy.pending_reward);
      phase_2_rewards += safeParseInt(strategy.extension_pending_reward);
    });

    let result: CfeRewards = {
      phase_1_rewards,
      phase_2_rewards
    };

    return result;
  }
});

const stakedTokenBalanceQuery = selectorFamily({
  key: 'StakedTokenBalanceQuery',
  get: (strategyId: StrategyId) => ({ get }) => {
    const userStrategies = get(getUserStrategiesQuery);
    return (
      userStrategies.find((x) => x.id === strategyId)?.base_token_balance || '0'
    );
  }
});

export const LpTokenPrice = selectorFamily({
  key: 'LpTokenValueQuery',
  get: (assetToken: AccAddress) => ({ get }) => {
    const pairs = get(pairPoolQuery);

    /* pair pool */
    const pairPool =
      pairs && pairs[assetToken]
        ? parsePairPool(pairs[assetToken])
        : { uusd: '0', asset: '0', total: '0' };

    /* from lp */
    const shares = {
      asset: { amount: pairPool.asset, token: assetToken },
      uusd: { amount: pairPool.uusd, token: 'uusd' }
    };

    const price = div(pairPool.uusd, pairPool.asset);

    const fromLP = calc.fromLP('1', shares, pairPool.total);
    const assetValueFromLP = times(price, fromLP.asset.amount);
    const valueFromLP = plus(assetValueFromLP, fromLP.uusd.amount);

    return [safeParseFloat(valueFromLP), safeParseFloat(price) * 1000000];
  }
});

export const assetAddressQuery = selectorFamily({
  key: 'assetAddressQuery',
  get: (asset: SupportedAsset) => ({ get }) => {
    const networkName = get(networkNameState);
    if (networkName && isSupportedNetwork(networkName)) {
      return networks[networkName].contracts.tokens[asset];
    } else {
      throw new Error('asset does not exist');
    }
  }
});

const sttAprQuery = selector({
  key: 'assetAddressQuery',
  get: ({ get }) => {
    const networkName = get(networkNameState);

    let strategyId: StrategyId;

    if (networkName === 'mainnet') {
      strategyId = 31;
    } else {
      strategyId = 25;
    }

    const factoryStrategies = get(getFactoryStrategiesQuery);
    const factoryStrategyInfo = factoryStrategies.find(
      (x) => x.id === strategyId
    );

    if (!factoryStrategyInfo) return 0;

    const staticStrategyInfo = allStaticStrategyInfos[networkName][strategyId];
    const mAsset = staticStrategyInfo.key;
    const mAssetAddr = get(assetAddressQuery(mAsset));
    if (!mAssetAddr) return 0;
    let [, tokenPrice] = get(LpTokenPrice(mAssetAddr));

    return factoryStrategyInfo.total_bond_amount === '0'
      ? 0
      : (tokenPrice * 4020076) / safeParseInt(factoryStrategyInfo.tvl);
  }
});

const strategyMirrorAprQuery = selectorFamily({
  key: 'StrategyMirrorAprQuery',
  get: (strategyId: StrategyId) => ({ get }) => {
    get(strategyRequestIndex);
    const networkName = get(networkNameState);
    const staticStrategyInfo = allStaticStrategyInfos[networkName][strategyId];
    const mAsset = staticStrategyInfo.key;

    const assets = get(assetsByNetworkQuery(StatsNetwork.TERRA));
    const getLongAPR = getAssetsHelpers(assets).longAPR;

    const mAssetAddr = get(assetAddressQuery(mAsset));
    if (!mAssetAddr) return 0;

    if (
      (strategyId === 23 && networkName === 'testnet') ||
      (strategyId === 29 && networkName === 'mainnet')
    ) {
      const pylonInfo = get(pylonInfoQuery);
      return safeParseFloat(pylonInfo.liq.apy);
    }

    if (
      (strategyId === 24 && networkName === 'testnet') ||
      (strategyId === 30 && networkName === 'mainnet')
    ) {
      return get(getAnchorAprQuery);
    }

    if (
      (strategyId === 25 && networkName === 'testnet') ||
      (strategyId === 31 && networkName === 'mainnet')
    ) {
      return get(sttAprQuery);
    }

    return safeParseFloat(getLongAPR(mAssetAddr));
  }
});

type PylonInfo = {
  liq: {
    apy: string;
  };
  price: {
    priceInUst: string;
  };
};

const pylonInfoQuery = selector({
  key: 'PylonInfoQuery',
  get: async ({ get }) => {
    try {
      const req = await axios.get<PylonInfo>(
        'https://api.apollo.farm/api/pylon-liquidity'
      );
      return req.data;
    } catch (error) {
      console.log('Pylon API error, set to zero');
      return {
        liq: {
          apy: '0'
        },
        price: {
          priceInUst: '0'
        }
      };
    }
  }
});

// export const sttStakedLpQuery = selector({
//   key: 'sttStakedLpQuery',
//   get: async ({ get }) => {
//     get(bankBalanceIndexState);

//     const networkName = get(networkNameState);
//     let address = '';

//     if (networkName === 'mainnet') {
//       address = '';
//     } else if (networkName === 'testnet') {
//       address = 'terra1xdtr6hk6s9jm6cr6pu5h4suesnz65dlg8t3je5';
//     }

//     const getListedContractQueries = get(getListedContractQueriesQuery);
//     return await getListedContractQueries<Balance>(
//       ({ lpToken }) => ({ contract: lpToken, msg: { balance: { address } } }),
//       'lpTokenBalance'
//     );
//   }
// });

const dynamicStrategyInfoQuery = selectorFamily({
  key: 'DynamicStrategyInfoQuery',
  get: (strategyId: StrategyId) => async ({ get }) => {
    const factoryStrategies = get(getFactoryStrategiesQuery);
    const factoryStrategyInfo = factoryStrategies.find(
      (x) => x.id === strategyId
    );
    if (!factoryStrategyInfo) return defaultDynamicStrategyInfo;

    get(strategyRequestIndex);

    const performanceFee =
      safeParseFloat(factoryStrategyInfo.performance_fee) * 0.95;
    const tvl = safeParseFloat(factoryStrategyInfo.tvl);
    const networkName = get(networkNameState);
    const staticStrategyInfo = allStaticStrategyInfos[networkName][strategyId];
    const mAsset = staticStrategyInfo.key;
    const mAssetAddr = get(assetAddressQuery(mAsset));
    if (!mAssetAddr) return defaultDynamicStrategyInfo;
    let mirrorApr = get(strategyMirrorAprQuery(strategyId));
    let [lpTokenPrice, tokenPrice] = get(LpTokenPrice(mAssetAddr));

    if (
      (strategyId === 23 && networkName === 'testnet') ||
      (strategyId === 29 && networkName === 'mainnet')
    ) {
      const pylonInfo = get(pylonInfoQuery);
      mirrorApr = safeParseFloat(pylonInfo.liq.apy);
    }

    if (
      (strategyId === 24 && networkName === 'testnet') ||
      (strategyId === 30 && networkName === 'mainnet')
    ) {
      mirrorApr = get(getAnchorAprQuery);
    }

    if (
      (strategyId === 25 && networkName === 'testnet') ||
      (strategyId === 31 && networkName === 'mainnet')
    ) {
      mirrorApr = 0;
    }

    const mirrorApy = calc.aprToApy(mirrorApr);
    const apolloApr = mirrorApr * performanceFee;
    const apr = mirrorApr * (1 - performanceFee);
    const apy = calc.aprToApy(apr);
    const dpr = apr / 365.25 + apolloApr / 365.25;

    const walletLpTokenBalances = get(lpStakableBalancesQuery);
    const walletLpTokenBalance = safeParseInt(
      walletLpTokenBalances[mAssetAddr]
    );
    const stakedLpTokenBalance = safeParseInt(
      get(stakedTokenBalanceQuery(strategyId))
    );

    const stakedLpTokenWorth = stakedLpTokenBalance * lpTokenPrice;
    const walletLpTokenWorth = walletLpTokenBalance * lpTokenPrice;

    const result: DynamicStrategyInfo = {
      farmApr: mirrorApr,
      farmApy: mirrorApy,
      apr,
      apy,
      dpr,
      tvl,
      stakedTokenBalance: stakedLpTokenBalance,
      stakedTokenValue: stakedLpTokenWorth,
      stakableTokenBalance: walletLpTokenBalance,
      stakableTokenValue: walletLpTokenWorth,
      baseTokenPrice: lpTokenPrice,
      tokenPrice: tokenPrice,
      apolloApr,
      depositFee: 0,
      withdrawalFee: 0,
      performanceFee,
      platformFee: 0
    };
    return result;
  }
});

export const userStrategiesQuery = selector({
  key: 'UserStrategiesQuery',
  get: ({ get }) => {
    const userStrategyInfo = get(getUserStrategiesQuery);
    const networkName = get(networkNameState);
    const userStrategies: StrategyInfo[] = userStrategyInfo
      .map(
        (strategy) =>
          isSupportedStrategyId(strategy.id) &&
          allStaticStrategyInfos[networkName][strategy.id]
      )
      .filter((x): x is StaticStrategyInfo => !!x)
      .map((x) => ({
        ...x,
        ...get(dynamicStrategyInfoQuery(x.id))
      }))
      .filter((x) => x.stakedTokenBalance > 10000);
    return userStrategies;
  }
});

export const availableStrategiesQuery = selector({
  key: 'AvailableStrategyQuery',
  get: ({ get }) => {
    const strategies = get(getStrategiesQuery);
    const userStrategyInfo = get(getUserStrategiesQuery);

    const availableStrategies: StrategyInfo[] = strategies.filter(
      (strategy) => !userStrategyInfo[strategy.id]
    );

    return availableStrategies;
  }
});

export const getStrategiesQuery = selector({
  key: 'GetStrategiesQuery',
  get: ({ get }) => {
    const factoryStrategies = get(getFactoryStrategiesQuery);

    const networkName = get(networkNameState);
    const strategies: StrategyInfo[] = factoryStrategies
      .filter((strategy) => !strategy.deprecated)
      .map(
        (x) =>
          isSupportedStrategyId(x.id) &&
          allStaticStrategyInfos[networkName][x.id]
      )
      .filter((x): x is StaticStrategyInfo => !!x)
      .map((x) => ({
        ...x,
        ...get(dynamicStrategyInfoQuery(x.id))
      }));
    return strategies.filter((x) => x.apr > 0);
  }
});

export const getStrategyQuery = selectorFamily({
  key: 'GetStrategyQuery',
  get: (key: SupportedAsset) => ({ get }) => {
    const strategies = get(getStrategiesQuery);

    return strategies.find((x) => x.key === key);
  }
});

export const topYieldStrategiesQuery = selectorFamily({
  key: 'TopYieldStrategiesQuery',
  get: (count: number) => ({ get }) => {
    const strategies = get(getStrategiesQuery);
    return strategies
      .slice() //strategies is readonly, so we copy it before sorting in place
      .sort((a, b) => {
        const mirrorAprA = get(strategyMirrorAprQuery(a.id));
        const mirrorAprB = get(strategyMirrorAprQuery(b.id));
        return mirrorAprB - mirrorAprA;
      })
      .slice(0, count);
  }
});

export const stakableAssetsQuery = selector({
  key: 'StakableAssetsQuery',
  get: ({ get }) => {
    const networkName = get(networkNameState);
    if (!networkName || !isSupportedNetwork(networkName))
      throw new Error('Network not connected or wrong network');

    const assets = Object.entries(networks[networkName].contracts.tokens);
    const strategies = get(getStrategiesQuery);
    const walletLpTokenBalances = get(lpStakableBalancesQuery);

    let stakableAssets = Object.entries(walletLpTokenBalances)
      .filter(([token, balance]) => balance !== '0')
      .map(([token, balance]) => {
        const assetName = assets.find(
          ([asset, tokenAddr]) => token === tokenAddr
        )?.[0];
        if (!assetName) return undefined;
        const [lpTokenPrice] = get(LpTokenPrice(token));
        const value = lpTokenPrice * safeParseInt(balance);
        const strategy = strategies.find((x) => x.key === assetName);
        if (!strategy) return undefined;

        return {
          key: `${assetName}-UST LP`,
          assetName,
          balance: safeParseInt(balance),
          value,
          denom: 'LP',
          vaultApy: strategy.apy
        };
      })
      .filter((x): x is StakableAsset => x !== undefined);

    const nativeBalances = get(nativeBalancesQuery);
    const ustBalance: string | undefined = nativeBalances['uusd'];
    if (ustBalance) {
      stakableAssets.unshift({
        key: 'UST',
        assetName: 'UST',
        balance: safeParseInt(ustBalance),
        value: safeParseInt(ustBalance),
        denom: 'UST',
        vaultApy: 0
      });
    }

    return stakableAssets;
  }
});

export const stakableAssetsValueQuery = selector({
  key: 'StakableAssetsValueQuery',
  get: ({ get }) => {
    const stakableAssets = get(stakableAssetsQuery);
    let totalValue = 0;
    for (let i = 0; i < stakableAssets.length; i++) {
      const asset = stakableAssets[i];
      totalValue += asset.value;
    }
    return totalValue;
  }
});

export const stakedAssetsValueQuery = selector({
  key: 'StakedAssetsValueQuery',
  get: ({ get }) => {
    const userStrategies = get(userStrategiesQuery);
    let totalValue = 0;
    for (let i = 0; i < userStrategies.length; i++) {
      const strategy = userStrategies[i];
      totalValue += strategy.stakedTokenValue;
    }
    return totalValue;
  }
});

/*
TODO: Make this when merged with multiVaults branch
export const vaultFarmingValueQuery = selector({
  key: 'VaultFarmingValueQuery',
  get: ({ get }) => {
    const userStrategies = get(userStrategiesQuery);
    let totalValue = 0;
    for (let i = 0; i < userStrategies.length; i++) {
      const strategy = userStrategies[i];
      totalValue += strategy.FIELDHERE;
    }
    return totalValue;
  }
});
*/

/*
TODO: Make this when merged with Angel Farming branch
export const haloFarmingValueQuery = selector({
  key: 'HaloFarmingValueQuery',
  get: ({ get }) => {
    const userStrategies = get(userStrategiesQuery);
    let totalValue = 0;
    for (let i = 0; i < userStrategies.length; i++) {
      const strategy = userStrategies[i];
      totalValue += strategy.FIELDHERE;
    }
    return totalValue;
  }
});
*/

export const stakedAverageAprQuery = selector({
  key: 'StakedAverageAprQuery',
  get: ({ get }) => {
    const userStrategies = get(userStrategiesQuery);
    const totalValue = get(stakedAssetsValueQuery);
    let averageApr = 0;
    for (let i = 0; i < userStrategies.length; i++) {
      const strategy = userStrategies[i];
      if (strategy.stakedTokenValue > 0)
        averageApr += strategy.apr * (strategy.stakedTokenValue / totalValue);
    }
    return averageApr;
  }
});

export const stakedAverageApolloQuery = selector({
  key: 'StakedAverageApolloQuery',
  get: ({ get }) => {
    const userStrategies = get(userStrategiesQuery);
    const totalValue = get(stakedAssetsValueQuery);
    let averageApr = 0;
    for (let i = 0; i < userStrategies.length; i++) {
      const strategy = userStrategies[i];
      if (strategy.stakedTokenValue > 0)
        averageApr +=
          strategy.apolloApr * (strategy.stakedTokenValue / totalValue);
    }
    return averageApr;
  }
});

export const totalAverageAprQuery = selector({
  key: 'TotalAverageAprQuery',
  get: ({ get }) => {
    let totalTvl = get(getTotalTvlQuery);
    const strategies = get(getStrategiesQuery);

    if (totalTvl === 0) return 0;

    let averageApr = 0;
    for (let i = 0; i < strategies.length; i++) {
      const strategy = strategies[i];
      if (strategy.tvl > 0)
        averageApr += strategy.apolloApr * (strategy.tvl / totalTvl);
    }

    return averageApr;
  }
});
