import { useContext } from 'react';
import { NewModalContext } from '../../providers/NewModalProvider';
import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { hexToRgbA } from '../../libs/util';
import { fontWeights } from '../../styles/themes/redesign';
import Button from '../common/Button';
import { useLocalStorage } from '../../hooks/useLocalStorage';

const useStyles = makeStyles((theme) => ({
  container: {
    border: `1px solid ${hexToRgbA('#FFFFFF', 0.2)}`,
    backgroundColor: '#070A0F',
    borderRadius: 16,
    padding: 20,
    width: 374,
    position: 'absolute',
    top: '50% ',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex'
  },
  announcementTitleText: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: fontWeights.semiBold,
    paddingBottom: 20
  },
  announcementBodyText: {
    fontSize: theme.typography.pxToRem(15),
    paddingBottom: 20
  },
  link: {
    color: '#5493f7'
  }
}));

const AnnouncementModal = () => {
  const { handleClose } = useContext(NewModalContext);
  // eslint-disable-next-line
  const [showWarning, setShowWarning] = useLocalStorage<string>(
    'showCFEWarning',
    'true'
  );

  const closeSaveDialog = () => {
    setShowWarning('false');
    handleClose();
  };

  const classes = useStyles();
  return (
    <Grid className={classes.container} container>
      <Grid item>
        <Typography className={classes.announcementTitleText}>
          Attention:
        </Typography>
      </Grid>
      <Grid item>
        <Typography className={classes.announcementBodyText}>
          Welcome to the Alpha version of Apollo Vaults.
        </Typography>
        <Typography className={classes.announcementBodyText}>
          While our vaults have been audited, this is still an early stage
          release from Apollo DAO and we therefore ask you to ape responsibly.
        </Typography>
        <Typography className={classes.announcementBodyText}>
          If you find any bugs or issues, please click the 'Submit A Bug' button
          located in the bottom right corner of the screen. We have now launched
          our Bug Bounty Program, so if you discover any bugs that potentially
          put user funds at risk, please contact the team to be able to report
          the bug and claim your reward.
        </Typography>
        <Typography className={classes.announcementBodyText}>
          You agree by using this dApp that are doing so at your own risk.
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ marginTop: 16 }}>
        <Button filled onClick={() => closeSaveDialog()}>
          I Agree
        </Button>
      </Grid>
    </Grid>
  );
};

export default AnnouncementModal;
