import { makeStyles } from '@mui/styles';
import { FC } from 'react';

import BowIconSvg from '../../content/images/icons/BowIcon.svg';

const useStyles = makeStyles(() => ({
  bowIconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 35,
    height: 35,
    margin: 10,
    transition: 'transform 0.3s',
    '&:hover': {
      transform: 'rotate(-15deg)'
    }
  }
}));

const Bow: FC = () => {
  const classes = useStyles();

  return (
    <div className={`${classes.bowIconContainer} bowIcon`}>
      <img src={BowIconSvg} alt="Bow" />
    </div>
  );
};

export default Bow;
