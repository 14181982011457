import { mainnetStrategies, testnetStrategies } from '../store/strategy_infos';

export const assetInfos = [];

export const assets = [
  'mARKK',
  'MIR',
  'mAAPL',
  'mABNB',
  'mAMD',
  'mAMZN',
  'mBABA',
  'mBTC',
  'mCOIN',
  'mDOT',
  'mETH',
  'mFB',
  'mGLXY',
  'mGME',
  'mGOOGL',
  'mGS',
  'mHOOD',
  'mIAU',
  'mMSFT',
  'mNFLX',
  'mQQQ',
  'mSLV',
  'mSPY',
  'mSQ',
  'mTSLA',
  'mTWTR',
  'mUSO',
  'mVIXY',
  'MINE',
  'ANC',
  'APOLLO'
] as const;

export type SupportedAsset = typeof assets[number];

//Just temporary. Fill in staticStrategyInfos below
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const autocompoundIds = {
  1: 'mAAPL',
  2: 'mABNB',
  3: 'mAMC',
  4: 'mAMZN',
  5: 'mBABA',
  6: 'mBTC',
  7: 'mCOIN',
  8: 'mETH',
  9: 'mFB',
  10: 'mGME',
  11: 'mGOOGL',
  12: 'mGS',
  13: 'mIAU',
  15: 'mMSFT',
  16: 'mNFLX',
  17: 'mQQQ',
  18: 'mSLV',
  19: 'mSPY',
  20: 'mTSLA',
  21: 'mTWTR',
  22: 'mUSO',
  23: 'mVIXY'
} as const;

export const strategyIds = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31
] as const;

export function isSupportedStrategyId(id: number): id is StrategyId {
  return strategyIds.includes(id as StrategyId);
}

export type StrategyId = typeof strategyIds[number];

//TODO: Add strategy config items such as paused state
export type DynamicStrategyInfo = {
  tvl: number;
  dpr: number;
  apr: number;
  apy: number;
  stakedTokenValue: number;
  stakableTokenValue: number;
  stakedTokenBalance: number;
  stakableTokenBalance: number;
  baseTokenPrice: number;
  tokenPrice: number;
  farmApr: number;
  farmApy: number;
  performanceFee: number;
  platformFee: number;
  depositFee: number;
  withdrawalFee: number;
  apolloApr: number;
};

export const defaultDynamicStrategyInfo: DynamicStrategyInfo = {
  tvl: 0,
  apy: 0,
  dpr: 0,
  apr: 0,
  stakableTokenValue: 0,
  stakableTokenBalance: 0,
  stakedTokenValue: 0,
  stakedTokenBalance: 0,
  baseTokenPrice: 0,
  tokenPrice: 0,
  farmApr: 0,
  farmApy: 0,
  performanceFee: 0,
  platformFee: 0,
  depositFee: 0,
  withdrawalFee: 0,
  apolloApr: 0
};

export type SupportedProtocolKey = 'anc' | 'mir' | 'pylon' | 'stt' | 'angel';

export type StaticStrategyInfo = {
  id: StrategyId;
  key: SupportedAsset;
  name: string;
  description: string;
  baseToken: string;
  baseTokenName: string;
  icon: any;
  protocolNameFull: string;
  protocolNameShort: string;
  protocolKey: SupportedProtocolKey;
  manageAssetLink: string;
};

export type StrategyInfo = DynamicStrategyInfo & StaticStrategyInfo;
export type Vault = StrategyInfo;

export type StrategyInfos = {
  [k in StrategyId]: StrategyInfo;
};

export type StaticStrategyInfos = {
  [k in StrategyId]: StaticStrategyInfo;
};

export const staticStrategyInfos = mainnetStrategies;

export const testnetStaticStrategyInfos = testnetStrategies;

export const allStaticStrategyInfos: any = {
  mainnet: staticStrategyInfos,
  testnet: testnetStaticStrategyInfos
};
