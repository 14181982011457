import { useState, useEffect } from 'react';
import { Grid, Typography, makeStyles, Modal } from '@material-ui/core';
import Button from '../common/Button';
import { palette, fontWeights } from '../../styles/themes/redesign';
import { hexToRgbA } from '../../libs/util';
import { TransactionProcessing } from './TransactionProcessing';
import { TransactionCompleted } from './TransactionCompleted';
import { TransactionFailed } from './TransactionFailed';
import VaultPanel from '../common/VaultPanel';

import { ASSET_PATH } from '../../store/constants';
import { StrategyInfo } from '../../store/strategies';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { TxResult, UserDenied } from '@terra-money/wallet-provider';
import { PostError } from './VaultModal';
import { getTxInfosQuery } from '../../data/native/tx';
import { useQuery } from 'react-query';
import { TX_POLLING_INTERVAL } from '../../constants';
import { bankBalanceIndexState } from '../../data/native/balance';
import { strategyRequestIndex } from '../../data/app';
import { useFinderLink } from '../../hooks/useFinderLink';

const useStyles = makeStyles((theme) => ({
  vaultTransactionContainer: {
    position: 'relative',
    maxWidth: 410,
    top: '49%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: hexToRgbA('#12161F', 0.9),
    border: `1px solid ${hexToRgbA('#FFFFFF', 0.2)}`,
    padding: 10,
    borderRadius: 25
  },
  successfulDepositContainer: {
    backgroundImage: `linear-gradient(rgba(24, 29, 41, 0) 0%, #181d29 100%), url('${ASSET_PATH}/img/embers.png')`,
    backgroundPosition: '50% 30%'
  },
  statusContainer: {
    margin: 16
  },
  statusMessageText: {
    fontSize: theme.typography.pxToRem(17),
    color: 'rgba(255,255,255,1)',
    fontWeight: fontWeights.semiBold
  },
  statusDetailText: {
    fontSize: theme.typography.pxToRem(13),
    color: 'rgba(255,255,255,1)',
    fontWeight: fontWeights.medium,
    marginTop: 4,
    marginBottom: 4
  },
  statusDetailMuted: {
    fontSize: theme.typography.pxToRem(11),
    color: 'rgba(255,255,255,.5)',
    fontWeight: fontWeights.medium,
    marginTop: 2
  },
  doneButton: {
    height: 55,
    marginTop: 15,
    backgroundColor: palette.mirrorBlue,
    '&:hover': {
      backgroundColor: palette.mirrorBlueHover
    }
  },
  doneButtonText: {
    fontSize: theme.typography.pxToRem(20),
    color: 'rgba(255,255,255,1)',
    fontWeight: fontWeights.semiBold
  },
  panelBorder: {}
}));

export const Pending = ({
  amount,
  formMode,
  vaultName,
  assetName,
  usingZapper,
  amountValue
}: {
  amount: string;
  formMode: 'deposit' | 'withdraw';
  vaultName: string;
  assetName: string;
  usingZapper: boolean;
  amountValue: string;
}) => {
  const classes = useStyles();
  return (
    <Grid
      className={classes.statusContainer}
      container
      direction={'column'}
      alignItems={'center'}
      justify={'flex-start'}>
      <TransactionProcessing />
      <Typography className={classes.statusMessageText}>
        Waiting for Confirmation
      </Typography>
      <Typography className={classes.statusDetailText} align={'center'}>
        {formMode === 'deposit' ? 'Depositing' : 'Withdrawing'}
        {usingZapper ? ` ~${amountValue} UST` : ` ${amount} ${assetName}`}{' '}
        {formMode === 'deposit' ? 'into' : 'from'} {vaultName}
      </Typography>
      <Typography className={classes.statusDetailMuted} align={'center'}>
        Please confirm this transaction in your wallet
      </Typography>
    </Grid>
  );
};

const WithdrawCompleted = ({
  amount,
  amountValue,
  txFee,
  handleClose,
  vault,
  hash,
  usingZapper
}: {
  vault: StrategyInfo;
  amount: string;
  amountValue: string;
  txFee: string;
  hash: string;
  handleClose: () => void;
  usingZapper: boolean;
}) => {
  const classes = useStyles();

  return (
    <Grid
      className={classes.statusContainer}
      container
      direction={'column'}
      alignItems={'center'}
      justify={'flex-start'}>
      <TransactionCompleted />
      <Typography className={classes.statusMessageText} align={'center'}>
        Complete!
      </Typography>
      <Typography className={classes.statusDetailText} align={'center'}>
        Successfully withdrawn{' '}
        {usingZapper
          ? `~${amountValue} UST `
          : `${amount} ${vault.baseTokenName} (~${amountValue}) `}
        from {vault.name}
      </Typography>
      <Grid container>
        <Grid item xs={6}>
          <Typography className={classes.statusDetailMuted}>
            Transaction Hash
          </Typography>
          <Typography className={classes.statusDetailMuted}>
            Transaction Fee
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.statusDetailMuted} align={'right'}>
            <a
              rel="noreferrer"
              className={classes.statusDetailMuted}
              href={useFinderLink('hash', hash)}
              target={'_blank'}>
              {formatTxHash(hash)}
            </a>
          </Typography>
          <Typography className={classes.statusDetailMuted} align={'right'}>
            {txFee} UST
          </Typography>
        </Grid>
      </Grid>
      <Button
        onClick={() => handleClose()}
        textClassName={classes.doneButtonText}
        className={classes.doneButton}>
        Done
      </Button>
    </Grid>
  );
};

const DepositCompleted = ({
  amount,
  amountValue,
  txFee,
  handleClose,
  vault,
  hash,
  usingZapper
}: {
  amount: string;
  amountValue: string;
  txFee: string;
  vault: StrategyInfo;
  handleClose: () => void;
  hash: string;
  usingZapper: boolean;
}) => {
  const classes = useStyles();

  return (
    <Grid
      className={classes.statusContainer}
      container
      direction={'column'}
      alignItems={'center'}
      justify={'flex-start'}>
      <Grid item className={classes.panelBorder}>
        <VaultPanel
          style={{ margin: 0, cursor: 'initial' }}
          vault={vault}
          useClick={false}
        />
      </Grid>
      <TransactionCompleted />

      <Typography className={classes.statusMessageText} align={'center'}>
        Complete!
      </Typography>
      <Typography className={classes.statusDetailText} align={'center'}>
        Successfully deposited {amount}{' '}
        {usingZapper ? 'UST ' : `${vault.baseTokenName} (~${amountValue}) `}
        into {vault.name}
      </Typography>
      <Grid container>
        <Grid item xs={6}>
          <Typography className={classes.statusDetailMuted}>
            Transaction Hash
          </Typography>
          <Typography className={classes.statusDetailMuted}>
            Transaction Fee
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.statusDetailMuted} align={'right'}>
            <a
              rel="noreferrer"
              className={classes.statusDetailMuted}
              href={useFinderLink('hash', hash)}
              target={'_blank'}>
              {formatTxHash(hash)}
            </a>
          </Typography>
          <Typography className={classes.statusDetailMuted} align={'right'}>
            {txFee} UST
          </Typography>
        </Grid>
      </Grid>
      <Button
        onClick={() => handleClose()}
        textClassName={classes.doneButtonText}
        className={classes.doneButton}>
        Done
      </Button>
    </Grid>
  );
};

export const Failed = ({
  handleClose,
  reason
}: {
  handleClose: () => void;
  reason: string;
}) => {
  const classes = useStyles();
  return (
    <Grid
      className={classes.statusContainer}
      container
      direction={'column'}
      alignItems={'center'}
      justify={'flex-start'}>
      <TransactionFailed />
      <Typography className={classes.statusMessageText} align={'center'}>
        Failed
      </Typography>
      <Typography className={classes.statusDetailText} align={'center'}>
        {reason}
      </Typography>
      <Button
        onClick={() => handleClose()}
        textClassName={classes.doneButtonText}
        className={classes.doneButton}>
        Done
      </Button>
    </Grid>
  );
};

export enum STATUS {
  SUCCESS = 'success',
  LOADING = 'loading',
  FAILURE = 'failure'
}

export const VaultTransactionStatusDialog = ({
  open,
  txnMode,
  handleClose,
  vault,
  txResult,
  txError,
  amount,
  amountValue,
  txFee,
  formMode,
  usingZapper
}: {
  open: boolean;
  txnMode: string;
  handleClose: () => void;
  vault: StrategyInfo;
  txResult?: TxResult;
  txError?: PostError;
  amount: string;
  amountValue: string;
  txFee: string;
  formMode: 'deposit' | 'withdraw';
  usingZapper: boolean;
}) => {
  const success = !txError;
  const hash = txResult?.result.txhash ?? '';
  const raw_log = txResult?.result.raw_log ?? '';

  /* polling */
  const [refetchInterval, setRefetchInterval] = useState<number | false>(false);
  const getTxInfos = useRecoilValue(getTxInfosQuery);
  const tx = useQuery(hash, () => getTxInfos(hash), { refetchInterval });
  const { data: txInfo } = tx;

  /* status */
  const status =
    !success || !hash || tx.error || (txInfo && !txInfo?.Success)
      ? STATUS.FAILURE
      : tx.isLoading || !txInfo
      ? STATUS.LOADING
      : STATUS.SUCCESS;

  useEffect(() => {
    success && hash && setRefetchInterval(TX_POLLING_INTERVAL);
  }, [success, hash]);

  const setBankBalanceIndexState = useSetRecoilState(bankBalanceIndexState);
  const setStrategyRequestIndex = useSetRecoilState(strategyRequestIndex);

  useEffect(() => {
    if (status === STATUS.LOADING) {
      setRefetchInterval(TX_POLLING_INTERVAL);
    } else {
      setRefetchInterval(false);
      setBankBalanceIndexState((n) => n + 1);
      if (status === STATUS.SUCCESS) setStrategyRequestIndex((n) => n + 1);
    }
  }, [status, setBankBalanceIndexState, setStrategyRequestIndex]);

  const message =
    txInfo?.RawLog ||
    raw_log ||
    txError?.message ||
    (txError instanceof UserDenied && 'Denied by the user') ||
    'Transaction failed for an unknown reason';

  const content = {
    [STATUS.SUCCESS]:
      txnMode === 'deposit' ? (
        <DepositCompleted
          hash={hash}
          amount={amount}
          amountValue={amountValue}
          txFee={txFee}
          handleClose={handleClose}
          vault={vault}
          usingZapper={usingZapper}
        />
      ) : (
        <WithdrawCompleted
          hash={hash}
          vault={vault}
          amount={amount}
          amountValue={amountValue}
          txFee={txFee}
          handleClose={handleClose}
          usingZapper={usingZapper}
        />
      ),
    [STATUS.LOADING]: (
      <Pending
        amount={amount}
        formMode={formMode}
        vaultName={vault.name}
        assetName={vault.baseTokenName}
        usingZapper={usingZapper}
        amountValue={amountValue}
      />
    ),
    [STATUS.FAILURE]: <Failed handleClose={handleClose} reason={message} />
  }[status];

  const classes = useStyles();
  return (
    <Modal open={open} onClose={() => {}}>
      <Grid
        className={`${classes.vaultTransactionContainer} ${
          status === STATUS.SUCCESS &&
          txnMode === 'deposit' &&
          classes.successfulDepositContainer
        }`}
        container>
        {content}
      </Grid>
    </Modal>
  );
};

export function formatTxHash(hash: string) {
  return hash.substring(0, 6) + '...' + hash.substring(hash.length - 6);
}
