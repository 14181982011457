import { useState, useEffect } from 'react';
import request from 'axios';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { useFinderLink } from '../../hooks/useFinderLink';
import { FCD_PATH } from '../../store/constants';
import { hexToRgbA } from '../../libs/util';
import { palette, fontWeights } from '../../styles/themes/redesign';

const useStyles = makeStyles((theme) => ({
  footerContainer: {
    padding: '25px',
    marginTop: 25
  },
  networkStatusText: {
    fontWeight: fontWeights.semiBold,
    fontSize: theme.typography.pxToRem(12),
    color: hexToRgbA(palette.systemGreen, 0.3)
  },
  networkStatusIndicator: {
    width: 10,
    height: 10,
    borderRadius: 16,
    backgroundColor: palette.systemGreen,
    marginRight: 8,
    filter: `drop-shadow(0px 0px 6px ${palette.systemGreen})`
  },
  networkStatusWaiting: {
    backgroundColor: palette.apolloGold,
    filter: `drop-shadow(0px 0px 6px ${palette.apolloGold})`
  }
}));

export const Footer = () => {
  const [blockInfo, setBlockInfo] = useState();
  const queryBlock = async () => {
    try {
      const status = await request.get(`${FCD_PATH}/blocks/latest`);
      setBlockInfo(status.data.block.header.height);
    } catch (e) {
      console.log('Error connecting to network.');
    }
  };

  useEffect(() => {
    queryBlock();
  });

  const classes = useStyles();

  return (
    <Grid container className={classes.footerContainer}>
      <Grid item>
        <Grid container justify={'center'} alignItems={'center'}>
          <div
            className={`${classes.networkStatusIndicator} ${
              !blockInfo && classes.networkStatusWaiting
            }`}
          />
          <a
            className={classes.networkStatusText}
            target={'_blank'}
            rel="noreferrer"
            href={useFinderLink('blocks', blockInfo)}>
            {blockInfo}
          </a>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Footer;
