import { createMuiTheme } from '@material-ui/core/styles';

export const palette = {
  buttonBlue: '#5493f7',
  newVaultDot: '#8dff80',
  hotVaultDot: '#f26469',
  systemGreen: '#32d74b',
  apolloGradient: { start: '#FED200', end: '#FD806D' },
  coral: '#fd806d',
  apolloGold: '#fed200',
  backgroundBlack: '#121722',
  nearBlack: '#05080d',
  reallyDarkBlueGrey: '#101723',
  darkBlueGrey: '#1c293e',
  white: '#ffffff',
  anchorGreen: '#45C748',
  anchorDarkGreen: '#39A83C',
  modalBlue: '#181D29',
  mirrorBlue: '#5493f7',
  mirrorBlueHover: '#477FD8',
  Mirror: 'linear-gradient(#5493f7 0%, rgba(84, 147, 247, 0.2) 100%)',
  Anchor: 'linear-gradient(#4ada4a 0%, rgba(74, 218, 74, 0.2) 100%)',
  anc: 'linear-gradient(rgba(74, 218, 74, 0.85) 0%, #03904d 100%)',
  mir:
    'linear-gradient(rgba(85, 158, 233, 0.85) 0%, rgba(57, 84, 235, 0.85) 100%)',
  pylon: 'linear-gradient(#2be3f6 0%, rgba(28, 156, 245, 0.8) 100%)',
  stt: 'linear-gradient(#feed00 0%, #c9a73a 100%)',
  plRed: '#E31837'
};

export const textPalette = {
  anc: '#03904d',
  mir: 'rgba(57, 84, 235, 1)',
  pylon: 'rgba(28, 156, 245, 1)',
  stt: '#c9a73a'
};

export const fontWeights = {
  semiBold: 700,
  medium: 600
};

export const omnesPro = ['omnes-pro', 'sans-serif'].join(',');

const theme = createMuiTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: `${palette.nearBlack}`
        }
      }
    }
  },
  typography: {
    fontFamily: omnesPro,
    fontWeight: 'normal'
  },
  palette: {
    text: {
      primary: palette.white
    }
  }
});

export default theme;
