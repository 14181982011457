import { useState, createContext } from 'react';
import { makeStyles, Grid, Modal } from '@material-ui/core';
import VaultModal from '../components/modals/VaultModal';
import AnnouncementModal from '../components/modals/AnnouncementModal';
import AlphaModal from '../components/alpha/AlphaModal';
import { SupportedAsset } from '../store/strategies';
import { FunctionComponent } from 'react';

export const NewModalContext = createContext({
  toggleBlur: () => {},
  handleOpen: () => {},
  handleClose: () => {},
  setModalContent: (content: JSX.Element) => {},
  handleVaultModal: (vaultKey: SupportedAsset) => {},
  handleAnnouncementModal: () => {},
  handleAlphaModal: () => {}
});

const useStyles = makeStyles((theme) => ({
  blur: {
    filter: 'blur(8px)'
  }
}));

export const NewModalProvider: FunctionComponent<{}> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [blur, setBlur] = useState(false);
  const [modalContent, setModalContent] = useState(<div />);
  const [allowOutsideClose, setAllowOutsideClose] = useState(true);

  const toggleBlur = () => {
    setBlur(!blur);
  };

  const handleOpen = () => {
    setOpen(true);
    toggleBlur();
  };

  const handleClose = () => {
    onClose({}, 'closeClicked');
  };

  const onClose = (
    event: {},
    reason: 'backdropClick' | 'escapeKeyDown' | 'closeClicked'
  ) => {
    if (allowOutsideClose || reason === 'closeClicked') {
      setOpen(false);
      toggleBlur();
      setAllowOutsideClose(true);
    }
  };

  const handleVaultModal = (vaultKey: SupportedAsset) => {
    setModalContent(<VaultModal vaultKey={vaultKey} />);
    handleOpen();
  };

  const handleAnnouncementModal = () => {
    setModalContent(<AnnouncementModal />);
    handleOpen();
  };

  const handleAlphaModal = () => {
    setModalContent(<AlphaModal />);
    setAllowOutsideClose(false); //Only allow closing modal by accepting warning
    handleOpen();
  };

  const classes = useStyles();

  return (
    <NewModalContext.Provider
      value={{
        toggleBlur,
        handleOpen,
        handleClose,
        setModalContent,
        handleVaultModal,
        handleAnnouncementModal,
        handleAlphaModal
      }}>
      <div className={blur ? classes.blur : undefined}>{children}</div>
      <Modal open={open} disableEnforceFocus disableAutoFocus onClose={onClose}>
        <Grid container direction={'column'} justify={'space-between'}>
          {modalContent}
        </Grid>
      </Modal>
    </NewModalContext.Provider>
  );
};
