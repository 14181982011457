import { Grid, makeStyles, Button } from '@material-ui/core';
import Bow from '../Bow';
import { fontWeights } from '../../../styles/themes/redesign';
import Connect from '../../../layouts/Connect';
import { white } from '../../../styles/colors';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: `18px 25px`,
    backgroundColor: '#05080D',
    borderBottom: '1px solid rgba(255,255,255,.3)'
  },
  navButton: {
    marginRight: 10
  },
  apolloLogo: {
    marginRight: 15
  },
  walletButton: {
    marginRight: 10
  },
  connectButton: {
    width: 140,
    marginRight: 10,
    background: `linear-gradient(to left, #fed200, #fd806d)`,
    transition: 'all .2s ease-in-out',
    '&:hover': {
      transform: 'scale(1.04)'
    }
  },
  connectButtonText: {
    fontWeight: fontWeights.semiBold
  },
  bowLogoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'transform 0.3s',
    '&:hover > .bowIcon': {
      transform: 'rotate(-15deg)'
    },
    textDecoration: 'none'
  },
  bowLogoText: {
    color: white,
    fontWeight: 700,
    fontSize: 18
  }
}));

const Desktop = () => {
  const classes = useStyles();

  return (
    <Grid
      className={classes.container}
      container
      justify={'space-between'}
      alignItems={'center'}>
      <Grid item>
        <Grid container alignItems={'center'}>
          <Button
            href={'https://app.apollo.farm'}
            target={'_blank'}
            variant="text"
            color="primary"
            className={classes.bowLogoContainer}>
            <Bow />
            <div className={classes.bowLogoText}>Farmer's Market</div>
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container justify={'center'} alignItems={'center'}>
          <Grid item>
            <Connect />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Desktop;
