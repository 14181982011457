// import { useRecoilValue } from 'recoil';
import { makeStyles } from '@material-ui/core';
import { useModal } from '../containers/mirror/Modal';
// import { locationKeyState } from '../data/app';
import TerraExtensionConnect from '../components/common/header/TerraExtensionConnect';
import { useWallet } from '@terra-money/wallet-provider';
import useAddress from '../hooks/useAddress';
import { useUusdBalance } from '../data/native/balance';
import Formatted from '../components/mirror/Formatted';

const useStyles = makeStyles((theme) => ({
  walletButton: {
    marginRight: 10
  }
}));

const Connected = () => {
  // const key = useRecoilValue(locationKeyState);
  const { disconnect } = useWallet();
  const classes = useStyles();
  const uusd = useUusdBalance();
  const address = useAddress();
  const modal = useModal();

  const handleDisconnect = () => {
    disconnect();
    modal.close();
  };

  return (
    <TerraExtensionConnect
      className={classes.walletButton}
      ustTotal={<Formatted symbol="uusd">{uusd}</Formatted>}
      address={address}
      onClick={handleDisconnect}
    />
  );
};

export default Connected;
