import { gql } from 'graphql-request';

export const ASSETS = {
  STATS: gql`
    query assets($network: Network) {
      assets {
        token
        description

        statistic {
          liquidity(network: $network)
          volume(network: $network)
          marketCap
          collateralValue
          minCollateralRatio
          apr {
            long
            short
          }
        }
      }
    }
  `,

  CHANGE: gql`
    query assets($timestamp: Float!) {
      assets {
        token
        prices {
          price
          priceAt(timestamp: $timestamp)
          oraclePrice
          oraclePriceAt(timestamp: $timestamp)
        }
      }
    }
  `,

  HISTORY: gql`
    query assets($interval: Float!, $from: Float!, $to: Float!) {
      assets {
        token
        prices {
          history(interval: $interval, from: $from, to: $to) {
            timestamp
            price
          }
        }
      }
    }
  `
};
