export const TransactionCompleted = ({ ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="120"
      height="120"
      viewBox="0 0 120 120"
      {...rest}>
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="0.5"
          x2="0.5"
          y2="1"
          gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#fed200" />
          <stop offset="1" stopColor="#fd806d" />
        </linearGradient>
        <linearGradient id="linear-gradient-4" y1="-1.002" y2="2.038" />
      </defs>
      <g
        id="Group_1532"
        data-name="Group 1532"
        transform="translate(5259 -2429)">
        <g
          id="Group_1525"
          data-name="Group 1525"
          transform="translate(-1382 384)">
          <path
            id="Subtraction_6"
            data-name="Subtraction 6"
            d="M45,90A45.012,45.012,0,0,1,27.484,3.536,45.012,45.012,0,0,1,62.516,86.464,44.718,44.718,0,0,1,45,90ZM45,5A40.01,40.01,0,0,0,29.43,81.856,40.01,40.01,0,0,0,60.57,8.144,39.75,39.75,0,0,0,45,5Z"
            transform="translate(-3862 2060)"
            fill="url(#linear-gradient)"
          />
          <path
            id="Subtraction_7"
            data-name="Subtraction 7"
            d="M45,90A45.012,45.012,0,0,1,27.484,3.536,45.012,45.012,0,0,1,62.516,86.464,44.718,44.718,0,0,1,45,90ZM45,5A40.01,40.01,0,0,0,29.43,81.856,40.01,40.01,0,0,0,60.57,8.144,39.75,39.75,0,0,0,45,5Z"
            transform="translate(-3862 2060)"
            fill="url(#linear-gradient)"
          />
        </g>
        <path
          id="Union_10"
          data-name="Union 10"
          d="M13.124,30.426a2.494,2.494,0,0,1-.75-.517L1.768,19.3A2.5,2.5,0,0,1,5.3,15.768l9.045,9.045L37.4,1.767A2.5,2.5,0,1,1,40.929,5.3L16.178,30.051a2.5,2.5,0,0,1-3.054.375Z"
          transform="translate(-5220.035 2472.965)"
          fill="url(#linear-gradient)"
        />
        <path
          id="Union_11"
          data-name="Union 11"
          d="M13.124,30.426a2.494,2.494,0,0,1-.75-.517L1.768,19.3A2.5,2.5,0,0,1,5.3,15.768l9.045,9.045L37.4,1.767A2.5,2.5,0,1,1,40.929,5.3L16.178,30.051a2.5,2.5,0,0,1-3.054.375Z"
          transform="translate(-5220.035 2472.965)"
          fill="url(#linear-gradient-4)"
        />
      </g>
    </svg>
  );
};
