import { Grid, CircularProgress, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Bow from '../common/Bow';

const useStyles = makeStyles((theme) => ({}));

const Loading = () => {
  const classes = useStyles();

  return (
    <Grid
      className={classes.container}
      container
      justify={'center'}
      alignItems={'center'}>
      <Grid item>
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center">
          <CircularProgress
            style={{ width: 150, height: 150 }}
            color={'inherit'}
          />
        </Box>
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center">
          <Bow className={''} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default Loading;
