import BigNumber from 'bignumber.js';
import { useRecoilValue } from 'recoil';
import { networkQuery } from '../data/network';

const useFee = (length = 1, gasAdjust = 1) => {
  const { fee } = useRecoilValue(networkQuery);
  const { gasPrice } = fee;

  const amount = new BigNumber(fee.amount)
    .times(length)
    .times(gasAdjust)
    .toNumber();

  const gas = new BigNumber(amount)
    .div(gasPrice)
    .integerValue(BigNumber.ROUND_FLOOR)
    .toNumber();

  return { ...fee, amount, gas };
};

export default useFee;
