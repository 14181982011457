import { selector } from 'recoil';
import { request } from 'graphql-request';
import {
  ClientError,
  RequestDocument,
  Variables
} from 'graphql-request/dist/types';
import alias from '../mirror/alias';
import { protocolQuery } from '../mirror/protocol';
import { mantleURLQuery } from '../network';
import { parseResults } from './parse';

/* queries */
export const getListedContractQueriesQuery = selector({
  key: 'getListedContractQueries',
  get: ({ get }) => {
    const { listedAll } = get(protocolQuery);
    const getContractQueries = get(getContractQueriesQuery);

    return async <Parsed>(fn: GetDocument, name: string) => {
      const document = alias(
        listedAll
          .filter((item) => fn(item))
          .map((item) => ({ name: item.token, ...fn(item) })),
        name
      );

      return await getContractQueries<Parsed>(document, name);
    };
  }
});

export const getContractQueriesQuery = selector({
  key: 'getContractQueries',
  get: ({ get }) => {
    const url = get(mantleURLQuery);

    return async <Parsed>(document: RequestDocument, name: string) => {
      try {
        const result = await request<Dictionary<ContractData | null> | null>(
          url + '?' + name,
          document
        );
        return result ? parseResults<Parsed>(result) : undefined;
      } catch (error) {
        const result =
          error instanceof ClientError ? error.response.data : undefined;
        return result ? parseResults<Parsed>(result) : undefined;
      }
    };
  }
});

/* native */
export const getNativeQueryQuery = selector({
  key: 'getNativeQuery',
  get: ({ get }) => {
    const url = get(mantleURLQuery);

    return async <Parsed>(
      params: { document: RequestDocument; variables?: Variables },
      name: string
    ) => {
      const { document, variables } = params;
      return await request<Parsed>(url + '?' + name, document, variables);
    };
  }
});
