import { selector } from 'recoil';
import { getListedContractQueriesQuery } from '../utils/queries';
import { priceKeyIndexState } from '../app';
import { addressState } from '../wallet';
import { protocolQuery } from './protocol';
import { bankBalanceIndexState } from '../native/balance';

export const pairPoolQuery = selector({
  key: 'pairPool',
  get: async ({ get }) => {
    get(priceKeyIndexState);
    const getListedContractQueries = get(getListedContractQueriesQuery);
    return await getListedContractQueries<PairPool>(
      ({ token, pair }) => ({ token, contract: pair, msg: { pool: {} } }),
      'pairPool'
    );
  }
});

export const oraclePriceQuery = selector({
  key: 'oraclePrice',
  get: async ({ get }) => {
    const { contracts } = get(protocolQuery);
    const getListedContractQueries = get(getListedContractQueriesQuery);
    return await getListedContractQueries<Rate>(
      ({ token, symbol }) =>
        symbol === 'MIR'
          ? undefined
          : {
              contract: contracts['oracle'],
              msg: { price: { base_asset: token, quote_asset: 'uusd' } }
            },
      'oraclePrice'
    );
  }
});

export const mintAssetConfigQuery = selector({
  key: 'mintAssetConfig',
  get: async ({ get }) => {
    const { contracts } = get(protocolQuery);
    const getListedContractQueries = get(getListedContractQueriesQuery);
    return await getListedContractQueries<MintAssetConfig>(
      ({ token, symbol }) =>
        symbol === 'MIR'
          ? undefined
          : {
              contract: contracts['mint'],
              msg: { asset_config: { asset_token: token } }
            },
      'mintAssetConfig'
    );
  }
});

export const tokenBalanceQuery = selector({
  key: 'tokenBalance',
  get: async ({ get }) => {
    get(bankBalanceIndexState);
    const address = get(addressState);

    if (address) {
      const getListedContractQueries = get(getListedContractQueriesQuery);
      return await getListedContractQueries<Balance>(
        ({ token }) => ({ contract: token, msg: { balance: { address } } }),
        'tokenBalance'
      );
    } else {
      //console.log('[tokenBalance] Address not found');
      return undefined;
    }
  }
});

export const lpTokenBalanceQuery = selector({
  key: 'lpTokenBalance',
  get: async ({ get }) => {
    get(bankBalanceIndexState);
    const address = get(addressState);

    if (address) {
      const getListedContractQueries = get(getListedContractQueriesQuery);
      return await getListedContractQueries<Balance>(
        ({ lpToken }) => ({ contract: lpToken, msg: { balance: { address } } }),
        'lpTokenBalance'
      );
    } else {
      //console.log('[lpTokenBalance] Address not found');
      return undefined;
    }
  }
});
