import { useState } from 'react';
//import MESSAGE from '../lang/MESSAGE.json';
import useAddress from '../hooks/useAddress';
import ConnectButton from '../components/mirror/ConnectButton';
import Connected from './Connected';
import { WalletSelectDialog } from '../components/modals/WalletSelectDialog';
import { useTranslation } from 'react-i18next';

const Connect = ({ className }: { className?: string }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const address = useAddress();

  return (
    <div className={className}>
      {!address ? (
        <>
          <ConnectButton
            onClick={() => {
              setIsOpen(true);
            }}>
            {t('Wallet.Connect')}
          </ConnectButton>

          <WalletSelectDialog
            open={isOpen}
            handleClose={() => {
              setIsOpen(false);
            }}
          />
        </>
      ) : (
        <Connected />
      )}
    </div>
  );
};

export default Connect;
