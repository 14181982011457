import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  '@keyframes spinner': {
    from: {
      transform: 'rotate(0deg)'
    },
    to: {
      transform: 'rotate(360deg)'
    }
  },
  transactionProcessing: {
    animation: '$spinner infinite 1s linear'
  }
}));

export const TransactionProcessing = ({ ...rest }) => {
  const classes = useStyles();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="120"
      height="120"
      viewBox="0 0 120 120"
      className={classes.transactionProcessing}
      {...rest}>
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="0.5"
          x2="0.5"
          y2="1"
          gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#fed200" />
          <stop offset="1" stopColor="#fd806d" />
        </linearGradient>
      </defs>
      <g
        id="Group_1525"
        data-name="Group 1525"
        transform="translate(3877 -2045)">
        <path
          id="Subtraction_6"
          data-name="Subtraction 6"
          d="M45,90A45.012,45.012,0,0,1,27.484,3.536,45.012,45.012,0,0,1,62.516,86.464,44.718,44.718,0,0,1,45,90ZM45,5A40.01,40.01,0,0,0,29.43,81.856,40.01,40.01,0,0,0,60.57,8.144,39.75,39.75,0,0,0,45,5Z"
          transform="translate(-3862 2060)"
          fill="url(#linear-gradient)"
        />
        <path
          id="Subtraction_7"
          data-name="Subtraction 7"
          d="M45,90A45.012,45.012,0,0,1,27.484,3.536,45.012,45.012,0,0,1,62.516,86.464,44.718,44.718,0,0,1,45,90ZM45,5A40.01,40.01,0,0,0,29.43,81.856,40.01,40.01,0,0,0,60.57,8.144,39.75,39.75,0,0,0,45,5Z"
          transform="translate(-3862 2060)"
          fill="url(#linear-gradient)"
        />
      </g>
    </svg>
  );
};
