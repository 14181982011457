/* terra:network */
export const ICON_URL = 'https://whitelist.mirror.finance/images';

/* terra:wasm */
export const WASMQUERY = 'WasmContractsContractAddressStore';

/* mirror:unit */
export const SMALLEST = 1e6;

/* network:settings */
export const PRICES_POLLING_INTERVAL = 30000;
export const TX_POLLING_INTERVAL = 1000;
