import { Grid, Typography, makeStyles, Modal } from '@material-ui/core';
import { fontWeights } from '../../styles/themes/redesign';
import { hexToRgbA } from '../../libs/util';
import VaultIcon from '../core/VaultIcon';
import AssetIcon from '../common/AssetIcon';

const useStyles = makeStyles((theme) => ({
  assetSelectorContainer: {
    position: 'relative',
    maxWidth: 410,
    top: '49%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: hexToRgbA('#12161F', 0.9),
    border: `1px solid ${hexToRgbA('#FFFFFF', 0.2)}`,
    padding: `20px 20px 10px 20px`,
    borderRadius: 25
  },
  assetSelectorTitleText: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: fontWeights.semiBold,
    marginBottom: 10
  },
  assetSelectorRow: {
    padding: 12,
    borderRadius: 15,
    marginBottom: 5,
    backgroundColor: `${hexToRgbA('#1F293C', 0.6)}`,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: `${hexToRgbA('#1F293C', 1)}`
    }
  },
  assetIcon: {
    width: 21,
    height: 21,
    marginRight: 18
  },
  assetText: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: fontWeights.medium
  },
  assetTextAmount: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: fontWeights.medium
  },
  assetTextMuted: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: fontWeights.semiBold
  }
}));

export const AssetSelectorDialog = ({
  open,
  asset,
  handleInputAsset,
  formMode,
  ustBalance,
  stakedTokenBalance,
  stakedTokenValue,
  stakableTokenBalance
}: {
  open: boolean;
  asset: string;
  handleInputAsset: (usingZapper: boolean) => void;
  formMode: 'deposit' | 'withdraw';
  ustBalance: string;
  stakedTokenBalance: string;
  stakedTokenValue: string;
  stakableTokenBalance: string;
}) => {
  const classes = useStyles();
  return (
    <Modal open={open} onClose={() => {}}>
      <Grid
        className={`${classes.assetSelectorContainer}`}
        container
        direction={'column'}>
        <Grid item xs={12}>
          <Typography className={classes.assetSelectorTitleText}>
            Select an Asset
          </Typography>
        </Grid>
        <Grid
          onClick={() => {
            handleInputAsset(true);
          }}
          container
          item
          xs={12}
          alignItems={'center'}
          className={classes.assetSelectorRow}>
          <Grid item container xs={6}>
            <AssetIcon className={classes.assetIcon} />
            <Typography className={classes.assetText}>UST</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.assetTextAmount} align={'right'}>
              {formMode === 'deposit' ? ustBalance : stakedTokenValue}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          onClick={() => {
            handleInputAsset(false);
          }}
          container
          item
          xs={12}
          alignItems={'center'}
          className={classes.assetSelectorRow}>
          <Grid item container xs={6}>
            <VaultIcon
              className=""
              vaultKey={asset}
              icon1Type={'full'}
              icon2Type="bordered"
              scale={'mini'}
              variant={'side'}
            />
            <Typography className={classes.assetText}>
              {asset}-UST LP
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.assetTextAmount} align={'right'}>
              {formMode === 'deposit'
                ? stakableTokenBalance
                : stakedTokenBalance}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};
